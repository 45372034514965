exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./img/bg.jpg"));

// Module
exports.push([module.id, ".Login_root__1q63o:after {\n  content: '';\n  position: absolute;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  top: 0;\n  opacity: 0.79;\n  background: #0F1F30;\n  z-index: 0; }\n\n.Login_lang__1iAZD {\n  position: absolute;\n  top: 20px;\n  left: 20px; }\n\n.Login_bg__1RLhq {\n  background: url(" + ___CSS_LOADER_URL___0___ + ") no-repeat;\n  background-position: center;\n  background-size: cover;\n  position: absolute;\n  z-index: 0;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  filter: blur(2px); }\n\n.Login_container__2wXPP {\n  position: relative;\n  z-index: 2;\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  overflow: auto;\n  padding: 0 25px;\n  max-width: 1024px;\n  margin: auto; }\n\n.Login_html__1hg9e {\n  max-height: 100vh;\n  overflow: auto; }\n  .Login_html__1hg9e::-webkit-scrollbar {\n    display: none; }\n  .Login_html__1hg9e h2 {\n    font-family: 'Roboto';\n    font-weight: 700;\n    font-size: 28px;\n    color: #FFFFFF;\n    text-align: left;\n    margin: 0; }\n  .Login_html__1hg9e p {\n    font-family: 'Roboto';\n    font-size: 17px;\n    color: #FFFFFF;\n    text-align: left; }\n  @media (min-width: 768px) {\n    .Login_html__1hg9e {\n      margin-top: 40px;\n      padding: 0 60px; }\n      .Login_html__1hg9e h2 {\n        font-size: 42px; }\n      .Login_html__1hg9e p {\n        font-size: 22px;\n        line-height: 28px; } }\n", ""]);

// Exports
exports.locals = {
	"root": "Login_root__1q63o",
	"lang": "Login_lang__1iAZD",
	"bg": "Login_bg__1RLhq",
	"container": "Login_container__2wXPP",
	"html": "Login_html__1hg9e"
};