import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Product from './Product';
import { fetchProduct } from './actions';
import { fetchCategory } from 'components/Categories/actions';

import { 
  addCart,
} from 'components/Cart/actions';

const mapStateToProps = (state) => ({
  product: state.product.products[state.product.active] || {},
  cartProducts: state.cart.cart.items || [],
  currency: state.user.user.currency,
  decimals: state.user.user.decimals,
  userToken: state.user.token,
  currentLocale: state.lang.currentLocale,
  viewed: state.categories.category.viewed,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    fetchProduct,
    fetchCategory,
    addCart
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Product);
