import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { FormattedMessage } from 'react-intl';

class BalanceSms extends Component {
  state = {
    sms: ''
  }

  handleSendSms = e => {
    const { userToken, userBalanceSms } = this.props

    userBalanceSms(userToken);
  }

  handleSms = e => {
    this.setState({
      sms: e.target.value.toUpperCase()
    });
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleLogin();
    }
  }

  prepareSms(sms){
    return sms.split(' ').join('').split('_').join('');
  }

  handleLogin = () => {
    const { setNotification, userBalanceLogin, userToken } = this.props;
    const { sms } = this.state;

    if (this.prepareSms(sms).length === 6){
      userBalanceLogin(userToken, this.prepareSms(sms));
      this.setState({
        sms: ''
      });
    }else{
      setNotification('loginSmsRequired');
    }
  }

  render() {
    const { sms } = this.state;
    const { phone, style } = this.props;

    return (
      <div className={style.root}>
        {phone.length
          ? <div>
              <h2><FormattedMessage id="BalanceSms.PASS_HEADING" /></h2>
              <p><FormattedMessage id="BalanceSms.PASS_TEXT" /> <b>{phone}</b></p>
              <MaskedInput
                className={style.telInputMask}
                mask={[/[A-Z0-9]/i,/[A-Z0-9]/i,' ',/[A-Z0-9]/i,/[A-Z0-9]/i,' ',/[A-Z0-9]/i,/[A-Z0-9]/i]}
                placeholderChar='_'
                onChange={this.handleSms} 
                onKeyDown={this.handleKeyDown}
                value={sms}
                autoFocus={true}
                type='tel'
                keepCharPositions={true}
              />
              <button className={style.btn} onClick={this.handleLogin} disabled={this.prepareSms(sms).length !== 6}><FormattedMessage id="App.NEXT" /></button>
            </div>
          : <div>
              <p className={style.text}><FormattedMessage id="BalanceSms.PASS_SMS_HEADING" /></p>
              <button className={style.btn} onClick={this.handleSendSms} disabled={phone}><FormattedMessage id="BalanceSms.PASS_SMS" /></button>
              <p className={style.textSm}><FormattedMessage id="BalanceSms.PASS_SMS_INFO" /></p>
            </div>
        }
      </div>
    )
  }
}

BalanceSms.defaultProps = {
  style: require('./BalanceSms.module.scss')
};

BalanceSms.propTypes = {
  userToken: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  userBalanceSms: PropTypes.func.isRequired,
  userBalanceLogin: PropTypes.func.isRequired
};

export default BalanceSms;