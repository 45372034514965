import _ from 'lodash';

export const getPrice = (price, decimals) => {
  return _.round(price, decimals).toFixed(decimals);
}

export const getCurrency = (isoCode, locale) => {
  const currencies = {
    ru: {
      'usd': 'у.е',
      'uah': 'грн.',
    },
    uk: {
      'usd': 'у.о',
      'uah': 'грн.',
    },
  };

  return currencies[locale][isoCode] || null;
}

export const preparePhone = (phone) => {
  return phone.replace(/^\+38/gm, '').replace(/[^\d]/g, '');
}

export const calcProductQty = (type, qty, product) => {
  if (type === 'plus'){
    qty = (+qty%+product.step !== 0) 
      ? _.multiply(_.ceil(_.divide(+qty, +product.step)), +product.step) 
      : _.sum([+qty, +product.step]);
  }else if(type === 'minus'){
    qty = (+qty%+product.step !== 0) 
      ? _.max([_.multiply(_.floor(_.divide(+qty, +product.step)), +product.step), +product.step])
      : _.max([_.subtract(+qty, +product.step), +product.step]);
  }else if (type === 'change'){
    let residue = +qty%+product.step;
    residue = residue === 0 ? 0 : +product.step-residue;
    qty = _.max([+qty+residue, +product.step]);
  }

  return qty;
}

export const decOfNum = (number, titles) => {
  var decCache = [],
      decCases = [2, 0, 1, 1, 1, 2];

  if(!decCache[number]) decCache[number] = number % 100 > 4 && number % 100 < 20 ? 2 : decCases[Math.min(number % 10, 5)];
  return titles[decCache[number]];
}