import Layout from './components/Layout';
import Cabinet from './components/Cabinet';
import NotFound from './components/NotFound';
import Login from './components/Login';
import Catalog from './components/Catalog';
import Product from './components/Product';
import Cart from './components/Cart';
import Contact from './components/Contact';
import Partners from './components/Partners';
import Delivery from './components/Delivery';
import About from './components/About';

const routes = {
  Cabinet: {
    path: '/',
    component: Cabinet,
    layout: Layout,
    withAuth: true
  },
  ProductHistory: {
    path: '/product-history',
    component: Catalog,
    layout: Layout,
    withAuth: true
  },
  Arrival: {
    path: '/arrival/:catId',
    component: Catalog,
    layout: Layout,
    withAuth: true
  },
  Testimonials: {
    path: '/testimonials',
    component: Catalog,
    layout: Layout,
    withAuth: true
  },
  Specials: {
    path: '/specials',
    component: Catalog,
    layout: Layout,
    withAuth: true
  },
  Login: {
    path: '/login',
    component: Login,
    layout: null,
    withAuth: false
  },
  LoginForm: {
    path: '/login-form',
    component: Login,
    layout: null,
    withAuth: false
  },
  Catalog: {
    path: '/catalog/:catId',
    component: Catalog,
    layout: Layout,
    withAuth: true
  },
  Mix: {
    path: '/mix/:catId',
    component: Catalog,
    layout: Layout,
    withAuth: true
  },
  Colorit: {
    path: '/colorit',
    component: Catalog,
    layout: Layout,
    withAuth: true
  },
  Product: {
    path: '/product/:productId',
    component: Product,
    layout: Layout,
    withAuth: true
  },
  Search: {
    path: '/search/:keyword',
    component: Catalog,
    layout: Layout,
    withAuth: true
  },
  Cart: {
    path: '/cart',
    component: Cart,
    layout: Layout,
    withAuth: true
  },
  Contact: {
    path: '/contact',
    component: Contact,
    layout: Layout,
    withAuth: true
  },
  About: {
    path: '/about',
    component: About,
    layout: Layout,
    withAuth: true
  },
  Partners: {
    path: '/partners',
    component: Partners,
    layout: Layout,
    withAuth: true
  },
  Delivery: {
    path: '/delivery',
    component: Delivery,
    layout: Layout,
    withAuth: true
  },
  NotFound: {
    path: null,
    component: NotFound,
    layout: Layout,
    withAuth: false
  }
}

export default routes;