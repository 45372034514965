import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import GradientSVG from 'components/GradientSvg';
import 'components/GradientSvg/styles.css';
import OrdersTable from 'components/Orders/components/OrdersTable';
import StatusIcon from 'components/StatusIcon';
import Banners from './../Banners';
import Loader from 'components/Loader';
import { getPrice, getCurrency, decOfNum } from 'utils/services';

import { ReactComponent as Bullet } from 'shared/img/bullet.svg';

import { STATUSES } from 'components/Cabinet/constants';

class Statistic extends Component {

  componentDidMount() {
    const { userToken, getUserStatistic } = this.props;

    getUserStatistic(userToken);
  }

  componentDidUpdate(prevProps) {
    const { userToken, getUserStatistic } = this.props;

    prevProps.userToken !== userToken && getUserStatistic(userToken);
  }

  render() {
    const { style, user, userStatistic, currentLocale } = this.props;

    const activeStatusIndex = userStatistic.statuses ? STATUSES.findIndex(status=>(userStatistic.statuses[userStatistic.status]===status.id)) : -1;
    const percentage = +userStatistic.percent || 0;

    return (
      <div className={style.wrapper}>
        {userStatistic.fetching
          ? <div className={style.loader}>
              <Loader />
            </div>
          : <div className={style.root}>
              <div className={style.status}>
                <div className={style.statusLeft}>
                  <div className={style.statusLeftInfo}>
                    <div className={style.heading}>
                      <FormattedMessage id="Statistic.STATUS" values={{ status: <strong>{STATUSES[activeStatusIndex] ? STATUSES[activeStatusIndex].name : null}</strong> }} />
                    </div>
                    <div className={style.text}>
                      <FormattedMessage id="Statistic.CONTACT" />
                    </div>
                  </div>
                  <div className={style.statusItems}>
                    {_.map(STATUSES, (status, index) => {
                      return(
                        <div key={status.id} className={classNames(style.statusItem, {[style.active]: (index<=activeStatusIndex), [style.activeStatus]: (index===activeStatusIndex)})}>
                          <div className={style.statusIcon}>
                            <StatusIcon style={status.style} />
                          </div>
                          <div className={style.statusBullet}><Bullet /></div>
                          <div className={classNames(style.statusName, {[style.active]: (user.type===status.id) })}>
                            {status.name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className={style.statusRight}>
                  <div className={style.money}>
                    <div className={style.moneyInfo}>
                      {+userStatistic.contract > 0 
                        ? <div className={style.heading}>
                            <FormattedMessage id="Statistic.CONTRACT" />
                          </div>
                        : <div className={style.heading}>
                            <FormattedMessage id="Statistic.TURNOVER" values={{ turnover: <strong>{ getPrice(userStatistic.turnover, 2) } {getCurrency(user.currency, currentLocale)}</strong> }}/>
                          </div>
                      }
                      <div className={style.text}>
                        <FormattedMessage id="Statistic.TURNOVER_INFO" />
                      </div>
                    </div>
                    <div className={style.moneyProgressWrapper}>
                      <div className={style.moneyProgress}>
                        <CircularProgressbarWithChildren 
                          value={percentage}
                          strokeWidth="6"
                          styles={{
                            path: {
                              stroke: `rgba(254, 148, 0, ${percentage / 100})`,
                            },
                            trail: {
                              stroke: 'rgba(254, 148, 0, 0.2)',
                            }
                          }}
                        >
                          <GradientSVG rotation={`${percentage / 100}`} />
                          <div className={style.moneyProgressCount}>{`${percentage}%`}<div className={style.text}>
                            <FormattedMessage id="Statistic.DONE" />
                          </div></div>
                        </CircularProgressbarWithChildren>
                      </div>
                      <table className={style.moneyOrders}>
                        <tbody>
                          {_.map(userStatistic.counters, (counter, year, index) => 
                            <tr key={`${counter}_${year}`} >
                              <td><div className={style.moneyOrderCount}>{counter}</div></td>
                              <td><FormattedMessage id="Statistic.DONE_ORDERS" values={{ br: <br/>, year: year }} /></td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.banner}>
                <Banners />
              </div>
              <div className={style.table}>
                <div className={style.heading}><FormattedMessage id="Statistic.ORDERS" /></div>
                {userStatistic.lastDeliveredAt 
                  ? <div className={style.text}>
                      <FormattedMessage id="Statistic.LAST_ORDER" values={{ time: <span className={style.textOrange}>за {userStatistic.lastDeliveredAt} {decOfNum(userStatistic.lastDeliveredAt, [<FormattedMessage id="Statistic.HOUR1" />, <FormattedMessage id="Statistic.HOUR2" />, <FormattedMessage id="Statistic.HOUR3" />])}</span> }} />
                    </div>
                  : null
                }
                <div className={style.tableWrapper}>
                  <OrdersTable className='statisticTable' notPaginate={true} perPage={20} limit={20} />
                </div>
              </div>
            </div>
        }
      </div>
    )
  }
}

Statistic.defaultProps = {
  style: require('./Statistic.module.scss')
};

Statistic.propTypes = {
  user: PropTypes.object.isRequired,
  userStatistic: PropTypes.object.isRequired,
  userToken: PropTypes.string.isRequired,
  currentLocale: PropTypes.string.isRequired,
  getUserStatistic: PropTypes.func.isRequired,
};

export default Statistic;