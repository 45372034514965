import { 
  all, 
  call 
} from 'redux-saga/effects';

import { 
  watchUserSms,
  watchUserToken,
  watchGetUser,
  watchGetUserRecipients,
  watchGetUserStatistic,
  watchAddUserRecipinet,
  watchGetDeliveryArea,
  watchGetDeliveryCity,
  watchGetDeliveryWarehouse,
  watchEditUser,
  watchGetPartnersBlanks,
  watchGetUserBalance,
  watchGetSettings,
  watchUserRegister,
  watchFetchBalanceGrid,
  watchUserBalanceSms,
  watchUserBalanceLogin,
  watchGetBanners
} from 'components/User/actions';

import { 
  watchFetchCategories,
  watchFetchArrivals,
  watchFetchCategory,
  watchFetchSubCategory,
  watchDeleteViewed,
  watchFetchFavorites,
  watchfetchLovedCategories,
  watchControlFavorite,
  watchFetchIncomes
} from 'components/Categories/actions';

import { 
  watchFetchProduct
} from 'components/Product/actions';

import { 
  watchAddCart,
  watchChangeCart,
  watchDeleteCart,
  watchFetchCart
} from 'components/Cart/actions';

import {
  watchAddCheckout
} from 'components/Checkout/actions';

import {
  watchFetchOrder,
  watchFetchOrders,
  watchReorderOrder,
  watchDeleteOrder,
  watchInvoiceOrder
} from 'components/Orders/actions';

export default function* sagas() {
  return yield all([
    call(watchUserSms),
    call(watchUserToken),
    call(watchAddCart),
    call(watchChangeCart),
    call(watchDeleteCart),
    call(watchFetchCart),
    call(watchGetUser),
    call(watchGetUserRecipients),
    call(watchFetchCategories),
    call(watchFetchArrivals),
    call(watchFetchCategory),
    call(watchFetchSubCategory),
    call(watchFetchProduct),
    call(watchAddCheckout),
    call(watchFetchOrder),
    call(watchFetchOrders),
    call(watchReorderOrder),
    call(watchDeleteOrder),
    call(watchDeleteViewed),
    call(watchGetUserStatistic),
    call(watchAddUserRecipinet),
    call(watchGetDeliveryArea),
    call(watchGetDeliveryCity),
    call(watchGetDeliveryWarehouse),
    call(watchEditUser),
    call(watchFetchFavorites),
    call(watchControlFavorite),
    call(watchGetPartnersBlanks),
    call(watchGetUserBalance),
    call(watchGetSettings),
    call(watchUserRegister),
    call(watchFetchBalanceGrid),
    call(watchUserBalanceSms),
    call(watchUserBalanceLogin),
    call(watchfetchLovedCategories),
    call(watchFetchIncomes),
    call(watchInvoiceOrder),
    call(watchGetBanners)
  ]);
}