import React, { Component } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { DELIVERY } from './constants';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

class Delivery extends Component {
  componentDidMount() {
    const { getSettings, userToken, settings } = this.props;

    if (_.isEmpty(settings)){
      getSettings(userToken);
    }
  }

  render() {
    const { style, settings, currentLocale } = this.props;

    return(
      <div className={style.root}>
        <Breadcrumbs 
          breadcrumbs={[<FormattedMessage id="Delivery.TITLE" />]} 
        />
        <div className={style.container}>
          <div className={style.box}>
            <div className={style.aboutItems}>
              <div className={style.aboutItem}>
                <div className={style.aboutHeading}><FormattedMessage id="Delivery.ABOUT_1_TITLE" /></div>
                <div className={style.aboutValue}>{settings.deliveryfreelimit}</div>
                <div className={style.aboutText}><FormattedMessage id="Delivery.ABOUT_1_INFO" /></div>
              </div>
              <div className={style.aboutItem}>
                <div className={style.aboutHeading}><FormattedMessage id="Delivery.ABOUT_2_TITLE" /></div>
                <div className={style.aboutValue}>{settings.deliverycost}</div>
                <div className={style.aboutText}><FormattedMessage id="Delivery.ABOUT_2_INFO" /></div>
              </div>
              <div className={style.aboutItem}>
                <div className={style.aboutHeading}><FormattedMessage id="Delivery.ABOUT_3_TITLE" /></div>
                <div className={style.aboutValue}>{settings.optlimit}</div>
                <div className={style.aboutText}><FormattedMessage id="Delivery.ABOUT_3_INFO" /></div>
              </div>
              <div className={style.aboutItem}>
                <div className={style.aboutHeading}><FormattedMessage id="Delivery.ABOUT_4_TITLE" /></div>
                <div className={style.aboutValue}>{settings.deliverydays}</div>
                <div className={style.aboutText}><FormattedMessage id="Delivery.ABOUT_4_INFO" /></div>
              </div>
            </div>
          </div>
          <div className={style.box}>
            <div className={style.boxHeading}><FormattedMessage id="Delivery.SERVICES" /></div>
            <div className={style.delivery}>
              {_.map(DELIVERY[currentLocale], item => <div key={item.id} className={style.deliveryItem}>
                <img className={style.deliveryImg} src={item.img} alt={item.name} />
                <div className={style.deliveryName}>{item.name}</div>
                <div className={style.deliveryLinks}>
                  {_.map(item.links, (link, index) => <div key={`link_${item.id}_${index}`}>
                    <a target='blank' href={link.href}>{link.text}</a>
                  </div>)}
                </div>
              </div>)}
            </div>
          </div>
          <div className={style.box}>
            <div className={style.payment}>
              <div className={style.infoItems}>
                <div className={style.infoItem}>
                  <div className={style.infoIcon}>
                    <FontAwesomeIcon icon={['far', 'file-alt']} />
                  </div>
                  <div className={style.infoContent}>
                    <div className={style.infoTitle}>
                      <FormattedMessage id="Delivery.INFO_1_TITLE" />
                    </div>
                    <div className={style.infoText}>
                      <FormattedMessage id="Delivery.INFO_1_TEXT" />
                    </div>
                  </div>
                </div>
                <div className={style.infoItem}>
                  <div className={style.infoIcon}>
                    <FontAwesomeIcon icon={['far', 'envelope']} />
                  </div>
                  <div className={style.infoContent}>
                    <div className={style.infoTitle}>
                      <FormattedMessage id="Delivery.INFO_2_TITLE" />
                    </div>
                    <div className={style.infoText}>
                      <FormattedMessage id="Delivery.INFO_2_TEXT" />
                    </div>
                  </div>
                </div>
                <div className={style.infoItem}>
                  <div className={style.infoIcon}>
                    <FontAwesomeIcon icon={['far', 'money-bill-alt']} />
                  </div>
                  <div className={style.infoContent}>
                    <div className={style.infoTitle}>
                      <FormattedMessage id="Delivery.INFO_3_TITLE" />
                    </div>
                    <div className={style.infoText}>
                      <FormattedMessage id="Delivery.INFO_3_TEXT" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.bottomItems}>
            <div className={style.bottomItem}>
              <div className={style.bottomHeading}>
                <FormattedMessage id="Delivery.BOTTOM_1_TITLE" />
              </div>
              <div className={style.bottomText}>
                <p><FormattedMessage id="Delivery.BOTTOM_1_TEXT_1" /></p>
                <p><FormattedMessage id="Delivery.BOTTOM_1_TEXT_2" /></p>
                <p><FormattedMessage id="Delivery.BOTTOM_1_TEXT_3" /></p>
              </div>
            </div>
            <div className={style.bottomItem}>
              <div className={style.bottomHeading}>
                <FormattedMessage id="Delivery.BOTTOM_2_TITLE" />
              </div>
              <div className={style.bottomText}>
                <p><FormattedMessage id="Delivery.BOTTOM_2_TEXT_1" /></p>
                <p><FormattedMessage id="Delivery.BOTTOM_2_TEXT_2" /></p>
                <p><FormattedMessage id="Delivery.BOTTOM_2_TEXT_3" /></p>
              </div>
            </div>
            <div className={style.bottomItem}>
              <div className={style.bottomHeading}>
                <FormattedMessage id="Delivery.BOTTOM_3_TITLE" />
              </div>
              <div className={style.bottomText}>
                <p><FormattedMessage id="Delivery.BOTTOM_3_TEXT_1" /></p>
                <p><FormattedMessage id="Delivery.BOTTOM_3_TEXT_2" /></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Delivery.defaultProps = {
  style: require('./Delivery.module.scss')
}

Delivery.propTypes = {
  getSettings: PropTypes.func.isRequired,
  userToken: PropTypes.string.isRequired,
  currentLocale: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired
};

export default Delivery;