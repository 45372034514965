import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CartSuccess from './CartSuccess';

import { resetCheckout } from 'components/Checkout/actions';
import { resetCart } from 'components/Cart/actions';

const mapStateToProps = (state) => ({
  currentLocale: state.lang.currentLocale,
  checkout: state.checkout.checkout,
  currency: state.user.user.currency
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    resetCheckout,
    resetCart
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CartSuccess);
