import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MaskedInput from 'react-text-mask';
import { preparePhone } from 'utils/services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'components/Modal';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

class ChangeProfile extends Component {
  state = {
    name: '',
    phone: [],
    formData: {},
  }

  componentDidMount() {
    const { user } = this.props;

    this.setState({
      name: user.name,
      phone: user.phones.map(phone => phone.normalized),
      formData: {
        city: user.city,
        mail: user.mail && user.mail.email ? user.mail.email : '',
        phone: {}
      }
    });
  }

  handleSubmit = () => {
    const { user, editUser, userToken } = this.props;
    const { formData } = this.state;

    const data = {
      city: formData.city,
      mail: formData.mail,
      phones: _.filter(_.values(formData.phone))
    };

    editUser(user.id, data, userToken);
  }

  handleUserInput = e => {
    const targetName = e.target.name;

    this.setState({
      formData: {
        ...this.state.formData,
        ...{[targetName]: e.target.value}
      }
    });
  }

  handleUserPhone = e => {
    const { formData } = this.state

    this.setState({
      formData: {
        ...formData,
        phone: {
          ...formData.phone,
          ...{[e.target.name]: preparePhone(e.target.value)}
        }
      },
    });
  }

  handleAddPhone = () => {
    const { formData } = this.state

    this.setState({
      formData: {
        ...formData,
        phone: {
          ...formData.phone,
          ...{[_.size(formData.phone)]: ''}
        }
      },
    });
  }

  handleRemovePhone = key => () => {
    const { editPhone } = this.state;

    this.setState({
      editPhone: _.omit(editPhone, key)
    });
  }

  render() {
    const { style, changeProfileIsOpen, toggleChangeProfile, validations } = this.props;
    const { phone, name, formData } = this.state;

    return (
      <Modal
        isOpen={changeProfileIsOpen}
        onRequestClose={toggleChangeProfile}
        content={
          <div className={style.modal}>
            <div className={style.modalHeading}><FormattedMessage id="ChangeProfile.TITLE" /></div>
            <div className={style.warning}><FormattedMessage id="ChangeProfile.INFO" /></div>
            <div className={style.form}>
              <label className={style.formControl}>
                <span><FormattedMessage id="Profile.USER_NAME" /></span>
                <div className={style.disabledInput}>{name}</div>
              </label>
              <label className={classNames(style.formControl, {[style.error]: validations.includes('city')})}>
                <span><FormattedMessage id="Profile.USER_CITY" /></span>
                <input className={style.input} value={formData.city} name='city' onChange={this.handleUserInput} />
              </label>
              <div className={style.formControl}>
                <span><FormattedMessage id="Profile.USER_PHONE" /></span>
                <button className={style.labelBtn} onClick={this.handleAddPhone}><FormattedMessage id="App.ADD_SM" /></button>
                {_.map(phone, (phoneItem, key) => 
                  <div key={key} className={style.phoneItem}>
                    <div className={style.disabledInput}>{phoneItem}</div>
                  </div>
                )}
                {_.map(formData.phone, (editPhoneItem, key) => 
                  <div key={`p_${key}`} className={classNames(style.phoneItem, {[style.error]: validations.includes(key)})}>
                    <MaskedInput
                      className={style.input}
                      mask={[/\d/,/\d/,/\d/,' ',/\d/,/\d/,/\d/,' ',/\d/,/\d/,' ',/\d/,/\d/]}
                      placeholderChar='_'
                      onChange={this.handleUserPhone} 
                      keepCharPositions={true}
                      name={key}
                    />
                    <button className={style.remove} onClick={this.handleRemovePhone(key)}><FontAwesomeIcon icon='times' /></button>
                  </div>
                )}
              </div>
              <label className={classNames(style.formControl, {[style.error]: validations.includes('mail')})}>
                <span><FormattedMessage id="Profile.USER_EMAIL" /></span>
                <input className={style.input} value={formData.mail} name='mail' onChange={this.handleUserInput} />
              </label>
              <div className={style.btnsBox}>
                <button className={style.modalSbt} onClick={this.handleSubmit}><FormattedMessage id="App.SAVE_UP" /></button>
                <button className={style.modalClose} onClick={toggleChangeProfile}><FormattedMessage id="App.CHANGE_CANCEL" /></button>
              </div>
            </div>
          </div>
        }
      />
    );
  }
}

ChangeProfile.defaultProps = {
  style: require('./ChangeProfile.module.scss')
};

ChangeProfile.propTypes = {
  user: PropTypes.object.isRequired,
  changeProfileIsOpen: PropTypes.bool.isRequired,
  toggleChangeProfile: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  validations: PropTypes.array.isRequired,
};

export default ChangeProfile;