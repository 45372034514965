import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import CartConfirm from './CartConfirm';

import { 
  getUserRecipients,
  toggleAddRecipinetProfile
} from 'components/User/actions';

import { 
  addCheckout
} from 'components/Checkout/actions';

import { 
  addCartComment
} from 'components/Cart/actions';

const mapStateToProps = (state) => ({
  cart: state.cart.cart,
  currency: state.user.user.currency,
  decimals: state.user.user.decimals,
  userToken: state.user.token,
  currentLocale: state.lang.currentLocale,
  userRecipients: state.user.recipients.items,
  fetchingCheckout: state.checkout.fetching,
  fetchingUserRecipients: state.user.recipients.fetching,
  cartComment: state.cart.cartComment,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    getUserRecipients,
    addCheckout,
    toggleAddRecipinetProfile,
    addCartComment
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CartConfirm));
