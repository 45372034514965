import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CartModal from './CartModal';

import { fetchCart } from './../../actions';
import { toggleCategories } from 'components/Categories/actions';

const mapStateToProps = (state) => ({
  cart: state.cart.cart,
  userToken: state.user.token,
  currency: state.user.user.currency,
  categoryOpen: state.categories.open,
  currentLocale: state.lang.currentLocale,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    fetchCart,
    toggleCategories
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CartModal);
