import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Order from './Order';
import { 
  fetchOrder,
  reorderOrder
} from './../../actions';

const mapStateToProps = (state) => ({
  currentLocale: state.lang.currentLocale,
  userToken: state.user.token,
  order: state.orders.order,
  groupid: state.user.user.groupid
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    fetchOrder,
    reorderOrder
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Order);
