import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Banners from './Banners';
import { getBanners } from 'components/User/actions';

const mapStateToProps = (state) => ({
  userToken: state.user.token,
  banners: state.user.banners.data,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    getBanners
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Banners);
