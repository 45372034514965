import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import AddRecipinetProfile from './AddRecipinetProfile';
import { 
  getUserRecipients,
  addUserRecipinet,
  toggleAddRecipinetProfile,
  getDeliveryCity,
  getDeliveryWarehouse,
  getDeliveryArea
} from 'components/User/actions';

const mapStateToProps = (state) => ({
  currentLocale: state.lang.currentLocale,
  user: state.user.user,
  userToken: state.user.token,
  userRecipients: state.user.recipients.items,
  fetchingUserRecipients: state.user.recipients.fetching,
  addRecipinetProfileIsOpen: state.user.addRecipinetProfileIsOpen,
  area: state.user.area.items,
  city: state.user.city.items,
  warehouse: state.user.warehouse.items
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    getUserRecipients,
    addUserRecipinet,
    toggleAddRecipinetProfile,
    getDeliveryCity,
    getDeliveryArea,
    getDeliveryWarehouse
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddRecipinetProfile));
