
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

class Breadcrumbs extends Component {
  render() {
    const { style, breadcrumbs, back, element, classnames, invoice } = this.props;

    return (
      <div className={classNames(style.root, classnames.root)}>
        <div className={style.container}>
          <div className={style.wrapper}>
            <div className={classNames(style.breadcrumbs, classnames.breadcrumbs)}>
              {_.map(breadcrumbs, 
                (breadcrumb, key) => <span key={`breadcrumbs${key}`} className={style.breadcrumb}>{ breadcrumb }</span>
              )}
            </div>
            {back && <Link className={style.back} to={back}><FormattedMessage id="Breadcrumbs.BACK" /></Link>}
            {element && <div className={style.element}>{element}</div>}
            {invoice}
          </div>
        </div>
      </div>
    )
  }
}

Breadcrumbs.defaultProps = {
  style: require('./Breadcrumbs.module.scss'),
  classnames: {
    root: null,
    breadcrumbs: null
  }
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  back: PropTypes.string,
  element: PropTypes.object,
  classnames: PropTypes.object,
  invoice: PropTypes.object
};

export default Breadcrumbs;