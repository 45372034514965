import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import OrdersTable from './OrdersTable';
import { 
  fetchOrders,
  reorderOrder,
  deleteOrder,
  invoiceOrder
} from './../../actions';

const mapStateToProps = state => ({
  currentLocale: state.lang.currentLocale,
  ordersData: state.orders.orders,
  ordersFetching: state.orders.fetching,
  userToken: state.user.token,
  groupid: state.user.user.groupid || 0,
  currency: state.user.user.currency,
  decimals: state.user.user.decimals,
  loadMore: state.orders.loadMore,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    fetchOrders,
    reorderOrder,
    deleteOrder,
    invoiceOrder
  }, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrdersTable));
