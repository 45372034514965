exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/layout.scss"), "");
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/inputs.scss"), "");
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss"), "");

// Module
exports.push([module.id, ".ContactForm_root__35isT {\n  padding: 20px; }\n  @media (min-width: 768px) {\n    .ContactForm_root__35isT {\n      padding: 20px 30px; } }\n\n.ContactForm_heading__3Lck7 {\n  font-weight: 700;\n  font-size: 21px;\n  color: #000000;\n  padding-bottom: 20px; }\n\n.ContactForm_info__1RI1X {\n  padding: 15px 0; }\n\n.ContactForm_text__35dUx {\n  font-size: 12px;\n  color: #2C2C2C;\n  letter-spacing: 0;\n  line-height: 19px;\n  margin-bottom: 20px; }\n\n.ContactForm_comment__ZeYHX {\n  margin-bottom: 20px; }\n\n.ContactForm_commentTextarea__2HHXF { }\n\n.ContactForm_btnWrapper__1Al-j {\n  margin: 30px 0 0; }\n\n.ContactForm_btnOrder__3IMgN {\n  display: block;\n  width: 100%; }\n\n.ContactForm_btnLoaderOrder__VWEf9 {\n  background: #EDEDED;\n  cursor: not-allowed; }\n  .ContactForm_btnLoaderOrder__VWEf9:hover {\n    background: #EDEDED; }\n  .ContactForm_btnLoaderOrder__VWEf9 svg {\n    width: 40px;\n    height: 40px; }\n\n.ContactForm_radio__2F-Ws { }\n", ""]);

// Exports
exports.locals = {
	"root": "ContactForm_root__35isT " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/layout.scss").locals["shadow"] + "",
	"heading": "ContactForm_heading__3Lck7",
	"info": "ContactForm_info__1RI1X",
	"text": "ContactForm_text__35dUx",
	"comment": "ContactForm_comment__ZeYHX",
	"commentTextarea": "ContactForm_commentTextarea__2HHXF " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/inputs.scss").locals["textarea"] + "",
	"btnWrapper": "ContactForm_btnWrapper__1Al-j",
	"btnOrder": "ContactForm_btnOrder__3IMgN " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss").locals["cartBtn"] + "",
	"btnLoaderOrder": "ContactForm_btnLoaderOrder__VWEf9 ContactForm_btnOrder__3IMgN " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss").locals["cartBtn"] + "",
	"radio": "ContactForm_radio__2F-Ws " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/inputs.scss").locals["radio"] + ""
};