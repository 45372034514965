import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';

export const logger = createLogger({
  collapsed: true
});

export const sagaMiddleware = createSagaMiddleware();

const middleware = process.env.NODE_ENV === 'development'
  ? [sagaMiddleware, logger]
  : [sagaMiddleware]

export default middleware;
