import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'qs';
import _ from 'lodash';

class WithAuth extends Component {
  componentDidMount() {
    const { getUser, userToken, sentSms } = this.props;

    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    if (params && !_.isEmpty(params.userToken)){
      getUser(params.userToken);
      this.props.history.push('/');
    }else if (!sentSms && !_.isEmpty(userToken)){
      getUser(userToken);
    }
  }

  render() {
    const { userToken } = this.props;

    if (!userToken) {
      return <Redirect to="/login" />;
    }

    return (this.props.component)
  }
}

WithAuth.propTypes = {
  userToken: PropTypes.string.isRequired,
  getUser: PropTypes.func.isRequired,
  sentSms: PropTypes.bool
};

export default WithAuth;