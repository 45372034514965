import { createReducer } from 'redux-act';

import { 
  TOOGLE_CATEGORIES,
  FETCH_CATEGORIES,
  FETCH_ARRIVALS,
  FETCH_CATEGORY,
  FETCH_SUBCATEGORY,
  ASSIGN_CATEGORY,
  SET_LOAD_MORE,
  DELETE_VIEWED,
  FETCH_FAVORITES,
  FETCH_LOVED_CATEGORIES,
  CONTROL_FAVORITE,
  CLEAR_SEARCH_AUTOCOMPLETE,
  FETCH_INCOMES,
} from './../actions/types';

const DEFAULT_STATE = {
  open: false,
  categories: [],
  arrivals: [],
  arrivalsFetching: false,
  arrivalsLimit: 0,
  arrivalsEnd: false,
  favorites: [],
  favoritesFetching: false,
  lovedCategories: [],
  lovedCategoriesFetching: false,
  category: {
    search: {},
    viewed: {}
  },
  subcategory: {},
  subcategoryFetching: false,
  fetching: false,
  loadMore: false,
  errors: {},
  incomes: [],
  incomesFetching: false,
};

export default createReducer({
  [TOOGLE_CATEGORIES]: (state) => ({
    ...state,
    open: !state.open
  }),
  [FETCH_CATEGORIES.REQUEST]: (state) => ({
    ...state,
    fetching: true
  }),
  [FETCH_CATEGORIES.SUCCESS]: (state, payload) => ({
    ...state,
    fetching: false,
    categories: payload
  }),
  [FETCH_CATEGORIES.FAILURE]: (state, error) => ({
    ...state,
    fetching: false,
    errors: error,
  }),
  [FETCH_ARRIVALS.REQUEST]: (state) => ({
    ...state,
    arrivalsFetching: true
  }),
  [FETCH_ARRIVALS.SUCCESS]: (state, payload) => ({
    ...state,
    arrivalsFetching: false,
    arrivalsLimit: payload.limit,
    arrivals: payload.data.items,
    arrivalsEnd: payload.data.total <= payload.limit
  }),
  [FETCH_ARRIVALS.FAILURE]: (state, error) => ({
    ...state,
    arrivalsFetching: false,
    errors: error,
  }),
  [FETCH_FAVORITES.REQUEST]: (state) => ({
    ...state,
    favoritesFetching: true
  }),
  [FETCH_FAVORITES.SUCCESS]: (state, payload) => ({
    ...state,
    favoritesFetching: false,
    favorites: payload
  }),
  [FETCH_FAVORITES.FAILURE]: (state, error) => ({
    ...state,
    favoritesFetching: false,
    errors: error,
  }),
  [FETCH_LOVED_CATEGORIES.REQUEST]: (state) => ({
    ...state,
    lovedCategoriesFetching: true
  }),
  [FETCH_LOVED_CATEGORIES.SUCCESS]: (state, payload) => ({
    ...state,
    lovedCategoriesFetching: false,
    lovedCategories: payload
  }),
  [FETCH_LOVED_CATEGORIES.FAILURE]: (state, error) => ({
    ...state,
    lovedCategoriesFetching: false,
    errors: error,
  }),
  [CONTROL_FAVORITE.REQUEST]: (state) => ({
    ...state,
    fetching: true
  }),
  [CONTROL_FAVORITE.SUCCESS]: (state, payload) => ({
    ...state,
    fetching: false,
    favorites: payload
  }),
  [CONTROL_FAVORITE.FAILURE]: (state, error) => ({
    ...state,
    fetching: false,
    errors: error,
  }),
  [FETCH_CATEGORY.REQUEST]: (state) => ({
    ...state,
    fetching: true,
    subcategoryFetching: true
  }),
  [FETCH_CATEGORY.SUCCESS]: (state, payload) => ({
    ...state,
    fetching: false,
    category: {...state.category, ...payload}
  }),
  [FETCH_CATEGORY.FAILURE]: (state, error) => ({
    ...state,
    fetching: false,
    errors: error,
  }),
  [FETCH_SUBCATEGORY.REQUEST]: (state) => ({
    ...state,
    subcategoryFetching: true
  }),
  [FETCH_SUBCATEGORY.SUCCESS]: (state, payload) => ({
    ...state,
    subcategoryFetching: false,
    subcategory: {
      ...state.subcategory,
      ...payload
    }
  }),
  [FETCH_SUBCATEGORY.FAILURE]: (state, error) => ({
    ...state,
    subcategoryFetching: false,
    errors: error,
  }),
  [ASSIGN_CATEGORY]: (state, payload) => ({
    ...state,
    category: {
      ...state.category,
      [payload.id]: {
        ...payload.data,
        data: [
          ...state.category[payload.id].data,
          ...payload.data.data
        ]
      }
    },
    loadMore: false
  }),
  [SET_LOAD_MORE]: (state) => ({
    ...state,
    loadMore: true
  }),
  [DELETE_VIEWED.SUCCESS]: (state, productId) => ({
    ...state,
    category: {
      ...state.category,
      viewed: {
        ...state.category.viewed,
        data: 
          productId === 'all' 
            ? [] 
            : state.category.viewed.data.filter(product => +product.id !== +productId)
      }
    },
  }),
  [CLEAR_SEARCH_AUTOCOMPLETE]: (state) => ({
    ...state,
    category: {
      ...state.category,
      search: {}
    }
  }),
  [FETCH_INCOMES.REQUEST]: (state) => ({
    ...state,
    incomesFetching: true
  }),
  [FETCH_INCOMES.SUCCESS]: (state, payload) => ({
    ...state,
    incomesFetching: false,
    incomes: payload
  }),
  [FETCH_INCOMES.FAILURE]: (state, error) => ({
    ...state,
    incomesFetching: false,
    errors: error,
  }),
}, DEFAULT_STATE);