import _ from 'lodash';

import { 
  takeLatest,
  call,
  put 
} from 'redux-saga/effects';

import { 
  TOOGLE_CATEGORIES,
  FETCH_CATEGORIES,
  FETCH_ARRIVALS,
  FETCH_CATEGORY,
  FETCH_SUBCATEGORY,
  FETCH_FAVORITES,
  ASSIGN_CATEGORY,
  SET_LOAD_MORE,
  DELETE_VIEWED,
  CONTROL_FAVORITE,
  FETCH_LOVED_CATEGORIES,
  CLEAR_SEARCH_AUTOCOMPLETE,
  FETCH_INCOMES
} from './types';

import { SET_NOTIFICATION } from 'components/Notification/actions/types';

import { 
  apiFetchCategories,
  apiFetchArrivals,
  apiFetchCategory,
  apiFetchSubcategory,
  apiDeleteViewed,
  apiFetchFavorites,
  apifetchLovedCategories,
  apiControlFavorite,
  apiFetchIncomes
} from './../services';

export const toggleCategories = () => ({
  type: TOOGLE_CATEGORIES
});

export function* watchFetchCategories() {
  yield takeLatest(FETCH_CATEGORIES.REQUEST, fetchCategoriesSaga);
}

function* fetchCategoriesSaga(data) {
  try {
    const response = yield call(apiFetchCategories, data.token);
    const categories = response.data;

    // dispatch a success action to the store with the new categories
    yield put({ type: FETCH_CATEGORIES.SUCCESS, payload: categories });
  } catch (error) {
    // dispatch a failure action to the store with the error
    const message = error.message;
    
    yield put({ type: FETCH_CATEGORIES.FAILURE, payload: message });
  }
}

export const fetchCategories = token => ({
  type: FETCH_CATEGORIES.REQUEST,
  token: token
});

export function* watchFetchArrivals() {
  yield takeLatest(FETCH_ARRIVALS.REQUEST, fetchArrivalsSaga);
}

function* fetchArrivalsSaga(data) {
  try {
    const response = yield call(apiFetchArrivals, data.token, '?limit='+data.limit);

    // dispatch a success action to the store with the new arrivals
    yield put({ type: FETCH_ARRIVALS.SUCCESS, payload: {data: response.data, limit: data.limit} });
  } catch (error) {
    // dispatch a failure action to the store with the error
    const message = error.message;
    
    yield put({ type: FETCH_ARRIVALS.FAILURE, payload: message });
  }
}

export const fetchArrivals = (token, limit) => ({
  type: FETCH_ARRIVALS.REQUEST,
  token: token,
  limit: limit
});

export function* watchFetchCategory() {
  yield takeLatest(FETCH_CATEGORY.REQUEST, fetchCategorySaga);
}

function* fetchCategorySaga(data) {
  try {
    const request = data.query ? data.catId+data.query : data.catId;

    if (data.page){
      yield put({ type: SET_LOAD_MORE });
      const catResponse = yield call(apiFetchCategory, request, data.token);
      yield put({ 
        type: ASSIGN_CATEGORY, 
        payload: {
          id: data.catId,
          data: catResponse.data
        } 
      });
    }else{
      const catResponse = yield call(apiFetchCategory, request, data.token);
      yield put({ 
        type: FETCH_CATEGORY.SUCCESS, 
        payload: {
          [data.catId]: catResponse.data
        } 
      });

      if (_.isInteger(+data.catId)){
        const subcatResponse = yield call(apiFetchSubcategory, request, data.token);

        yield put({ 
          type: FETCH_SUBCATEGORY.SUCCESS, 
          payload: {[data.catId]: subcatResponse.data} 
        });
      }else{
        yield put({ type: FETCH_SUBCATEGORY.FAILURE, payload: {} });
      }
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    const message = error.message;
    
    yield put({ type: FETCH_CATEGORY.FAILURE, payload: message });
    yield put({ type: FETCH_SUBCATEGORY.FAILURE, payload: message });
  }
}

export const fetchCategory = (token, catId, page, query) => ({
  type: FETCH_CATEGORY.REQUEST,
  catId: catId,
  token: token,
  page: page,
  query: query
});

export function* watchFetchSubCategory() {
  yield takeLatest(FETCH_SUBCATEGORY.REQUEST, fetchSubCategorySaga);
}

function* fetchSubCategorySaga(data) {
  try {
    const subcatResponse = yield call(apiFetchSubcategory, data.catId, data.token);

    yield put({ 
      type: FETCH_SUBCATEGORY.SUCCESS, 
      payload: {[data.catId]: subcatResponse.data} 
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    const message = error.message;

    yield put({ type: FETCH_SUBCATEGORY.FAILURE, payload: message });
  }
}

export const fetchSubCategory = (catId, token) => ({
  type: FETCH_SUBCATEGORY.REQUEST,
  catId: catId,
  token: token
});

export function* watchDeleteViewed() {
  yield takeLatest(DELETE_VIEWED.REQUEST, deleteViewedSaga);
}

function* deleteViewedSaga(data) {
  try {
    const response = yield call(apiDeleteViewed, data.productId, data.token);

    if (response.data){
      yield put({ type: DELETE_VIEWED.SUCCESS, payload: data.productId });
      const msg = data.productId === 'all' ? 'DELETE_ALL_VIEWED.SUCCESS' : 'DELETE_VIEWED.SUCCESS';
      yield put({ type: SET_NOTIFICATION, payload: {msgId: msg, type: 'success'} })
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    const message = error.message;

    yield put({ type: DELETE_VIEWED.FAILURE, payload: message });
  }
}

export const deleteViewed = (productId, token) => ({
  type: DELETE_VIEWED.REQUEST,
  productId: productId,
  token: token
});

export function* watchFetchFavorites() {
  yield takeLatest(FETCH_FAVORITES.REQUEST, fetchFavoritesSaga);
};

function* fetchFavoritesSaga(data) {
  try {
    const response = yield call(apiFetchFavorites, data.token);

    // dispatch a success action to the store with the new arrivals
    yield put({ type: FETCH_FAVORITES.SUCCESS, payload: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    const message = error.message;
    
    yield put({ type: FETCH_FAVORITES.FAILURE, payload: message });
  }
};

export const fetchFavorites = token => ({
  type: FETCH_FAVORITES.REQUEST,
  token: token
});

export function* watchControlFavorite() {
  yield takeLatest(CONTROL_FAVORITE.REQUEST, controlFavoriteSaga);
};

function* controlFavoriteSaga(data) {
  try {
    const response = yield call(apiControlFavorite, data.data, data.token);

    // dispatch a success action to the store with the new arrivals
    yield put({ type: CONTROL_FAVORITE.SUCCESS, payload: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    const message = error.message;
    
    yield put({ type: CONTROL_FAVORITE.FAILURE, payload: message });
  }
};

export const controlFavorite = (data, token) => ({
  type: CONTROL_FAVORITE.REQUEST,
  data: data,
  token: token
});

export function* watchfetchLovedCategories() {
  yield takeLatest(FETCH_LOVED_CATEGORIES.REQUEST, fetchLovedCategoriesSaga);
};

function* fetchLovedCategoriesSaga(data) {
  try {
    const response = yield call(apifetchLovedCategories, data.token);

    // dispatch a success action to the store with the new arrivals
    yield put({ type: FETCH_LOVED_CATEGORIES.SUCCESS, payload: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    const message = error.message;
    
    yield put({ type: FETCH_LOVED_CATEGORIES.FAILURE, payload: message });
  }
};

export const fetchLovedCategories = token => ({
  type: FETCH_LOVED_CATEGORIES.REQUEST,
  token: token
});

export const clearSearchAutocomplete = () => ({
  type: CLEAR_SEARCH_AUTOCOMPLETE
});

export function* watchFetchIncomes() {
  yield takeLatest(FETCH_INCOMES.REQUEST, fetchIncomesSaga);
};

function* fetchIncomesSaga(data) {
  try {
    const response = yield call(apiFetchIncomes, data.token);

    // dispatch a success action to the store with the new arrivals
    yield put({ type: FETCH_INCOMES.SUCCESS, payload: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    const message = error.message;
    
    yield put({ type: FETCH_INCOMES.FAILURE, payload: message });
  }
};

export const fetchIncomes = token => ({
  type: FETCH_INCOMES.REQUEST,
  token: token
});