import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ChangeProfile from './ChangeProfile';
import { 
  toggleChangeProfile,
  editUser
} from 'components/User/actions';

const mapStateToProps = (state) => ({
  user: state.user.user,
  userToken: state.user.token,
  changeProfileIsOpen: state.user.changeProfileIsOpen,
  validations: state.notification.validations
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    toggleChangeProfile,
    editUser
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ChangeProfile);
