exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/typography.scss"), "");
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss"), "");

// Module
exports.push([module.id, ".Maintenance_root__7_Rnl {\n  text-align: center;\n  background: #0F1F30;\n  min-height: 100vh;\n  min-width: 100%;\n  display: flex;\n  align-items: center;\n  align-content: center;\n  justify-content: center;\n  flex-direction: column;\n  color: #ffffff; }\n\n.Maintenance_gif__39FEc {\n  flex: 0 1 auto;\n  max-width: 400px; }\n\n.Maintenance_heading__1MpBp {\n  flex: 0 1 auto;\n  font-weight: 700;\n  font-size: 20px;\n  margin: 25px 0;\n  color: #ffffff; }\n\n.Maintenance_title__3qz96 {\n  flex: 0 1 auto;\n  color: #CBCBCB;\n  font-size: 16px;\n  line-height: 21px;\n  margin-bottom: 21px; }\n\n.Maintenance_phones__2OOOe {\n  margin: 20px 0;\n  flex: 0 1 auto;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  align-content: center; }\n\n.Maintenance_link__E_4aU {\n  color: #FFFFFF;\n  text-decoration: none;\n  margin: 0 20px;\n  font-weight: 700; }\n  .Maintenance_link__E_4aU span {\n    margin-left: 10px; }\n  .Maintenance_link__E_4aU:hover {\n    color: #EB8C01; }\n\n.Maintenance_logoImg__1yrJJ {\n  flex: 0 1 auto;\n  margin: 25px auto;\n  width: 141px; }\n", ""]);

// Exports
exports.locals = {
	"root": "Maintenance_root__7_Rnl",
	"gif": "Maintenance_gif__39FEc",
	"heading": "Maintenance_heading__1MpBp " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/typography.scss").locals["title"] + "",
	"title": "Maintenance_title__3qz96 " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/typography.scss").locals["text"] + "",
	"phones": "Maintenance_phones__2OOOe",
	"link": "Maintenance_link__E_4aU " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss").locals["btnLink"] + "",
	"logoImg": "Maintenance_logoImg__1yrJJ"
};