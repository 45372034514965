import { createAction, createAsyncAction } from 'utils/actions';

export const SET_LOAD_MORE = createAction('SET_LOAD_MORE');
export const TOOGLE_CATEGORIES = createAction('TOOGLE_CATEGORIES');
export const ASSIGN_CATEGORY = createAction('ASSIGN_CATEGORY');
export const FETCH_CATEGORIES = createAsyncAction('FETCH_CATEGORIES');
export const FETCH_ARRIVALS = createAsyncAction('FETCH_ARRIVALS');
export const FETCH_CATEGORY = createAsyncAction('FETCH_CATEGORY');
export const FETCH_SUBCATEGORY = createAsyncAction('FETCH_SUBCATEGORY');
export const DELETE_VIEWED = createAsyncAction('DELETE_VIEWED');
export const FETCH_FAVORITES = createAsyncAction('FETCH_FAVORITES');
export const FETCH_LOVED_CATEGORIES = createAsyncAction('FETCH_LOVED_CATEGORIES');
export const CONTROL_FAVORITE = createAsyncAction('CONTROL_FAVORITE');
export const CLEAR_SEARCH_AUTOCOMPLETE = createAction('CLEAR_SEARCH_AUTOCOMPLETE');
export const FETCH_INCOMES = createAsyncAction('FETCH_INCOMES');