import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';

import 'swiper/css/swiper.css'

import Loader from 'components/Loader';

class Banners extends Component {

  componentDidMount() {
    const { userToken, getBanners } = this.props;

    getBanners(userToken);
  }

  render() {
    const { style, banners } = this.props;

    const params = {
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    }

    return (
      <div className={style.root}>
        {!_.isEmpty(banners) ? 
          <Swiper {...params}>
            {banners.map(
              banner => 
                <div className={style.banner} key={banner.id}>
                  {!_.isEmpty(banner.link) && banner.link !== '#'
                    ? <a href={banner.link} className={style.link} target={banner.external ? '_blank' : '_self'}>
                        <img src={banner.src} className={style.image} alt="" />
                      </a>
                    : <span className={style.link}>
                        <img src={banner.src} className={style.image} alt="" />
                      </span>
                  }
                </div>
            )}
          </Swiper>
        : <div className={style.loader}><Loader /></div>}
      </div>
    )
  }
}

Banners.defaultProps = {
  style: require('./Banners.module.scss')
};

Banners.propTypes = {
  userToken: PropTypes.string.isRequired,
  getBanners: PropTypes.func.isRequired,
};

export default Banners;