exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/layout.scss"), "");
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/inputs.scss"), "");
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss"), "");

// Module
exports.push([module.id, ".CartConfirm_root__lySNC {\n  padding: 20px; }\n  @media (min-width: 768px) {\n    .CartConfirm_root__lySNC {\n      padding: 20px 30px;\n      margin-bottom: 40px; } }\n\n.CartConfirm_total__1nCKK {\n  font-weight: 700;\n  font-size: 21px;\n  color: #000000;\n  display: flex;\n  align-content: center;\n  align-items: center;\n  justify-content: space-between;\n  border-bottom: 1px solid #D8D8D8;\n  padding-bottom: 20px; }\n\n.CartConfirm_info__2NHVm {\n  padding: 15px 0; }\n\n.CartConfirm_text__2nN5k {\n  font-size: 12px;\n  color: #2C2C2C;\n  letter-spacing: 0;\n  line-height: 19px;\n  margin-bottom: 20px; }\n\n.CartConfirm_comment__2cjyg {\n  margin-bottom: 20px; }\n\n.CartConfirm_commentTextarea__3_fD0 { }\n\n.CartConfirm_selectLoader__sLQos {\n  text-align: center; }\n  .CartConfirm_selectLoader__sLQos svg {\n    width: 50px;\n    height: 10px; }\n\n.CartConfirm_selectOption__18gmk {\n  font-size: 12px; }\n\n.CartConfirm_btnWrapper__3oDoG {\n  margin: 30px 0 10px; }\n\n.CartConfirm_btnOrder__1vX0q {\n  display: block;\n  width: 100%; }\n\n.CartConfirm_btnLoaderOrder__2VOne {\n  background: #EDEDED;\n  cursor: not-allowed; }\n  .CartConfirm_btnLoaderOrder__2VOne:hover {\n    background: #EDEDED; }\n  .CartConfirm_btnLoaderOrder__2VOne svg {\n    width: 40px;\n    height: 40px; }\n\n.CartConfirm_emptyBtn__28DgT {\n  color: #2C2C2C;\n  font-weight: 500;\n  font-size: 16px;\n  margin: 0;\n  text-align: center;\n  display: block;\n  width: 100%;\n  box-sizing: border-box; }\n", ""]);

// Exports
exports.locals = {
	"root": "CartConfirm_root__lySNC " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/layout.scss").locals["shadow"] + "",
	"total": "CartConfirm_total__1nCKK",
	"info": "CartConfirm_info__2NHVm",
	"text": "CartConfirm_text__2nN5k",
	"comment": "CartConfirm_comment__2cjyg",
	"commentTextarea": "CartConfirm_commentTextarea__3_fD0 " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/inputs.scss").locals["textarea"] + "",
	"selectLoader": "CartConfirm_selectLoader__sLQos",
	"selectOption": "CartConfirm_selectOption__18gmk",
	"btnWrapper": "CartConfirm_btnWrapper__3oDoG",
	"btnOrder": "CartConfirm_btnOrder__1vX0q " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss").locals["cartBtn"] + "",
	"btnLoaderOrder": "CartConfirm_btnLoaderOrder__2VOne CartConfirm_btnOrder__1vX0q " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss").locals["cartBtn"] + "",
	"emptyBtn": "CartConfirm_emptyBtn__28DgT " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss").locals["link"] + ""
};