exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".layout_fullHeight__1fiFl {\n  min-height: calc(100vh - 151px - 36px - 100px - 102px); }\n\n.layout_container__I3IVj {\n  margin: auto;\n  max-width: calc(100% - 30px); }\n  @media (min-width: 768px) {\n    .layout_container__I3IVj {\n      max-width: calc(100% - 80px); } }\n  @media (min-width: 1280px) {\n    .layout_container__I3IVj {\n      max-width: 1185px; } }\n\n.layout_loader__NJYS1 {\n  text-align: center;\n  margin: 0; }\n  .layout_loader__NJYS1 > div {\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%); }\n\n.layout_shadow__gu4oS {\n  background: #FFFFFF;\n  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15); }\n  @media (min-width: 768px) {\n    .layout_shadow__gu4oS {\n      border-radius: 5px; } }\n\n.layout_boxShadow__B6Scf {\n  margin: 15px 0;\n  padding: 15px; }\n  @media (min-width: 768px) {\n    .layout_boxShadow__B6Scf {\n      margin: 30px 0;\n      padding: 30px; } }\n\n.layout_empty__wnnHU {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  font-weight: 700;\n  font-size: 22px;\n  color: #3C4144;\n  margin-bottom: 22px;\n  text-align: center; }\n  @media (min-width: 768px) {\n    .layout_empty__wnnHU {\n      margin-bottom: 25px;\n      font-size: 25px; } }\n", ""]);

// Exports
exports.locals = {
	"fullHeight": "layout_fullHeight__1fiFl",
	"container": "layout_container__I3IVj",
	"loader": "layout_loader__NJYS1 layout_fullHeight__1fiFl",
	"shadow": "layout_shadow__gu4oS",
	"boxShadow": "layout_boxShadow__B6Scf",
	"empty": "layout_empty__wnnHU"
};