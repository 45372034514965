exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!./../../Login.module.scss"), "");
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss"), "");
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/inputs.scss"), "");

// Module
exports.push([module.id, ".LoginForm_root__qFNTG {\n  width: 100%;\n  display: flex;\n  height: 100%;\n  align-content: flex-end;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  height: calc(100vh - 80px);\n  min-height: 480px; }\n  @media (min-width: 768px) {\n    .LoginForm_root__qFNTG {\n      min-height: 600px;\n      justify-content: center; } }\n\n.LoginForm_logoBtn__1X6l2 {\n  display: block; }\n  @media (min-width: 768px) {\n    .LoginForm_logoBtn__1X6l2 {\n      text-align: left;\n      width: 100%; } }\n\n.LoginForm_logoImg__v_kAf {\n  flex: 0 1 auto;\n  width: 220px;\n  margin: 0 auto 25px;\n  display: block; }\n  @media (min-width: 768px) {\n    .LoginForm_logoImg__v_kAf {\n      width: 300px;\n      margin-left: 0; } }\n\n.LoginForm_html__3Cjtl { }\n\n.LoginForm_btn__1knHr { }\n\n.LoginForm_telInputMask__2Dfmk {\n  width: 100%;\n  max-width: 300px;\n  box-sizing: border-box;\n  margin: 30px auto 50px;\n  text-align: center; }\n  .LoginForm_telInputMask__2Dfmk.LoginForm_notValid__3cb13 {\n    border-color: #a71d2a;\n    background-color: rgba(220, 53, 69, 0.64); }\n", ""]);

// Exports
exports.locals = {
	"root": "LoginForm_root__qFNTG",
	"logoBtn": "LoginForm_logoBtn__1X6l2",
	"logoImg": "LoginForm_logoImg__v_kAf",
	"html": "LoginForm_html__3Cjtl " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!./../../Login.module.scss").locals["html"] + "",
	"btn": "LoginForm_btn__1knHr " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss").locals["btn-border-svg"] + "",
	"telInputMask": "LoginForm_telInputMask__2Dfmk " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/inputs.scss").locals["input"] + "",
	"notValid": "LoginForm_notValid__3cb13"
};