import { 
  takeLatest,
  put 
} from 'redux-saga/effects';

import _ from 'lodash';

import { 
  ADD_CHECKOUT,
  RESET_CHECKOUT
} from './types';

import { SET_NOTIFICATION } from 'components/Notification/actions/types';

import { apiAddCheckout } from './../services';

export function* watchAddCheckout() {
  yield takeLatest(ADD_CHECKOUT.REQUEST, addCheckoutSaga);
}

function* addCheckoutSaga(data) {
  try {
    const response = yield apiAddCheckout(data.recipientId, data.comment, data.token);

    yield put({ type: ADD_CHECKOUT.SUCCESS, payload: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ADD_CHECKOUT.FAILURE, payload: error.message });

    let errMsg = error.response.data.message || 'error';

    if (_.isObject(errMsg) && errMsg.validation){
      errMsg = _.values(errMsg.validation);
    }

    yield put({ type: SET_NOTIFICATION, payload: {msgId: errMsg} });
  }
}

export const addCheckout = (recipientId, comment, token) => ({
  type: ADD_CHECKOUT.REQUEST,
  recipientId: recipientId,
  comment: comment,
  token: token
});

export const resetCheckout = () => ({
  type: RESET_CHECKOUT
});
