import React, { Component } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

class About extends Component {
  render() {
    const { style } = this.props;

    const fromYear = moment('1999', 'YYYY').fromNow(true);

    // const press = <button className={style.pressBtn}><span>СКАЧАТЬ ПРЕСС-РЕЛИЗ</span></button>

    return(
      <div className={style.root}>
        <Breadcrumbs 
          breadcrumbs={[<FormattedMessage id="About.TITLE" />]} 
          // element={press}
        />
        <div className={style.bg}>
          <div className={style.bgText}>
            <FormattedMessage id="About.HEADING" />
          </div>
        </div>
        <div className={style.container}>
          <div className={style.box}>
            <div className={style.heading}>
              <FormattedMessage id="About.BOX_HEADING" />
            </div>
            <div className={style.quote}>
              <FormattedMessage id="About.BOX_TEXT" />
            </div>
            <div className={style.author}>
              <FormattedMessage id="About.BOX_AUTHOR" />
            </div>
          </div>
        </div>
        <div className={style.container}>
          <div className={style.box}>
            <div className={style.infoItems}>
              <div className={style.infoItem}>
                <div className={style.infoIcon}>
                  <FontAwesomeIcon icon='money-bill' />
                </div>
                <div className={style.infoContent}>
                  <div className={style.infoTitle}>
                    <FormattedMessage id="About.INFO_1_TITLE" />
                  </div>
                  <div className={style.infoText}>
                    <FormattedMessage id="About.INFO_1_TEXT" />
                  </div>
                </div>
              </div>
              <div className={style.infoItem}>
                <div className={style.infoIcon}>
                  <FontAwesomeIcon icon='truck' />
                </div>
                <div className={style.infoContent}>
                  <div className={style.infoTitle}>
                    <FormattedMessage id="About.INFO_2_TITLE" />
                  </div>
                  <div className={style.infoText}>
                    <FormattedMessage id="About.INFO_2_TEXT" />
                  </div>
                </div>
              </div>
              <div className={style.infoItem}>
                <div className={style.infoIcon}>
                  <FontAwesomeIcon icon='hands-helping' />
                </div>
                <div className={style.infoContent}>
                  <div className={style.infoTitle}>
                    <FormattedMessage id="About.INFO_3_TITLE" />
                  </div>
                  <div className={style.infoText}>
                    <FormattedMessage id="About.INFO_3_TEXT" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.textBox}>
            <p><FormattedMessage id="About.TEXT_1" values={{ fromYear: fromYear }} /></p>
            <p><FormattedMessage id="About.TEXT_2" /></p>
            <p><FormattedMessage id="About.TEXT_3" /></p>
          </div>
        </div>
      </div>
    )
  }
}

About.defaultProps = {
  style: require('./About.module.scss')
}

export default About;