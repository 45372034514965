import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {default as ReactModal} from 'react-modal';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

class Modal extends Component {
  render() {
    const { style, isOpen, onRequestClose, content } = this.props;

    return (
      <div className={style.root}>
        <ReactModal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          ariaHideApp={false}
          className={style.modal}
          overlayClassName={style.overlay}
        >
          <TransitionGroup>
            <CSSTransition key='ModalComponent' timeout={{ enter: 300, exit: 200 }} classNames='fade' appear>
              {content}
            </CSSTransition>
          </TransitionGroup>
        </ReactModal>
      </div>
    )
  }
}

Modal.defaultProps = {
  style: require('./Modal.module.scss')
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
};


export default Modal;