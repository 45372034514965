import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from './Header';

import { toggleCategories } from 'components/Categories/actions';

const mapStateToProps = (state) => ({
  user: state.user.user,
  categoryOpen: state.categories.open
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    toggleCategories
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Header);