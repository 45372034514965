import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from 'components/Breadcrumbs';
import Loader from 'components/Loader';
import ListProducts from 'components/ListProducts';
import CartConfirm from './../CartConfirm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import { CONSTANTS } from './constants';

class CartOrder extends Component {
  componentDidMount() {
    const { userToken, cart } = this.props;

    if (_.isEmpty(cart)){
      this.props.fetchCart(userToken);
    }
  }

  render() {
    const { style, cart, fetching, currency, currentLocale } = this.props;

    return (
      <div className={style.root}>
        <Breadcrumbs breadcrumbs={CONSTANTS[currentLocale].breadcrumbs} />
        <div className={style.container}>
        {!_.isEmpty(cart.items)
          ? <div className={style.wrapper}>
              <div className={style.col}>
                <ListProducts products={cart.items} currency={currency} />
              </div>
              <div className={style.col}>
                <CartConfirm />
                <div className={style.info}>
                  <FontAwesomeIcon icon='user' />
                  <div className={style.infoText}>
                    <h4 className={style.infoTitle}><FormattedMessage id="CartOrder.HEADING_INFO" /></h4>
                    <p><FormattedMessage id="CartOrder.TEXT_INFO" /></p>
                  </div>
                </div>
                <div className={style.info}>
                  <FontAwesomeIcon icon='truck' flip='horizontal' />
                  <div className={style.infoText}>
                    <h4 className={style.infoTitle}><FormattedMessage id="CartOrder.HEADING_DELEVERY" /></h4>
                    <p><FormattedMessage id="CartOrder.TEXT_DELEVERY" /></p>
                  </div>
                </div>
              </div>
            </div>
          : fetching 
              ? <div className={style.empty}>
                  <Loader />
                </div>
              : <div className={style.empty}>
                  <p className={style.emptyText}><FormattedMessage id="CartOrder.EMPTY" /></p>
                </div>
        }
        </div>
      </div>
    )
  }
}

CartOrder.defaultProps = {
  style: require('./CartOrder.module.scss')
};

CartOrder.propTypes = {
  currentLocale: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired,
  cart: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  fetchCart: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired
};

export default CartOrder;