import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProductCard from './ProductCard';

import { addCart } from 'components/Cart/actions';

const mapStateToProps = state => ({
  userToken: state.user.token,
  currency: state.user.user.currency,
  currentLocale: state.lang.currentLocale,
  decimals: state.user.user.decimals,
  cartProducts: state.cart.cart.items || []
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    addCart
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
