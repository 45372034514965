import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { preparePhone } from 'utils/services';

import Logo from 'shared/img/logo_big.png';

class RegisterForm extends Component {

  state = {
    name: '',
    city: '',
    mail: '',
    disableRegister: true
  }

  componentWillUnmount() {
    this.props.unsetUserNotRegister();
  }

  handleRegister = () => {
    const { setNotification, userRegister, phone } = this.props;
    const { name, city, mail } = this.state;

    if (phone.length){
      userRegister({
        phone: preparePhone(phone),
        name: name,
        city: city,
        mail: mail
      });
      // this.setState({
      //   name: '',
      //   city: '',
      //   mail: ''
      // });
    }else{
      setNotification('loginPhoneRequired');
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleRegister();
    }
  }

  handleInput = e => {
    const { name, city, mail } = this.state;
    const { phone } = this.props;

    if (phone.length && name.length && city.length && mail.length){
      this.setState({
        disableRegister: false
      });
    }else{
      this.setState({
        disableRegister: true
      });
    }

    this.setState({
      [e.target.name]: e.target.value
    });
  }

  render() {
    const { name, city, mail, disableRegister } = this.state;
    const { style, intl } = this.props;

    return (
      <div className={style.root}>
        <Link className={style.logoBtn} to='/' onClick={this.handleLogo}>
          <img src={Logo} alt='Sparta лого' className={style.logoImg} />
        </Link>
        <div className={style.html}>
          <h2><FormattedMessage id="Login.REGISTER" /></h2>
          <p><FormattedMessage id="Login.REGISTER_INFO" /></p>
        </div>
        <input
          className={style.input}
          value={name}
          name='name'
          onKeyDown={this.handleKeyDown}
          onChange={this.handleInput}
          placeholder={intl.formatMessage({id: 'Profile.USER_NAME'})}
        />
        <input
          className={style.input}
          value={city}
          name='city'
          onKeyDown={this.handleKeyDown}
          onChange={this.handleInput}
          placeholder={intl.formatMessage({id: 'Profile.USER_CITY'})}
        />
        <input
          className={style.input}
          value={mail}
          name='mail'
          onKeyDown={this.handleKeyDown}
          onChange={this.handleInput}
          placeholder={intl.formatMessage({id: 'Profile.USER_EMAIL'})}
        />
        <button
          className={style.btn}
          onClick={this.handleRegister}
          disabled={disableRegister}
        >
          <FormattedMessage id="Login.REGISTER_BTN" />
        </button>
      </div>
    )
  }
}

RegisterForm.defaultProps = {
  style: require('./RegisterForm.module.scss')
};

RegisterForm.propTypes = {
  intl: PropTypes.object.isRequired,
  phone: PropTypes.string.isRequired,
  setNotification: PropTypes.func.isRequired,
  userRegister: PropTypes.func.isRequired,
  unsetUserNotRegister: PropTypes.func.isRequired
};

export default RegisterForm;