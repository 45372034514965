import {
  SET_NOTIFICATION
} from './types';

export const setNotification = (msgId, type) => ({
  type: SET_NOTIFICATION,
  payload: {
    msgId: msgId,
    type: type
  }
});