import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContactForm from './ContactForm';

import { setNotification } from 'components/Notification/actions';

const mapStateToProps = state => ({
  userToken: state.user.token
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setNotification
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
