import { createReducer } from 'redux-act';

import { 
  FETCH_PRODUCT
} from './../actions/types';

const DEFAULT_STATE = {
  products: [],
  active: 0
};

export default createReducer({
  [FETCH_PRODUCT.REQUEST]: (state) => ({
    ...state,
    fetching: true
  }),
  [FETCH_PRODUCT.SUCCESS]: (state, payload) => ({
    ...state,
    fetching: false,
    products: {...state.products, ...payload.data},
    active: payload.productId
  }),
  [FETCH_PRODUCT.FAILURE]: (state, error) => ({
    ...state,
    fetching: false,
    errors: error,
  }),
}, DEFAULT_STATE);