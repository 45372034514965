import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RegisterForm from './RegisterForm';
import { setNotification } from 'components/Notification/actions';
import { 
  userRegister,
  unsetUserNotRegister
} from 'components/User/actions';

const mapStateToProps = (state) => ({
  phone: state.user.user.phone || ''
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    setNotification,
    userRegister,
    unsetUserNotRegister
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);