import { createReducer } from 'redux-act';

import { 
  FETCH_ORDER,
  FETCH_ORDERS,
  DELETE_ORDER,
  SET_ORDER_LOAD_MORE,
  ASSIGN_ORDERS
} from './../actions/types';

const DEFAULT_STATE = {
  orders: {},
  order: {},
  fetching: false,
  errors: {},
  loadMore: false
};

export default createReducer({
  [FETCH_ORDERS.REQUEST]: (state) => ({
    ...state,
    fetching: true
  }),
  [FETCH_ORDERS.SUCCESS]: (state, payload) => ({
    ...state,
    fetching: false,
    orders: payload,
  }),
  [FETCH_ORDERS.FAILURE]: (state, error) => ({
    ...state,
    fetching: false,
    errors: error,
  }),
  [ASSIGN_ORDERS]: (state, payload) => ({
    ...state,
    orders: {
      ...payload,
      data: [
        ...state.orders.data,
        ...payload.data
      ]
    },
    loadMore: false
  }),
  [FETCH_ORDER.REQUEST]: (state) => ({
    ...state,
    orderFetching: true
  }),
  [FETCH_ORDER.SUCCESS]: (state, payload) => ({
    ...state,
    orderFetching: false,
    order: {
      ...state.order,
      ...payload
    }
  }),
  [FETCH_ORDER.FAILURE]: (state, error) => ({
    ...state,
    orderFetching: false,
    errors: error,
  }),
  [DELETE_ORDER.SUCCESS]: (state, orderId) => ({
    ...state,
    orders: {
      ...state.orders,
      data: state.orders.data.filter(order => +order.id !== +orderId)
    },
  }),
  [SET_ORDER_LOAD_MORE]: (state) => ({
    ...state,
    loadMore: true
  }),
}, DEFAULT_STATE);