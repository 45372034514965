import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cabinet from './Cabinet';

import { resetUser } from 'components/User/actions';
import { deleteViewed } from 'components/Categories/actions';

const mapStateToProps = (state) => ({
  user: state.user.user,
  userToken: state.user.token,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    resetUser,
    deleteViewed
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Cabinet);
