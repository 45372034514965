import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './reducers';
import middleware from './middleware';

const initialState = {}

export default createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
