import { createReducer } from 'redux-act';

import { 
  ADD_CHECKOUT, 
  RESET_CHECKOUT
} from './../actions/types';

const DEFAULT_STATE = {
  checkout: {},
  fetching: false,
  errors: {}
};

export default createReducer({
  [ADD_CHECKOUT.REQUEST]: (state) => ({
    ...state,
    fetching: true
  }),
  [ADD_CHECKOUT.SUCCESS]: (state, payload) => ({
    ...state,
    fetching: false,
    checkout: payload
  }),
  [ADD_CHECKOUT.FAILURE]: (state, error) => ({
    ...state,
    fetching: false,
    errors: error,
  }),
  [RESET_CHECKOUT]: state => ({
    ...state,
    checkout: {}
  })
}, DEFAULT_STATE);