import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { 
  BrowserRouter, 
  Route,
  Switch
} from 'react-router-dom';
import axios from 'axios';
import { IntlProvider } from 'react-intl';
import store from './store';
import saga from './sagas';
import routes from './routes';
import _ from 'lodash';
import { sagaMiddleware } from './middleware';
import WithAuth from './components/WithAuth';
import Maintenance from './components/Maintenance';
import Loader from './components/Loader';
import moment from 'moment';
import ru from 'moment/locale/ru';
import uk from 'moment/locale/uk';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { apiGetMaintenance } from './components/User/services';
import { messages } from "./i18n/messages";

// Fonts
import 'roboto-fontface/css/roboto/sass/roboto-fontface.scss';
import 'typeface-pt-serif/index.css';

// react-fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { 
  faTimes,
  faUser,
  faShoppingCart,
  faSearch,
  faBars,
  faTruck,
  faShieldAlt,
  faThumbsUp,
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faCaretRight,
  faPhoneAlt,
  faEllipsisV,
  faMoneyBill,
  faHandsHelping,
  faDownload,
  faFileAlt,
  faEnvelope,
  faCog,
  faMinusCircle,
  faPlusCircle,
  faLeaf,
  faArrowsAlt,
  faFlag,
  faPaperPlane,
  faInfo,
  faCheck,
  faLongArrowAltUp,
  faAngleDown,
  faThList
} from '@fortawesome/free-solid-svg-icons';

import { 
  faMoneyBillAlt,
  faEnvelope as farEnvelope,
  faFileAlt as farFileAlt
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faTimes,
  faUser,
  faShoppingCart,
  faSearch,
  faBars,
  faTruck,
  faShieldAlt,
  faThumbsUp,
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faCaretRight,
  faPhoneAlt,
  faEllipsisV,
  faMoneyBill,
  faHandsHelping,
  faDownload,
  faFileAlt,
  faEnvelope,
  faCog,
  faMinusCircle,
  faPlusCircle,
  faLeaf,
  faArrowsAlt,
  faFlag,
  faPaperPlane,
  faMoneyBillAlt,
  farEnvelope,
  farFileAlt,
  faInfo,
  faCheck,
  faLongArrowAltUp,
  faAngleDown,
  faThList
);

const loadMomentLocale = (locale) => {
  moment.locale(locale);
}

const setAxiosLang = (locale) => {
  axios.interceptors.request.use(function (config) {
    config.headers['Accept-Language'] = locale;

    return config;
  });
}

class App extends Component {
  state = {
    isMaintenanceMode: false,
    isLoading: true
  };

  componentDidMount(){
    apiGetMaintenance().then(response => {
      if (response.status === 200 && response.data === 1){
        this.setState({
          isMaintenanceMode: true,
          isLoading: false
        });
      }else{
        this.setState({
          isLoading: false
        });
      }
    });
  }

  render() {
    const { isLoading, isMaintenanceMode } = this.state;
    const { lang } = store.getState();
    const { currentLocale } = lang;
    setAxiosLang(currentLocale);
    loadMomentLocale(currentLocale);

    return (
      <Provider store={store}>
        <IntlProvider
          messages={messages[currentLocale]}
          locale={currentLocale}
          defaultLocale={currentLocale}
        >
          {isLoading 
            ? <div className='loader'>
                <Loader />
              </div>
            : isMaintenanceMode
              ?  <Maintenance />
              :  <BrowserRouter>
                    <Switch>
                      {
                        _.map(routes, (route, key) => {
                          return(
                            <Route 
                              exact 
                              path={route.path} 
                              key={key}
                              render={
                                props => {
                                  const currentKey = props.location.pathname.split('/')[1] || '/'

                                  const component = 
                                    <TransitionGroup>
                                      <CSSTransition key={currentKey} timeout={{ enter: 300, exit: 200 }} classNames='fade' appear>
                                        {route.layout
                                          ? <route.layout 
                                              {...props}
                                              component={route.component}
                                            /> 
                                          : <route.component {...props} />
                                        }
                                      </CSSTransition>
                                    </TransitionGroup>

                                  return(
                                    route.withAuth
                                      ? <WithAuth {...props} component={component} />
                                      : component
                                  )
                                }
                              }
                            />
                          )
                        })
                      }
                    </Switch>
                  </BrowserRouter>
          }
        </IntlProvider>
      </Provider>
    );
  }
}

export default App;

sagaMiddleware.run(saga);
