import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import fileDownload from 'js-file-download';
// import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Loader from 'components/Loader';
import Breadcrumbs from 'components/Breadcrumbs';
import ProductCard from 'components/ProductCard';
import CatalogFilter from './components/CatalogFilter';
import Modal from 'components/Modal';

import emptyGif from 'shared/img/travolta.gif';

import { apiFetchArrivalInvoice } from './../Categories/services';

// import { CONSTANTS } from './constants';

class Catalog extends Component {
  state = {
    category: false,
    perPage: 21,
    currentPage: 1,
    catId: null,
    subCatId: null,
    hash: null,
    removeBtn: false,
    arrivalFetching: false,
    downloadInvoiceModal: false,
    invoiceFee: 0
  }

  static getDerivedStateFromProps(nextProps, prevState){
    const { match: { params }, location: { pathname, hash } } = nextProps;

    let catId = params.catId;
    let subCatId = params.catId;
    let removeBtn = false;

    if (prevState.catId !== catId){
      if (params.keyword){
        catId = 'search';
      }else if (pathname === '/testimonials'){
        catId = 81375;
      }else if (hash === '#viewed'){
        removeBtn = true
        catId = 'viewed';
      }else if (hash === '#specials'){
        // catId = !catId ? '0-100' : catId;
        catId = '0-100';
        catId = `sale/${catId}`;
      }else if (pathname === '/colorit'){
        catId = 'brand/157';
      }else if (pathname.includes('/arrival/')){
        catId = `arrival/${catId}`;
      }else if (pathname.includes('/mix/')){
        catId = `mix/${catId}`;
      }
    }

    return {
      catId: catId,
      subCatId: subCatId,
      hash: hash,
      removeBtn: removeBtn
    };
  }

  componentDidMount() {
    this.getCategory();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.catId !== this.props.match.params.catId) {
      this.getCategory();
    }else if (prevProps.match.params.keyword !== this.props.match.params.keyword){
      this.getCategory();
    }else if (prevState.catId !== this.state.catId){
      this.getCategory();
    }

    if (prevProps.category[this.state.catId] && prevProps.category[this.state.catId].category){
      const parentId = prevProps.category[this.state.catId].category.parentid;

      if (parentId !== 0 && this.props.subcategory[this.state.catId] && _.isEmpty(this.props.subcategory[parentId])){
        this.props.fetchSubCategory(parentId, this.props.userToken);
      }
    }
  }

  getCategory(){
    const { match: { params }, userToken, fetchCategory } = this.props;
    const { catId } = this.state;

    let query;
    if (params.keyword){
      query = '?keyword='+params.keyword;
    }

    fetchCategory(userToken, catId, null, query);
  }

  handleMore = () => {
    const { category, userToken, match: { params }, fetchCategory } = this.props;
    const { catId } = this.state;

    this.setState({currentPage: this.state.currentPage+1, loadMore: true})

    const categoryData = category[catId];

    let nextPage = _.min([_.sum([categoryData.current_page, 1]), categoryData.last_page]);

    let query = '?page='+nextPage;
    if (params.keyword){
      query += '&keyword='+params.keyword;
    }

    fetchCategory(userToken, catId, nextPage, query);
  }

  handleArrivalInvoice = () => {
    const { setNotification, userToken, match: {params} } = this.props;
    const { invoiceFee } = this.state;

    this.setState({arrivalFetching: true});

    apiFetchArrivalInvoice(params.catId, invoiceFee, userToken).then(response => {
      if (response.status === 200){
        fileDownload(response.data, `Инвойс_${params.catId}.xlsx`);
        this.handleInvoiceModal();
      }else{
        setNotification('failApiFetchArrivalInvoice');
      }
      this.setState({arrivalFetching: false});
    }).catch(function (error) {
      setNotification('error');
    });
  }

  handleInvoiceModal = () => {
    const { downloadInvoiceModal } = this.state;

    this.setState({
      downloadInvoiceModal: !downloadInvoiceModal
    });
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleArrivalInvoice();
    }
  }

  handleFeeInput = e => {
    this.setState({
      invoiceFee: _.inRange(e.target.value, 100) ? e.target.value : ''
    });
  }

  render() {
    const { styleModal, style, category, loadMore, hideBreadcrumbs, subcategory, subcategoryFetching, handleRemove, match: {params}, categoryFetching, intl } = this.props;
    const { currentPage, catId, hash, removeBtn, arrivalFetching, downloadInvoiceModal, invoiceFee } = this.state;

    const categoryData = category[catId];

    let subcategories = [];
    if (categoryData && !_.isEmpty(categoryData)){
      if (categoryData.category){
        const parentId = !categoryData.category.parentid ? categoryData.category.id : categoryData.category.parentid;
        subcategories = [...subcategories, {
          id: parentId,
          name: intl.formatMessage({id: 'Catalog.ALL_GOODS'})
        }];
  
        if (!_.isEmpty(subcategory[parentId])){
          subcategories = [...subcategories, ...subcategory[parentId]];
        } 
      }
    }

    return (
      <div className={style.root}>
        <Modal
          isOpen={downloadInvoiceModal}
          onRequestClose={this.handleInvoiceModal}
          content={
            <div className={styleModal.modal}>
              <div className={styleModal.modalHeading}><FormattedMessage id="Catalog.INV_DOWNLOAD" /></div>
              <div className={styleModal.warning}><FormattedMessage id="Catalog.INV_DOWNLOAD_TEXT" /></div>
              <div className={styleModal.form}>
                <label className={styleModal.formControl}>
                  <span><FormattedMessage id="Catalog.INV_PERCENT" /></span>
                  <input 
                    className={styleModal.inputCenter} 
                    type='number' 
                    name='fee' 
                    onChange={this.handleFeeInput} 
                    onKeyDown={this.handleKeyDown} 
                    value={invoiceFee}
                    autoFocus={true}
                  />
                </label>
                <div className={styleModal.btnsBox}>
                  <button className={styleModal.modalSbt} onClick={this.handleArrivalInvoice} disabled={arrivalFetching}>
                    {arrivalFetching
                      ? <div className={styleModal.sbtLoader}>
                          <Loader />
                        </div>
                      : <FormattedMessage id="App.DOWNLOAD" />
                    }
                    </button>
                  <button className={styleModal.modalClose} onClick={this.handleInvoiceModal}><FormattedMessage id="App.DOWNLOAD_CANCEL" /></button>
                </div>
              </div>
            </div>
          }
        />
        {!hideBreadcrumbs &&
          <Breadcrumbs 
            breadcrumbs = {!_.isEmpty(categoryData) ? categoryData.breadcrumbs : []} 
            element={
              <CatalogFilter 
                pathname='catalog' 
                selected={catId} 
                items={subcategories} 
                fetching={subcategoryFetching}
                intl={intl}
              />
            }
            invoice={catId.includes('arrival/') 
              ? <button className={style.invoice} onClick={this.handleInvoiceModal}><FormattedMessage id="Catalog.INV" values={{ inv: params.catId }}/></button>
              : null
            }
            invoiceFetching={arrivalFetching}
          />
        }
        {categoryData
          ? <div className={style.wrapper}>
              {/* Specials filters */}
              {/* {hash === '#specials' && 
                <div className={style.filterWrapper}>
                  <CatalogFilter 
                    pathname='#specials' 
                    selected={catId} 
                    fetching={false}
                    items={CONSTANTS.SALE_FILTERS} 
                    intl={intl}
                  />
                </div>
              } */}
              {hash === '#viewed' && handleRemove && !_.isEmpty(categoryData.data) &&
                <div className={style.filterWrapper}>
                  <button className={style.btnRemoveAll} onClick={handleRemove('all')}><span><FormattedMessage id="Catalog.CLEAR" /></span></button>
                </div>
              }
              {!_.isEmpty(categoryData.data)
                ? <div className={style.container}>
                    <TransitionGroup className={style.products}>
                      {_.map(categoryData.data, 
                        (product, key) => <CSSTransition key={`${key}_${product.id}`} timeout={{ enter: 300, exit: 200 }} classNames='fade' appear>
                                            <ProductCard 
                                              key={`${key}_${product.id}`} 
                                              product={product}
                                              removeBtn={removeBtn}
                                              handleRemove={handleRemove ? handleRemove(product.id) : null}
                                            />
                                          </CSSTransition>
                      )}
                    </TransitionGroup>
                    {categoryData.last_page > 1 && currentPage !== categoryData.last_page &&
                      <div className={style.moreWrapper}>
                        <button className={style.more} type="button" onClick={this.handleMore}>
                          {!loadMore
                            ? `ПОКАЗАТЬ ЕЩЕ`
                            : <div className={style.moreLoader}><Loader /></div>
                          }
                        </button>  
                      </div>
                    }
                  </div>
                : categoryFetching || subcategoryFetching
                  ? <div className={style.loader}>
                      <Loader />
                    </div>
                  : <div className={style.containerEmpty}>
                      <img src={emptyGif} className={style.emptyGif} alt={intl.formatMessage({id: 'Catalog.EMPTY'})} />
                      <div className={style.emptyHeading}><FormattedMessage id="Catalog.EMPTY_HEADING" /></div>
                      <div className={style.emptySubHeading}><FormattedMessage id="Catalog.EMPTY" /></div>
                      <div className={style.emptyText}><FormattedMessage id="Catalog.EMPTY_TEXT" /></div>
                    </div>
              }
            </div>
          : <div className={style.loader}>
              <Loader />
            </div>
        }
      </div>
    )
  }
}

Catalog.defaultProps = {
  style: require('./Catalog.module.scss'),
  styleModal: require('shared/styles/Modal.module.scss')
};

Catalog.propTypes = {
  intl: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  userToken: PropTypes.string.isRequired,
  loadMore: PropTypes.bool.isRequired,
  subcategories: PropTypes.array,
  subcategoryFetching: PropTypes.bool,
  categoriesFetching: PropTypes.bool,
};

export default Catalog;