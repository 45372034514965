import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoginSms from './LoginSms';
import { setNotification } from 'components/Notification/actions';
import { 
  userSms,
  userSmsReset,
  userToken 
} from 'components/User/actions';

const mapStateToProps = (state) => ({
  user: state.user.user
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    setNotification,
    userSms,
    userSmsReset,
    userToken,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(LoginSms);