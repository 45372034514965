import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/Loader';
import ChangeProfile from './../ChangeProfile';
import AddRecipinetProfile from './../AddRecipinetProfile';

import { PAYMENTS, DELIVERIES } from 'components/Cabinet/constants';

class Profile extends Component {
  componentDidMount() {
    const { userRecipients, userToken, getUserRecipients, user } = this.props;

    if (_.isEmpty(userRecipients)){
      getUserRecipients(userToken);
    }

    this.setState({
      user: user
    });
  }

  render() {
    const { style, user, userRecipients, toggleAddRecipinetProfile, toggleChangeProfile, fetchingUserRecipients, intl, currentLocale } = this.props;

    const phone = user.phones.map(phone => phone.normalized);

    return (
      <div className={style.root}>
        <ChangeProfile />
        <AddRecipinetProfile />
        <div className={style.container}>
          <div className={style.row}>
            <div className={style.boxLeft}>
              <div className={style.heading}><FormattedMessage id="Profile.TITLE" /><button className={style.headingBtn} onClick={toggleChangeProfile}><FormattedMessage id="App.EDIT" /></button></div>
              <table className={style.table}>
                <tbody>
                  <tr>
                    <td><FormattedMessage id="Profile.USER_NAME" /></td>
                    <td>{user.name}</td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="Profile.USER_CITY" /></td>
                    <td>{user.city}</td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="Profile.USER_PHONE" /></td>
                    <td>{_.map(phone, (phoneItem, key) => <div key={key} className={style.phone}>{phoneItem}</div>)}</td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="Profile.USER_EMAIL" /></td>
                    <td>{user.mail && user.mail.email && user.mail.email}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={style.boxRight}>
              <div className={style.heading}><FormattedMessage id="Profile.RECIPIENT" /><button className={style.headingBtn} onClick={toggleAddRecipinetProfile}><FormattedMessage id="App.ADD_SM" /></button></div>
              {!_.isEmpty(userRecipients)
                ? _.map(userRecipients, userRecipient => {
                    return (
                      <div key={userRecipient.id} className={style.tableRow}>
                        <div className={style.tableCol}>
                          <table className={style.table}>
                            <tbody>
                              <tr>
                                <td><FormattedMessage id="Profile.RECIPIENT_NAME" /></td>
                                <td>{userRecipient.name}</td>
                              </tr>
                              <tr>
                                <td><FormattedMessage id="Profile.RECIPIENT_PHONE" /></td>
                                <td>{userRecipient.phone}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className={style.tableCol}>
                          <table className={style.table}>
                            <tbody>
                              <tr>
                                <td><FormattedMessage id="Profile.RECIPIENT_PAYMENT" /></td>
                                <td>{PAYMENTS[currentLocale][userRecipient.payment] || '-'}</td>
                              </tr>
                              <tr>
                                <td><FormattedMessage id="Profile.RECIPIENT_DELEVERY" /></td>
                                <td>{DELIVERIES[currentLocale][userRecipient.delivery] || '-'}</td>
                              </tr>
                              <tr>
                                <td><FormattedMessage id="Profile.USER_CITY" /></td>
                                <td>{userRecipient.city}</td>
                              </tr>
                              {!_.isEmpty(userRecipient.warehouse) && userRecipient.warehouse !== intl.formatMessage({id: 'Profile.RECIPIENT_WAREHOUSE_CHOSE'}) &&
                                <tr>
                                  <td><FormattedMessage id="Profile.RECIPIENT_WAREHOUSE" /></td>
                                  <td>{userRecipient.warehouse}</td>
                                </tr>
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    );
                  })
                : fetchingUserRecipients 
                    ? <div className={style.loader}><Loader /></div>
                    : <div className={style.empty}>
                        <FormattedMessage 
                          id="Profile.RECIPIENT_EMPTY" 
                          values={{ btn: <button className={style.emptyBtn} onClick={toggleAddRecipinetProfile}><FormattedMessage id="Profile.RECIPIENT_EMPTY_ADD" /></button> }} 
                        />
                      </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Profile.defaultProps = {
  style: require('./Profile.module.scss')
};

Profile.propTypes = {
  intl: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userToken: PropTypes.string.isRequired,
  currentLocale: PropTypes.string.isRequired,
  userRecipients: PropTypes.array.isRequired,
  getUserRecipients: PropTypes.func.isRequired,
  addUserRecipinet: PropTypes.func.isRequired,
  toggleAddRecipinetProfile: PropTypes.func.isRequired,
  toggleChangeProfile: PropTypes.func.isRequired,
  fetchingUserRecipients: PropTypes.bool.isRequired,
};

export default Profile;