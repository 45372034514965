import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import CatalogFilter from './CatalogFilter';

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({

  }, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CatalogFilter));
