import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Loader from 'components/Loader';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { getCurrency, getPrice } from 'utils/services';
import Modal from 'components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import classNames from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { FormattedMessage } from 'react-intl';

import { STATUSES } from './../../constants';

class OrdersTable extends Component {
  constructor(props) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  state = {
    activeDropdown: null,
    removeConfirmModal: false,
    removeOrderId: null,
    perPage: 30,
    currentPage: 1,
    loadMore: false
  };

  componentDidMount(){
    const { userToken, fetchOrders, ordersFetching } = this.props;
    const { perPage } = this.state;

    if (!ordersFetching){
      fetchOrders(userToken, '?per_page='+perPage);
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.toggleMenuRef && !this.toggleMenuRef.contains(event.target)) {
      this.setState({
        activeDropdown: null
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.perPage && prevState.perPage !== nextProps.perPage){
      return {
        perPage: nextProps.perPage
      };
    }
    return null;
  }


  handleDropdownBtn = (e, orderId) => {
    e.stopPropagation();
    const { activeDropdown } = this.state;
    
    this.setState({
      activeDropdown: (orderId === activeDropdown) ? null : orderId
    });
  }

  handleOrderRow = orderId => () => {
    this.props.history.push("/#order/"+orderId);
  }

  handleReorder = orderId => () => {
    const { userToken, reorderOrder } = this.props;

    reorderOrder(orderId, userToken);
  }

  handleInvoice = orderId => () => {
    const { userToken, invoiceOrder } = this.props;

    invoiceOrder(orderId, userToken);
  }

  handleInvoiceBarcode = orderId => () => {
    const { userToken, invoiceOrder } = this.props;

    invoiceOrder(orderId, userToken, true);
  }

  handleRemove = orderId => () => {
    const { userToken, deleteOrder } = this.props;

    deleteOrder(orderId, userToken);
    this.handleCloseConfirmRemove();
  }

  handleOpenConfirmRemove = orderId => () => {
    this.setState({
      removeConfirmModal: true,
      removeOrderId: orderId
    });
  }

  handleCloseConfirmRemove = () => {
    this.setState({
      removeConfirmModal: false,
      removeOrderId: null
    });
  }

  handleMore = () => {
    const { ordersData, fetchOrders, userToken } = this.props;
    const { perPage } = this.state;

    let nextPage = _.min([_.sum([ordersData.current_page, 1]), ordersData.last_page]);
    
    fetchOrders(userToken, '?page='+nextPage+'&per_page='+perPage);
  }

  render() {
    const { style, ordersData, currency, groupid, ordersFetching, className, loadMore, notPaginate, limit, currentLocale } = this.props;
    const { activeDropdown, removeConfirmModal, removeOrderId, currentPage, perPage } = this.state;

    const orders = limit && ordersData.data ? ordersData.data.slice(0, limit) : ordersData.data;

    return (
      <div className={classNames(style.root, style[className])}>
        <Modal
          isOpen={removeConfirmModal}
          onRequestClose={this.handleCloseConfirmRemove}
          content={
            <div className={style.modal}>
              <div className={style.modalHeading}>
                <FormattedMessage id="OrdersTable.CONFIRM_DELETE" />
              </div>
              <div className={style.form}>
                <div className={style.btnsBox}>
                  <button className={style.modalSbt} onClick={this.handleRemove(removeOrderId)}>
                    <FormattedMessage id="App.YES" />
                  </button>
                  <button className={style.modalClose} onClick={this.handleCloseConfirmRemove}>
                    <FormattedMessage id="App.NO" />
                  </button>
                </div>
              </div>
            </div>
          }
        />
        {!_.isEmpty(orders) 
          ? <div className={style.tableWrapper}>
              <table className={style.table}>
                <thead>
                  <tr>
                    <th><FormattedMessage id="OrdersTable.DATE" /></th>
                    <th><FormattedMessage id="OrdersTable.NUMBER" /></th>
                    <th><FormattedMessage id="OrdersTable.SUM" values={{ currency: getCurrency(currency, currentLocale) }}/></th>
                    <MediaQuery minWidth={768}>
                      <th><FormattedMessage id="OrdersTable.IN_ORDER" /></th>
                    </MediaQuery>
                    <th><FormattedMessage id="OrdersTable.STATUS" /></th>
                    <th></th>
                  </tr>
                </thead>
                <TransitionGroup component="tbody">
                  {orders.map(order => {
                    const filterStatus = _.filter(STATUSES[currentLocale], (status, statusId) => statusId.split('|').includes(order.status))[0] || {color: '', name: '-'};

                    return (
                      <CSSTransition key={order.id} timeout={{ enter: 300, exit: 200 }} classNames='fade' appear>
                        <tr key={order.id}>
                          <td>
                            <Link to={"/#order/"+order.id} className={style.link}>{ moment(order.added).isValid() ? moment(order.added).format('lll') : <FormattedMessage id="App.NOT_SET" /> }</Link>
                          </td>
                          <td>
                            <Link to={"/#order/"+order.id} className={style.link}>{ order.id }</Link>
                          </td>
                          <td>
                            { getPrice(order.total, 2) } {getCurrency(order.currency, currentLocale)}
                          </td>
                          <MediaQuery minWidth={768}>
                            <td>
                              <div className={style.cats}>{ order.cats }</div>
                            </td>
                          </MediaQuery>
                          <td style={{ color: filterStatus.color }}>
                            { filterStatus.name }
                          </td>
                          <td>
                            <div className={style.toggleBtn}>
                              <button className={style.btnMenu} onClick={e => this.handleDropdownBtn(e, order.id)}><FontAwesomeIcon icon="ellipsis-v" /></button>
                              {(activeDropdown === order.id) && 
                                <div className={style.toggleMenu} ref={el => this.toggleMenuRef = el}>
                                  <Link to={`/#order/${order.id}`} className={style.toggleItem}><FormattedMessage id="OrdersTable.SEE" /></Link> 
                                  <button onClick={this.handleInvoice(order.id)} className={style.toggleItem}><FormattedMessage id="OrdersTable.DOWNLOAD" /></button>
                                  {/* <button onClick={this.handleInvoiceBarcode(order.id)} className={style.toggleItem}>скачать накладную с штрих кодом</button> */}
                                  <button onClick={this.handleReorder(order.id)} className={style.toggleItem}><FormattedMessage id="OrdersTable.ADD_IN_CART" /></button>
                                  {/* <button onClick={this.handleOpenConfirmRemove(order.id)} className={style.toggleItem}>удалить</button> */}
                                </div>
                              }
                            </div>
                          </td>
                        </tr>
                      </CSSTransition>
                    )
                  })}
                </TransitionGroup>
              </table>
              {!notPaginate && ordersData.last_page > 1 && currentPage !== ordersData.last_page &&
                <div className={style.moreWrapper}>
                  <button className={style.more} type="button" onClick={this.handleMore}>
                    {!loadMore
                      ? <FormattedMessage id="App.SHOW_MORE" />
                      : <div className={style.moreLoader}><Loader /></div>
                    }
                  </button>  
                </div>
              }
            </div>
          : ordersFetching 
            ? <div className={style.loader}>
                <Loader />
              </div>
            : <div className={style.emptyWrapper}>
                <div className={style.empty}>
                  <FormattedMessage id="OrdersTable.EMPTY" />
                </div>
              </div>

        }
      </div>
    )
  }
}

OrdersTable.defaultProps = {
  style: require('./OrdersTable.module.scss'),
  notPaginate: false,
  limit: null,
  downloadInvoice: () => {}
};

OrdersTable.propTypes = {
  ordersData: PropTypes.object.isRequired,
  currentLocale: PropTypes.string.isRequired,
  groupid: PropTypes.number.isRequired,
  fetchOrders: PropTypes.func.isRequired,
  reorderOrder: PropTypes.func.isRequired,
  deleteOrder: PropTypes.func.isRequired,
  downloadInvoice: PropTypes.func.isRequired,
  currency: PropTypes.string,
  decimals: PropTypes.number,
  userToken: PropTypes.string.isRequired,
  loadMore: PropTypes.bool.isRequired,
  className: PropTypes.string,
  notPaginate: PropTypes.bool
};

export default OrdersTable;