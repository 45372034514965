import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { getPrice, getCurrency, calcProductQty } from 'utils/services';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { FormattedMessage } from 'react-intl';

import LazyImage from 'components/LazyImage';

class ListProducts extends Component {
  state = {
    orderOpened: false,
    quantity: {}
  };

  componentDidMount() {
    this.setState({
      quantity: this.props.products.reduce((obj, curr) => {
        return {...obj, ...{[curr.id]: curr.quantity}};
      }, {})
    });
  };

  handleChange = (item, e, value = undefined) => {
    if (e.target.validity.valid){
      this.setState({
        quantity: {
          ...this.state.quantity,
          [item.id]: value || e.target.value
        }
      });
    }
  };

  handleBlur = (item, e) => {
    const { userToken, changeCart } = this.props;

    if (e.target.validity.valid){
      const value = calcProductQty('change', e.target.value, item.good);
      changeCart(item.id, value, userToken);
      this.handleChange(item, e, value);
    }
  };

  handleControl = (type, item, e) => {
    const { userToken, changeCart } = this.props;

    const value = calcProductQty(type, item.quantity, item.good);

    changeCart(item.id, value, userToken);

    this.handleChange(item, e, value);
  }

  handleRemove = itemId => () => {
    const { userToken, deleteCart } = this.props;

    deleteCart(itemId, userToken);
  }

  handleToogle = () => {
    this.setState({
      orderOpened: !this.state.orderOpened
    })
  }

  render() {
    const { style, products, currency, decimals, disabled, showBottom, total, currentLocale } = this.props;
    const { orderOpened, quantity } = this.state;

    return (
      <div className={style.root}>
        <div className={classNames(style.heading, {[style.active]: orderOpened})} onClick={this.handleToogle}>
          <FormattedMessage id="ListProducts.HEADING" />
          <button className={style.toogleMobile}>
            {orderOpened 
              ? <span className={style.toogleMobileBtnClose}><FontAwesomeIcon icon="caret-down" /></span>
              : <span className={style.toogleMobileBtnOpen}><FontAwesomeIcon icon="caret-right" /></span>
            }
          </button>
        </div>
        <div className={classNames(style.items, {[style.active]: orderOpened})}>
          <TransitionGroup>
            {products.map(item => 
              <CSSTransition key={item.id} timeout={{ enter: 300, exit: 200 }} classNames='fade' appear>
                <div key={item.id} className={classNames(style.item, {[style.available]: (!item.good.visible_im || !item.good.instock)})}>
                  <Link to={'/product/'+item.good.id}>
                    <LazyImage
                      image={{
                        className: style.thumb,
                        wrapperClassName: style.thumbImg,
                        src: item.good.image,
                        alt: item.good.name,
                        lowResSrc: item.good.imagesmall
                      }}
                    />
                  </Link>
                  <div className={style.info}>
                    <Link className={style.name} to={'/product/'+item.good.id}>{ item.good.name } ({ item.good.minnum })</Link>
                    <div className={style.controls}>
                      <div className={style.price}>{ getPrice(item.price, decimals) } {getCurrency(currency, currentLocale)}</div>
                      {!disabled && item.good.instock && item.good.visible_im
                        ? <div className={style.qty}>
                            <button type="button" className={classNames(style.qtyBtn, style.qtyBtnMinus)} onClick={this.handleControl.bind(this, 'minus', item)}></button>
                            <input type="text" className={style.qtyInput} pattern="[0-9]*" value={quantity[item.id] || item.quantity} onChange={this.handleChange.bind(this, item)} onBlur={this.handleBlur.bind(this, item)} />
                            <button type="button" className={classNames(style.qtyBtn, style.qtyBtnPlus)} onClick={this.handleControl.bind(this, 'plus', item)}></button>
                          </div>
                        : <div className={style.qty}>
                            <div className={classNames(style.qtyBtn, style.qtyBtnMinus)}></div>
                            <input type="text" disabled="disabled" className={style.qtyInput} pattern="[0-9]*" value={item.quantity} onChange={this.handleChange.bind(this, item)} />
                            <div className={classNames(style.qtyBtn, style.qtyBtnPlus)}></div>
                          </div>
                      }
                    </div>
                  </div>
                  <div className={style.totalBox}>
                    <div className={style.total}>
                      {item.good.visible_im
                        ? !item.good.instock
                            ? <FormattedMessage id="Good.NOT_AVAILABLE" />
                            : `${getPrice(item.good.total || item.total, 2)} ${getCurrency(currency, currentLocale)}`
                        : <FormattedMessage id="Good.OUT" />
                      }
                    </div>
                    {!disabled && 
                      <button className={style.remove} onClick={this.handleRemove(item.id)}>
                        <FontAwesomeIcon icon="times" />
                        <span><FormattedMessage id="App.REMOVE" /></span>
                      </button>
                    }
                  </div>
                </div>
              </CSSTransition>
            )}
          </TransitionGroup>
          {showBottom && total && <div className={style.bottom}><FormattedMessage id="ListProducts.TOTAL" />: {getPrice(total, 2)} {getCurrency(currency, currentLocale)}</div>}
        </div>
      </div>
    )
  }
}

ListProducts.defaultProps = {
  style: require('./ListProducts.module.scss')
}

ListProducts.propTypes = {
  products: PropTypes.array.isRequired,
  currency: PropTypes.string.isRequired,
  currentLocale: PropTypes.string.isRequired,
  decimals: PropTypes.number.isRequired,
  changeCart: PropTypes.func.isRequired,
  deleteCart: PropTypes.func.isRequired,
  total: PropTypes.number,
  showBottom: PropTypes.bool,
};

export default ListProducts;