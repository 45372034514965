import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { FormattedMessage } from 'react-intl';

import CatalogMenu from 'components/CatalogMenu';
import CartModal from 'components/Cart/components/CartModal';
import MobileMenu from 'components/MobileMenu';
import Categories from 'components/Categories';
// import LangSwitcher from 'components/Lang/components/LangSwitcher';

import Logo from 'shared/img/logo.png';
import LogoSm from 'shared/img/logo_sm.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Header extends Component {
  state = {
    showMobileMenu: false,
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (e) => {
    this.setState({
      scrolled: window.scrollY > 100
    });
  }

  handleMenu = () => {
    this.props.categoryOpen && this.props.toggleCategories();
    this.setState({
      showMobileMenu: !this.state.showMobileMenu
    });
  }

  render() {
    const { showMobileMenu, scrolled } = this.state;
    const { style, user, template } = this.props;

    return(
      <div className={`${style.root} ${!scrolled && style[template]}`}>
        <header className={style.header}>
          <div className={style.container}>
            <div className={style.items}>
              <div className={style.itemLogo}>
                <Link className={style.navItemLogo} to='/'>
                  <MediaQuery minWidth={1024}>
                    <img src={Logo} alt='Sparta лого' className={style.logoImg} />
                  </MediaQuery>
                  <MediaQuery maxWidth={1023}>
                    <img src={showMobileMenu ? Logo : LogoSm} alt='Sparta лого' className={style.logoImgMobile} />
                  </MediaQuery>
                </Link>
              </div>
              {!showMobileMenu &&
                <div className={style.item}>
                  <CatalogMenu template={template} />
                </div>
              }
              {/* <LangSwitcher /> */}
              <MediaQuery minWidth={1024}>
                <div className={style.itemNav}>
                  <nav className={style.nav}>
                    <NavLink className={style.navItem} activeClassName={style.active} to='/partners'>
                      <FormattedMessage id="App.PARTNERS" />
                    </NavLink>
                    <NavLink className={style.navItem} activeClassName={style.active} to='/colorit'>
                      TM COLOR-IT / CELLO / ELLOT
                    </NavLink>
                  </nav>
                </div>
                <div className={style.itemCabinet}>
                  <NavLink className={style.navCabinet} activeClassName={style.active} to={user.token ? '/' : '/login'}>
                    <FontAwesomeIcon icon='user' />
                    <span><FormattedMessage id="App.CABINET" /></span>
                  </NavLink>
                </div>
              </MediaQuery>
              {!showMobileMenu &&
                <div className={style.item}>
                  <CartModal />
                </div>
              }
              <MediaQuery maxWidth={1023}>
                <div className={style.itemMenu}>
                  <button className={style.menuBtn} onClick={this.handleMenu}><FontAwesomeIcon icon='bars' /></button>
                </div>
              </MediaQuery>
            </div>
          </div>
        </header>
        {showMobileMenu && <MobileMenu />}
        <Categories />
      </div>
    )
  }
}

Header.defaultProps = {
  style: require('./Header.module.scss'),
  template: 'default',
}

Header.propTypes = {
  user: PropTypes.object.isRequired,
  categoryOpen: PropTypes.bool.isRequired,
  toggleCategories: PropTypes.func.isRequired,
  template: PropTypes.string,
}

export default Header;