import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import partnersBg from 'shared/img/partnersbg.png';
import exclusiveImg from 'shared/img/exclusive.png';
import originalImg from 'shared/img/original.png';
import sertImg1 from 'shared/img/sert1.png';
import sertImg2 from 'shared/img/sert2.png';
import sertImg3 from 'shared/img/sert3.png';
import sertImg4 from 'shared/img/sert4.png';
import sertImg5 from 'shared/img/sert5.png';
import sertImg6 from 'shared/img/sert6.png';
import sertImg7 from 'shared/img/sert7.png';
import sertImg8 from 'shared/img/sert8.png';
import sertImg9 from 'shared/img/sert9.png';
import sertImg10 from 'shared/img/sert10.png';
import sertImg11 from 'shared/img/sert11.png';
import sertImg12 from 'shared/img/sert12.png';
import sertImg13 from 'shared/img/sert13.png';
import sertImg14 from 'shared/img/sert14.png';

class Partners extends Component {
  render() {
    const { style } = this.props;

    return(
      <div className={style.root}>
        <div className={style.top}>
          <img className={style.imageBg} src={partnersBg} alt='' />
          <h1 className={style.heading}><FormattedMessage id="Partners.HEADING" /></h1>
        </div>
        <div className={style.blueBox}>
          <div className={style.container}>
            <div className={style.row}>
              <div className={style.exclusive}>
                <h2 className={style.title}><FormattedMessage id="Partners.TITLE" /></h2>
                <img className={style.imageExcl} src={exclusiveImg} alt='' />
              </div>
            </div>
          </div>
        </div>
        <div className={style.container}>
          <div className={`${style.row} ${style.rowOriginal}`}>
            <div className={style.col}>
              <img className={style.imageOriginal} src={originalImg} alt="" />
            </div>
            <div className={style.col}>
              <h3 className={style.titleH3}><FormattedMessage id="Partners.OFICIAL" /></h3>
            </div>
          </div>
        </div>
        <div className={style.tableWrapper}>
          <div className={style.container}>
            <div className={style.row}>
              <div className={style.col}>
                <img className={style.imageSert} src={sertImg1} alt="" />
              </div>
              <div className={style.col}>
                <img className={style.imageSert} src={sertImg2} alt="" />
              </div>
            </div>
          </div>
          <div className={style.tableHr}></div>
          <div className={style.container}>
            <div className={style.row}>
              <div className={style.col}>
                <img className={style.imageSert} src={sertImg3} alt="" />
              </div>
              <div className={style.col}>
                <img className={style.imageSert} src={sertImg4} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className={style.container}>
          <div className={style.row}>
            <div className={style.col}>
              <img className={style.imageSertSm} src={sertImg5} alt="" />
            </div>
            <div className={style.col}>
              <img className={style.imageSertSm} src={sertImg6} alt="" />
            </div>
            <div className={style.col}>
              <img className={style.imageSertSm} src={sertImg7} alt="" />
            </div>
            <div className={style.col}>
              <img className={style.imageSertSm} src={sertImg8} alt="" />
            </div>
          </div>
        </div>
        <div className={`${style.tableWrapper} ${style.tableWrapperHalf}`}>
          <div className={style.container}>
            <div className={style.row}>
              <div className={style.col}>
                <img className={style.imageSert} src={sertImg9} alt="" />
              </div>
              <div className={style.col}>
                <div className={style.imgColumn}>
                  <img className={style.imageSert} src={sertImg10} alt="" />
                  <img className={style.imageSert} src={sertImg11} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${style.tableWrapper} ${style.tableWrapperHalf} ${style.tableWrapperLast}`}>
          <div className={style.container}>
            <div className={style.row}>
              <div className={style.col}>
                <img className={style.imageSert} src={sertImg12} alt="" />
              </div>
              <div className={style.col}>
                <div className={style.imgColumn}>
                  <img className={style.imageSert} src={sertImg13} alt="" />
                  <img className={style.imageSert} src={sertImg14} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Partners.defaultProps = {
  style: require('./Partners.module.scss')
}

Partners.propTypes = {
  userToken: PropTypes.string.isRequired,
  blanks: PropTypes.object.isRequired,
  getPartnersBlanks: PropTypes.func.isRequired
};

export default Partners;