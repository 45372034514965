import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Balance from './Balance';
import { fetchBalanceGrid } from 'components/User/actions';
import { getUserBalance } from 'components/User/actions';

const mapStateToProps = state => ({
  currentLocale: state.lang.currentLocale,
  balances: state.user.balanceGrid.items,
  fetching: state.user.balanceGrid.fetching,
  userToken: state.user.token,
  currency: state.user.user.currency,
  decimals: state.user.user.decimals,
  userBalance: state.user.balance.items,
  groupid: state.user.user.groupid,
  access: state.user.balanceGrid.access,
  balanceLogin: state.user.balanceLogin,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    fetchBalanceGrid,
    getUserBalance
  }, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Balance));
