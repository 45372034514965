import axios from 'axios';

export const apiSendMessage = (data, token) => {
  return axios({
    method: 'POST',
    url: process.env.REACT_APP_API_URL+'auth/contact',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    },
    data: {
      message: data.comment,
      type: data.type
    }
  });
}