import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import Profile from './Profile';
import { 
  getUserRecipients,
  addUserRecipinet,
  toggleAddRecipinetProfile,
  toggleChangeProfile
} from 'components/User/actions';

const mapStateToProps = (state) => ({
  user: state.user.user,
  userToken: state.user.token,
  currentLocale: state.lang.currentLocale,
  userRecipients: state.user.recipients.items,
  fetchingUserRecipients: state.user.recipients.fetching,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    getUserRecipients,
    addUserRecipinet,
    toggleAddRecipinetProfile,
    toggleChangeProfile
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Profile));
