import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CatalogMenu from './CatalogMenu';

import { toggleCategories } from 'components/Categories/actions';

const mapStateToProps = (state) => ({
  isCategoriesOpen: state.categories.open
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    toggleCategories
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CatalogMenu);
