exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/layout.scss"), "");
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/typography.scss"), "");

// Module
exports.push([module.id, ".CartOrder_container__SNYf5 { }\n\n.CartOrder_empty__2Lwmu {\n  display: flex;\n  align-items: center;\n  align-content: stretch;\n  justify-content: center; }\n\n.CartOrder_emptyText__3eojg {\n  flex: 0 1 auto;\n  text-align: center;\n  font-size: 20px;\n  margin: 0; }\n\n.CartOrder_wrapper__1WYKC {\n  display: flex;\n  align-content: stretch;\n  align-items: stretch;\n  justify-content: space-between;\n  height: 100%;\n  flex-direction: column; }\n  @media (min-width: 1024px) {\n    .CartOrder_wrapper__1WYKC {\n      flex-direction: row; } }\n\n.CartOrder_col__-UBEf {\n  flex: 1 1 auto;\n  margin: 0 -15px; }\n  .CartOrder_col__-UBEf + .CartOrder_col__-UBEf {\n    margin-top: 30px; }\n  @media (min-width: 768px) {\n    .CartOrder_col__-UBEf {\n      margin: 30px 15px 0; } }\n  @media (min-width: 1024px) {\n    .CartOrder_col__-UBEf {\n      margin: 30px 15px; }\n      .CartOrder_col__-UBEf + .CartOrder_col__-UBEf {\n        max-width: 410px; } }\n\n.CartOrder_info__3Kk6a {\n  display: none; }\n  @media (min-width: 768px) {\n    .CartOrder_info__3Kk6a {\n      display: flex;\n      align-content: flex-start;\n      align-items: flex-start;\n      justify-content: space-between;\n      margin-top: 15px; }\n      .CartOrder_info__3Kk6a svg {\n        color: #868686;\n        margin-right: 18px;\n        font-size: 18px;\n        flex: 0 1 auto;\n        min-width: 21px; } }\n  @media (min-width: 1024px) {\n    .CartOrder_info__3Kk6a {\n      margin-top: 30px; } }\n\n.CartOrder_infoTitle__2_gLp {\n  font-size: 14px;\n  margin-bottom: 5px; }\n\n.CartOrder_infoText__5tXHw {\n  flex: 1 1 auto; }\n  .CartOrder_infoText__5tXHw p {\n    margin: 0 0 10px;\n    font-size: 14px; }\n", ""]);

// Exports
exports.locals = {
	"container": "CartOrder_container__SNYf5 " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/layout.scss").locals["container"] + " " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/layout.scss").locals["fullHeight"] + "",
	"empty": "CartOrder_empty__2Lwmu " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/layout.scss").locals["loader"] + "",
	"emptyText": "CartOrder_emptyText__3eojg",
	"wrapper": "CartOrder_wrapper__1WYKC " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/layout.scss").locals["fullHeight"] + "",
	"col": "CartOrder_col__-UBEf",
	"info": "CartOrder_info__3Kk6a",
	"infoTitle": "CartOrder_infoTitle__2_gLp " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/typography.scss").locals["title"] + "",
	"infoText": "CartOrder_infoText__5tXHw"
};