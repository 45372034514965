exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/layout.scss"), "");
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/typography.scss"), "");

// Module
exports.push([module.id, ".Cart_container__GSXn5 { }\n\n.Cart_empty__2Luii {\n  display: flex;\n  align-items: center;\n  align-content: stretch;\n  justify-content: center; }\n\n.Cart_emptyText__2x9c8 {\n  flex: 0 1 auto;\n  text-align: center;\n  font-size: 20px;\n  margin: 0; }\n\n.Cart_wrapper__1s9RS {\n  display: flex;\n  align-content: stretch;\n  align-items: stretch;\n  justify-content: space-between;\n  height: 100%; }\n\n.Cart_col__2uv3Y {\n  flex: 1 1 auto;\n  margin: 30px 10px; }\n  .Cart_col__2uv3Y + .Cart_col__2uv3Y {\n    max-width: 410px; }\n\n.Cart_info__3oJFv {\n  display: flex;\n  align-content: flex-start;\n  align-items: flex-start;\n  justify-content: space-between;\n  margin-top: 30px; }\n  .Cart_info__3oJFv svg {\n    color: #868686;\n    margin-right: 18px;\n    font-size: 18px;\n    flex: 0 1 auto;\n    min-width: 21px; }\n\n.Cart_infoTitle__Wt2Er {\n  font-size: 14px;\n  margin-bottom: 5px; }\n\n.Cart_infoText__2CR3g {\n  flex: 1 1 auto; }\n  .Cart_infoText__2CR3g p {\n    margin: 0 0 10px;\n    font-size: 14px; }\n", ""]);

// Exports
exports.locals = {
	"container": "Cart_container__GSXn5 " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/layout.scss").locals["container"] + " " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/layout.scss").locals["fullHeight"] + "",
	"empty": "Cart_empty__2Luii " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/layout.scss").locals["loader"] + "",
	"emptyText": "Cart_emptyText__2x9c8",
	"wrapper": "Cart_wrapper__1s9RS " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/layout.scss").locals["fullHeight"] + "",
	"col": "Cart_col__2uv3Y",
	"info": "Cart_info__3oJFv",
	"infoTitle": "Cart_infoTitle__Wt2Er " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/typography.scss").locals["title"] + "",
	"infoText": "Cart_infoText__2CR3g"
};