import { 
  takeLatest, 
  call, 
  put 
} from 'redux-saga/effects';

import _ from 'lodash';

import { 
  SET_NOTIFICATION, 
  SET_VALIDATION 
} from 'components/Notification/actions/types';

import {
  USER,
  USER_SMS,
  USER_TOKEN,
  USER_EDIT,
  USER_RESET,
  USER_RECIPIENTS,
  USER_STATISTIC,
  USER_BALANCE,
  ADD_USER_RECIPIENT,
  TOGGLE_ADD_RECIPIENT_PROFILE,
  GET_DELIVERY_WAREHOUSE,
  GET_DELIVERY_CITY,
  GET_DELIVERY_AREA,
  GET_PARTNERS_BLANKS,
  TOGGLE_CHANGE_PROFILE,
  GET_SETTINGS,
  USER_NOT_REGISTER,
  UNSET_USER_NOT_REGISTER,
  USER_REGISTER,
  FETCH_BALANCE_GRID,
  USER_BALANCE_SMS,
  USER_BALANCE_LOGIN,
  GET_BANNERS
} from './types';

import { 
  apiUserSms,
  apiUserToken,
  apiEditUser,
  saveToLocalStorage,
  removeFromLocalStorage,
  apiGetUser,
  apiGetUserRecipients,
  apiGetUserStatistic,
  apiGetUserBalance,
  apiAddUserRecipinet,
  apiGetDeliveryArea,
  apiGetDeliveryCity,
  apiGetDeliveryWarehouse,
  apiGetPartnersBlanks,
  apiGetSettings,
  apiUserRegister,
  apiFetchBalanceGrid,
  apiUserBalanceSms,
  apiUserBalanceLogin,
  apiGetBanners
} from './../services';

export function* watchUserSms() {
  yield takeLatest(USER_SMS.REQUEST, userSmsSaga);
}

function* userSmsSaga(data) {
  try {
    const response = data.loginModer 
      ? { data: { phone: data.phone } }
      : yield apiUserSms(data.phone);

    yield put({ type: USER_SMS.SUCCESS, payload: response.data });
    yield put({ type: SET_NOTIFICATION, payload: {msgId: 'USER_SMS.SUCCESS', type: 'success'} });
  } catch (error) {
    if (error.response.status === 404) {
      yield put({ 
        type: USER_NOT_REGISTER, 
        payload: {
          phone: data.phone
        } 
      });
    }else{
      let errMsg = error.response.data.message || 'error';

      if (_.isObject(errMsg) && errMsg.validation){
        errMsg = _.values(errMsg.validation);
      }

      yield put({ type: USER_SMS.FAILURE });
      yield put({ type: SET_NOTIFICATION, payload: {msgId: errMsg} });
    }
  }
}

export const userSms = (phone, loginModer = false) => ({
  type: USER_SMS.REQUEST,
  phone: phone,
  loginModer: loginModer
});

export const userSmsReset = () => ({
  type: USER_SMS.FAILURE
});

export function* watchUserToken() {
  yield takeLatest(USER_TOKEN.REQUEST, userTokenSaga);
}

function* userTokenSaga(data) {
  try {
    const response = yield apiUserToken(data.phone, data.code, data.loginModer);

    yield put({ type: USER_TOKEN.SUCCESS, payload: response.data });
    yield put({ type: SET_NOTIFICATION, payload: {msgId: 'USER_TOKEN.SUCCESS', type: 'success'} });
    yield call(getUser, response.data.token);
  } catch (error) {
    let errMsg = error.response.data.message || 'error';

    if (_.isObject(errMsg) && errMsg.validation){
      errMsg = _.values(errMsg.validation);
    }

    yield put({ type: SET_NOTIFICATION, payload: {msgId: errMsg} });
  }
}

export const userToken = (phone, code, loginModer = false) => ({
  type: USER_TOKEN.REQUEST,
  phone: phone,
  code: code,
  loginModer: loginModer
});

export function* watchEditUser() {
  yield takeLatest(USER_EDIT.REQUEST, editUserSaga);
}

function* editUserSaga(data) {
  try {
    const response = yield apiEditUser(data.userId, data.data, data.token);

    yield put({ type: TOGGLE_CHANGE_PROFILE,  payload: false });
    yield put({ type: USER_EDIT.SUCCESS, payload: response.data });
    yield put({ type: SET_NOTIFICATION, payload: {msgId: 'USER_EDIT.SUCCESS', type: 'success'} });
  } catch (error) {
    const errMsg = error.response.data.message || 'error';

    if (errMsg.validation){
      yield put({ type: SET_VALIDATION, payload: errMsg.validation });
    }else{
      yield put({ type: SET_NOTIFICATION, payload: {msgId: errMsg} });
    }
  }
}

export const editUser = (userId, data, token) => ({
  type: USER_EDIT.REQUEST,
  userId: userId,
  data: data,
  token: token
});

export function* watchGetUser() {
  yield takeLatest(USER.REQUEST, getUserSaga);
}

function* getUserSaga(data) {
  try {
    const response = yield apiGetUser(data.token);

    yield put({ type: USER.SUCCESS, payload: {user: response.data, token: data.token} });
    yield call(saveToLocalStorage, 'userToken', data.token);
    yield call(saveToLocalStorage, 'user', response.data);
  } catch (error) {
    const errMsg = error.response.data.message || 'error';

    yield put({ type: USER.FAILURE, payload: {msgId: errMsg} });
    yield call(resetUser);
  }
}

export const getUser = token => ({
  type: USER.REQUEST,
  token: token
});

export const resetUser = () => {
  removeFromLocalStorage('userToken');
  removeFromLocalStorage('user');

  return {
    type: USER_RESET
  }
}

export function* watchGetUserRecipients() {
  yield takeLatest(USER_RECIPIENTS.REQUEST, getUserRecipientsSaga);
}

function* getUserRecipientsSaga(data) {
  try {
    const response = yield apiGetUserRecipients(data.token);

    yield put({ type: USER_RECIPIENTS.SUCCESS, payload: response.data });
  } catch (error) {
    const errMsg = error.response.data.message || 'error';

    yield put({ type: USER_RECIPIENTS.FAILURE, payload: {msgId: errMsg} });
  }
}

export const getUserRecipients = token => ({
  type: USER_RECIPIENTS.REQUEST,
  token: token
});

export function* watchGetUserStatistic() {
  yield takeLatest(USER_STATISTIC.REQUEST, getUserStatisticSaga);
}

function* getUserStatisticSaga(data) {
  try {
    const response = yield apiGetUserStatistic(data.token);

    yield put({ type: USER_STATISTIC.SUCCESS, payload: response.data });
  } catch (error) {
    const errMsg = error.response.data.message || 'error';

    yield put({ type: USER_STATISTIC.FAILURE, payload: {msgId: errMsg} });
  }
}

export const getUserStatistic = token => ({
  type: USER_STATISTIC.REQUEST,
  token: token
});

export function* watchAddUserRecipinet() {
  yield takeLatest(ADD_USER_RECIPIENT.REQUEST, addUserRecipinetSaga);
}

function* addUserRecipinetSaga(data) {
  try {
    const response = yield apiAddUserRecipinet(data.token, data.data);

    yield put({ type: TOGGLE_ADD_RECIPIENT_PROFILE,  payload: false });
    yield put({ type: ADD_USER_RECIPIENT.SUCCESS, payload: response.data });
    yield put({ type: SET_NOTIFICATION, payload: {msgId: 'ADD_USER_RECIPIENT.SUCCESS', type: 'success'} });
  } catch (error) {
    const errMsg = error.response.data.message || 'error';

    if (errMsg.validation){
      yield put({ type: ADD_USER_RECIPIENT.FAILURE, payload: errMsg.validation });
    }else{
      yield put({ type: ADD_USER_RECIPIENT.FAILURE, payload: {msgId: errMsg} });
    }
  }
}

export const addUserRecipinet = (data, token) => ({
  type: ADD_USER_RECIPIENT.REQUEST,
  data: data,
  token: token
});

export const toggleAddRecipinetProfile = data => ({
  type: TOGGLE_ADD_RECIPIENT_PROFILE,
  data: data
});

export const toggleChangeProfile = data => ({
  type: TOGGLE_CHANGE_PROFILE,
  data: data
});

export function* watchGetDeliveryArea() {
  yield takeLatest(GET_DELIVERY_AREA.REQUEST, getDeliveryAreaSaga);
}

function* getDeliveryAreaSaga(data) {
  try {
    const response = yield apiGetDeliveryArea(data.token, data.data);

    yield put({ type: GET_DELIVERY_AREA.SUCCESS, payload: {[data.data.delivery]: response.data} });
  } catch (error) {
    const errMsg = error.response.data.message || 'error';

    yield put({ type: GET_DELIVERY_AREA.FAILURE, payload: {msgId: errMsg} });
  }
}

export const getDeliveryArea = (delivery, token) => ({
  type: GET_DELIVERY_AREA.REQUEST,
  data: {
    delivery: delivery
  },
  token: token
});

export function* watchGetDeliveryCity() {
  yield takeLatest(GET_DELIVERY_CITY.REQUEST, getDeliveryCitySaga);
}

function* getDeliveryCitySaga(data) {
  try {
    const response = yield apiGetDeliveryCity(data.token, data.data);

    yield put({ type: GET_DELIVERY_CITY.SUCCESS, payload: {[data.data.delivery]: response.data} });
  } catch (error) {
    const errMsg = error.response.data.message || 'error';

    yield put({ type: GET_DELIVERY_CITY.FAILURE, payload: {msgId: errMsg} });
  }
}

export const getDeliveryCity = (data, token) => ({
  type: GET_DELIVERY_CITY.REQUEST,
  data: data,
  token: token
});

export function* watchGetDeliveryWarehouse() {
  yield takeLatest(GET_DELIVERY_WAREHOUSE.REQUEST, getDeliveryWarehouseSaga);
}

function* getDeliveryWarehouseSaga(data) {
  try {
    const response = yield apiGetDeliveryWarehouse(data.token, data.data);

    yield put({ type: GET_DELIVERY_WAREHOUSE.SUCCESS, payload: {
        delivery: data.data.delivery,
        city: data.data.city,
        warehouse: response.data
      }
    });
  } catch (error) {
    const errMsg = error.response.data.message || 'error';

    yield put({ type: GET_DELIVERY_WAREHOUSE.FAILURE, payload: {msgId: errMsg} });
  }
}

export const getDeliveryWarehouse = (data, token) => ({
  type: GET_DELIVERY_WAREHOUSE.REQUEST,
  data: {
    delivery: data.delivery,
    city: data.city
  },
  token: token
});

export function* watchGetPartnersBlanks() {
  yield takeLatest(GET_PARTNERS_BLANKS.REQUEST, getPartnersBlanksSaga);
}

function* getPartnersBlanksSaga(data) {
  try {
    const response = yield apiGetPartnersBlanks(data.token);

    const blanks = _.reduce(response.data, function(result, value) {
      result[_.keys(value)[0]] = _.values(value)[0];
      return result;
    }, {});

    yield put({ type: GET_PARTNERS_BLANKS.SUCCESS, payload: blanks});
  } catch (error) {
    const errMsg = error.response.data.message || 'error';

    yield put({ type: GET_PARTNERS_BLANKS.FAILURE, payload: {msgId: errMsg} });
  }
}

export const getPartnersBlanks = token => ({
  type: GET_PARTNERS_BLANKS.REQUEST,
  token: token
});

export function* watchGetUserBalance() {
  yield takeLatest(USER_BALANCE.REQUEST, getUserBalanceSaga);
}

function* getUserBalanceSaga(data) {
  try {
    const response = yield apiGetUserBalance(data.token);

    yield put({ type: USER_BALANCE.SUCCESS, payload: response.data });
  } catch (error) {
    const errMsg = error.response.data.message || 'error';

    yield put({ type: USER_BALANCE.FAILURE, payload: {msgId: errMsg} });
  }
}

export const getUserBalance = token => ({
  type: USER_BALANCE.REQUEST,
  token: token
});

export function* watchGetSettings() {
  yield takeLatest(GET_SETTINGS.REQUEST, getSettingsSaga);
};

function* getSettingsSaga(data) {
  try {
    const response = yield apiGetSettings(data.token);

    yield put({ type: GET_SETTINGS.SUCCESS, payload: response.data });
  } catch (error) {
    const errMsg = error.response.data.message || 'error';

    yield put({ type: GET_SETTINGS.FAILURE, payload: {msgId: errMsg} });
  }
};

export const getSettings = token => ({
  type: GET_SETTINGS.REQUEST,
  token: token
});

export function* watchUserRegister() {
  yield takeLatest(USER_REGISTER.REQUEST, userRegisterSaga);
}

function* userRegisterSaga(data) {
  try {
    const response = yield apiUserRegister(data.data);

    yield put({ type: USER_SMS.SUCCESS, payload: response.data });
    yield put({ type: SET_NOTIFICATION, payload: {msgId: 'USER_SMS.SUCCESS', type: 'success'} });
  } catch (error) {
    let errMsg = error.response.data.message || 'error';

    if (_.isObject(errMsg) && errMsg.validation){
      errMsg = _.values(errMsg.validation);
    }

    // yield put({ type: USER_REGISTER.FAILURE });
    yield put({ type: SET_NOTIFICATION, payload: {msgId: errMsg} });
  }
}

export const userRegister = data => ({
  type: USER_REGISTER.REQUEST,
  data: data
});

export function* watchFetchBalanceGrid() {
  yield takeLatest(FETCH_BALANCE_GRID.REQUEST, fetchBalanceGridSaga);
}

function* fetchBalanceGridSaga(data) {
  try {
    const response = yield call(apiFetchBalanceGrid, data.token);
    yield put({ type: FETCH_BALANCE_GRID.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: FETCH_BALANCE_GRID.FAILURE, payload: error.message });
  }
}

export const fetchBalanceGrid = token => ({
  type: FETCH_BALANCE_GRID.REQUEST,
  token: token
});

export const unsetUserNotRegister = () => ({
  type: UNSET_USER_NOT_REGISTER
});

export function* watchUserBalanceSms() {
  yield takeLatest(USER_BALANCE_SMS.REQUEST, userBalanceSmsSaga);
}

function* userBalanceSmsSaga(data) {
  try {
    const response = yield apiUserBalanceSms(data.token);

    yield put({ type: USER_BALANCE_SMS.SUCCESS, payload: response.data });
    yield put({ type: SET_NOTIFICATION, payload: {msgId: 'USER_SMS.SUCCESS', type: 'success'} });
  } catch (error) {
    let errMsg = error.response.data.message || 'error';

    if (_.isObject(errMsg) && errMsg.validation){
      errMsg = _.values(errMsg.validation);
    }

    yield put({ type: USER_BALANCE_SMS.FAILURE });
    yield put({ type: SET_NOTIFICATION, payload: {msgId: errMsg} });
  }
}

export const userBalanceSms = token => ({
  type: USER_BALANCE_SMS.REQUEST,
  token: token
});

export function* watchUserBalanceLogin() {
  yield takeLatest(USER_BALANCE_LOGIN.REQUEST, userBalanceLoginSaga);
}

function* userBalanceLoginSaga(data) {
  try {
    const response = yield apiUserBalanceLogin(data.token, data.code);

    yield put({ type: USER_BALANCE_LOGIN.SUCCESS, payload: response.data });
    yield put({ type: SET_NOTIFICATION, payload: {msgId: 'USER_SMS.SUCCESS', type: 'success'} });
  } catch (error) {
    let errMsg = error.response.data.message || 'error';

    if (_.isObject(errMsg) && errMsg.validation){
      errMsg = _.values(errMsg.validation);
    }

    yield put({ type: USER_BALANCE_LOGIN.FAILURE });
    yield put({ type: SET_NOTIFICATION, payload: {msgId: errMsg} });
  }
}

export const userBalanceLogin = (token, code) => ({
  type: USER_BALANCE_LOGIN.REQUEST,
  token: token,
  code: code
});

export function* watchGetBanners() {
  yield takeLatest(GET_BANNERS.REQUEST, getBannersSaga);
}

function* getBannersSaga(data) {
  try {
    const response = yield apiGetBanners(data.token);

    yield put({ type: GET_BANNERS.SUCCESS, payload: {data: response.data} });
  } catch (error) {
    const errMsg = error.response.data.message || 'error';

    yield put({ type: GET_BANNERS.FAILURE, payload: {msgId: errMsg} });
  }
}

export const getBanners = token => ({
  type: GET_BANNERS.REQUEST,
  token: token
});