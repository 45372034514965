import axios from 'axios';

export const apiAddCart = (productId, qty, token) => {
  return axios({
    method: 'POST',
    url: process.env.REACT_APP_API_URL+'order/cart',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    },
    data: {
      'good': productId,
      'quantity': qty
    }
  });
}

export const apiChangeCart = (cartId, qty, token) => {
  return axios({
    method: 'PUT',
    url: process.env.REACT_APP_API_URL+'order/cart/'+cartId,
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    },
    data: {
      'quantity': qty
    }
  });
}

export const apiDeleteCart = (cartId, token) => {
  return axios({
    method: 'DELETE',
    url: process.env.REACT_APP_API_URL+'order/cart/'+cartId,
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiFetchCart = token => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'order/cart',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}
