export const SOLUTIONS = {
  ru: [
    {
      id: 1,
      title: 'Направления деятельности',
      icon: 'arrows-alt',
      html: `<h2>Направления</h2>
        <p>Наша компания уже долгое время занимает лидирующие позиции на рынке канцелярских товаров Украины. Канцтовары это то, что мы любим и с чем ассоциируемся у наших клиентов в первую очередь.</p>
        <p>Не так давно мы расширили ассортимент до хозяйственных товаров, игрушек для детей и сезонных товаров и уже становимся лучшими в этих сферах.</p>`
    },
    {
      id: 2,
      title: 'Собственное производство',
      icon: 'leaf',
      html: `<h2>Производство</h2>
        <p>В какой-то момент мы поняли, что можем сделать канцтовары лучше.</p>
        <p>Когда в одном месте встречаются вдохновленные профессионалы, многолетний опыт работы и непреодолимое желание сделать качественные и доступные продукты – случается что-то удивительное. Продукты, которыми мы действительно гордимся.</p>`
    },
    {
      id: 3,
      title: 'Широкая дистрибуция',
      icon: 'flag',
      html: `<h2>Дистрибуция</h2>
        <p>Каждый день мы обеспечиваем наших клиентов прекрасными товарами, сделанными с любовью к деталям и высокими требованиями к их качеству и цене. Секрет компании в том, что каждую секунду мы работаем чтобы быть лучшими в том, что мы делаем.</p>
        <p>Сотрудничая с нами, вы можете быть уверены в своем конкурентном преимуществе.</p>`
    },
    {
      id: 4,
      title: 'Качественная логистика',
      icon: 'paper-plane',
      html: `<h2>Логистика</h2>
        <p>Наша цель – сделать оптовые покупки в интернете удобными и доступными каждому, гарантировать потрясающий сервис и, главное, сохранить ваше время. Проведите его с теми, кто вам дорог, вместо утомительных поездок, телефонных разговоров и других рутинных вещей.</p>
        <p>Со своей стороны мы обеспечим своевременную доставку заказанных вами товаров.</p>`
    }
  ],
  uk: [
    {
      id: 1,
      title: 'Напрямки діяльності',
      icon: 'arrows-alt',
      html: `<h2>Напрями</h2>
        <p>Наша компанія вже довгий час займає лідируючі позиції на ринку канцелярських товарів України. Канцтовари це те, що ми любимо та з чим асоціюємося у наших клієнтів у першу чергу.</p>
        <p>Нещодавно ми розширили асортимент до господарських товарів, іграшок для дітей та сезонних товарів і вже стаємо кращими в цих сферах.</p>`
    },
    {
      id: 2,
      title: 'Власне виробництво',
      icon: 'leaf',
      html: `<h2>Виробництво</h2>
        <p>Якоїсь миті ми зрозуміли, що можемо зробити канцтовари кращими.</p>
        <p>Коли в одному місці зустрічаються натхненні професіонали, багаторічний досвід роботи та непереборне бажання зробити якісні та доступні продукти – трапляється щось дивовижне. Продукти, якими ми справді пишаємося.</p>`
    },
    {
      id: 3,
      title: 'Широка дистрибуція',
      icon: 'flag',
      html: `<h2>Дистрибуція</h2>
        <p>Кожен день ми забезпечуємо наших клієнтів чудовими товарами, зробленими з любов'ю до деталей та високими вимогами до їхньої якості та ціни. Секрет компанії в тому, що кожної секунди ми працюємо, щоб бути кращими в тому, що ми робимо.</p>
        <p>Співпрацюючи з нами, ви можете бути впевнені у своїй конкурентній перевагі.</p>`
    },
    {
      id: 4,
      title: 'Якісна логістика',
      icon: 'paper-plane',
      html: `<h2>Логістика</h2>
        <p>Наша мета – зробити оптові покупки в інтернеті зручними та доступними кожному, гарантувати приголомшливий сервіс та, головне, зберегти ваш час. Проведіть його з тими, хто вам дорогий, замість стомлюючих поїздок, телефонних розмов та інших рутинних речей.</p>
        <p>Зі свого боку, ми забезпечимо своєчасну доставку замовлених вами товарів.</p>`
    }
  ]
};