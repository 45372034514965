import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

const LazyImage = ({ image }) => (
  <div className={image.className || ''}>
    <LazyLoadImage
      alt={image.alt}
      src={image.src} // use normal <img> attributes as props
      width='100%'
      height='100%'
      effect='black-and-white'
      wrapperClassName={image.wrapperClassName || ''}
      placeholderSrc={image.lowResSrc || null}
    />
    <span>{image.caption}</span>
  </div>
);

export default LazyImage;
