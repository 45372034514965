import { 
  takeLatest,
  call,
  put 
} from 'redux-saga/effects';

import { 
  FETCH_PRODUCT,
} from './types';

import { 
  apiFetchProduct
} from './../services';

export function* watchFetchProduct() {
  yield takeLatest(FETCH_PRODUCT.REQUEST, fetchProductSaga);
}

function* fetchProductSaga(data) {
  try {
    const response = yield call(apiFetchProduct, data.productId, data.token);

    let product = {data: {[data.productId]: response.data}, productId: parseInt(data.productId)};

    // dispatch a success action to the store with the new categories
    yield put({ type: FETCH_PRODUCT.SUCCESS, payload: product });
  } catch (error) {
    // dispatch a failure action to the store with the error
    const message = error.message;
    
    yield put({ type: FETCH_PRODUCT.FAILURE, payload: message });
  }
}

export const fetchProduct = (token, productId) => ({
  type: FETCH_PRODUCT.REQUEST,
  productId: productId,
  token: token
});