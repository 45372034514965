import fileDownload from 'js-file-download';

import { 
  takeLatest,
  call,
  put 
} from 'redux-saga/effects';

import { 
  ADD_CART
} from 'components/Cart/actions/types';

import { 
  FETCH_ORDER,
  FETCH_ORDERS,
  REORDER_ORDER,
  DELETE_ORDER,
  SET_ORDER_LOAD_MORE,
  ASSIGN_ORDERS,
  INVOICE_ORDER
} from './types';

import { 
  apiFetchOrder,
  apiFetchOrders,
  apiReorderOrder,
  apiDeleteOrder,
  apiInvoiceOrder,
} from './../services';

import { SET_NOTIFICATION } from 'components/Notification/actions/types';

export function* watchFetchOrders() {
  yield takeLatest(FETCH_ORDERS.REQUEST, fetchOrdersSaga);
}

function* fetchOrdersSaga(data) {
  try {
    let response;

    const pageReg = /[\?|&]page=\d+/gm;

    if (pageReg.test(data.query)){
      yield put({ type: SET_ORDER_LOAD_MORE });
      response = yield call(apiFetchOrders, data.token, data.query);
      yield put({ type: ASSIGN_ORDERS, payload: response.data });
    }else{
      response = yield call(apiFetchOrders, data.token, data.query);
      yield put({ type: FETCH_ORDERS.SUCCESS, payload: response.data });
    }
  } catch (error) {

    // dispatch a failure action to the store with the error
    yield put({ type: FETCH_ORDERS.FAILURE, payload: error.message });
  }
}

export const fetchOrders = (token, query) => ({
  type: FETCH_ORDERS.REQUEST,
  token: token,
  query: query
});

export function* watchFetchOrder() {
  yield takeLatest(FETCH_ORDER.REQUEST, fetchOrderSaga);
}

function* fetchOrderSaga(data) {
  try {
    const response = yield call(apiFetchOrder, data.orderId, data.token);

    yield put({ type: FETCH_ORDER.SUCCESS, payload: response.data });
  } catch (error) {

    // dispatch a failure action to the store with the error
    yield put({ type: FETCH_ORDER.FAILURE, payload: error.message });
  }
}

export const fetchOrder = (orderId, token) => ({
  type: FETCH_ORDER.REQUEST,
  orderId: orderId,
  token: token
});

export function* watchReorderOrder() {
  yield takeLatest(REORDER_ORDER.REQUEST, reorderOrderSaga);
}

function* reorderOrderSaga(data) {
  try {
    const response = yield call(apiReorderOrder, data.orderId, data.token);

    yield put({ type: ADD_CART.SUCCESS, payload: response.data });
    yield put({ type: SET_NOTIFICATION, payload: {msgId: 'REORDER_ORDER.SUCCESS', type: 'success'} });
  } catch (error) {

    // dispatch a failure action to the store with the error
    yield put({ type: REORDER_ORDER.FAILURE, payload: error.message });
  }
}

export const reorderOrder = (orderId, token) => ({
  type:  REORDER_ORDER.REQUEST,
  orderId: orderId,
  token: token
});

export function* watchDeleteOrder() {
  yield takeLatest(DELETE_ORDER.REQUEST, deleteOrderSaga);
}

function* deleteOrderSaga(data) {
  try {
    const response = yield call(apiDeleteOrder, data.orderId, data.token);

    if (response.data){
      yield put({ type: DELETE_ORDER.SUCCESS, payload: data.orderId });
      yield put({ type: SET_NOTIFICATION, payload: {msgId: 'DELETE_ORDER.SUCCESS', type: 'success'} });
    }
  } catch (error) {

    // dispatch a failure action to the store with the error
    yield put({ type: DELETE_ORDER.FAILURE, payload: error.message });
  }
}

export const deleteOrder = (orderId, token) => ({
  type:  DELETE_ORDER.REQUEST,
  orderId: orderId,
  token: token
});

export function* watchInvoiceOrder() {
  yield takeLatest(INVOICE_ORDER.REQUEST, invoiceOrderSaga);
}

function* invoiceOrderSaga(data) {
  try {
    const response = yield call(apiInvoiceOrder, data.orderId, data.token, data.barcode);

    if (response.data){
      fileDownload(response.data, `inoice${data.orderId}.xlsx`);
    }
  } catch (error) {

    // dispatch a failure action to the store with the error
    yield put({ type: INVOICE_ORDER.FAILURE, payload: error.message });
  }
}

export const invoiceOrder = (orderId, token, barcode = false) => ({
  type:  INVOICE_ORDER.REQUEST,
  orderId: orderId,
  token: token,
  barcode: barcode,
});