export {
  toggleCategories,
  fetchCategories,
  watchFetchCategories,
  fetchCategory,
  watchFetchCategory,
  fetchSubCategory,
  watchFetchSubCategory,
  deleteViewed,
  watchDeleteViewed,
  fetchArrivals,
  watchFetchArrivals,
  watchFetchFavorites,
  fetchFavorites,
  controlFavorite,
  watchControlFavorite,
  watchfetchLovedCategories,
  fetchLovedCategories,
  clearSearchAutocomplete,
  fetchIncomes,
  watchFetchIncomes,
} from './categoriesActions';