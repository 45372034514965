import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import { getCurrency } from 'utils/services';

class Notification extends Component {
  state = {}

  componentDidUpdate(prevProps) {
    this.showErrors();
  }

  showErrors() {
    const { notifications, messages, type, currency, currentLocale } = this.props;

    _.forIn(notifications, notification => {

      let key = _.isArray(notification) 
        ? notification[0][0]
        : notification;

      const regex = /[+-]?\d+\.?\d+/;

      const match = regex.exec(key);

      let replace;
      if (match !== null){
        replace = match[0];
      }

      key = key.replace(regex, '{replace}') || '';

      let msg = messages[currentLocale][key] || messages[currentLocale]['error'];

      if (currency){
        msg = msg.replace('{replace}', Math.ceil(replace)).replace('{currency}', getCurrency(currency, currentLocale));
      }

      this.addNotification({
        title: '',
        message: msg,
        type: type,
        duration: 6000
      });
    });
  }

  addNotification = notification => {
    this.notificationDOMRef.addNotification({
      title: notification.title,
      message: notification.message,
      type: notification.type,
      insert: 'bottom',
      container: 'bottom-right',
      animationIn: ['animated', 'flipInX'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: { duration: notification.duration },
      dismissable: { click: true }
    });
  }

  render() {
    return (
      <div>
        <ReactNotification ref={node => this.notificationDOMRef = node} />
      </div>
    )
  }
}

Notification.propTypes = {
  notifications: PropTypes.array.isRequired,
  messages: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  currentLocale: PropTypes.string.isRequired,
  currency: PropTypes.string
};

export default Notification;