import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select, { components } from 'react-select';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import './styles.scss';

class CustomSelect extends Component {
  render() {
    const { options, placeholder, noOptionsMessage, onChange, colors, className, classNamePrefix, isSearchable, selected } = this.props;
    const defaultValue = options[selected];

    const DropdownIndicator = props => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <FontAwesomeIcon icon={props.selectProps.menuIsOpen ? 'caret-up' : 'caret-down'}/>
          </components.DropdownIndicator>
        )
      );
    };

    const IndicatorSeparator = ({ innerProps }) => {
      return null;
    }

    return (
      options.length
        ?  <Select 
            menuPlacement="auto"
            className={className}
            classNamePrefix={classNamePrefix}
            label={placeholder}
            defaultValue={defaultValue}
            value={defaultValue}
            options={options} 
            components={{ DropdownIndicator, IndicatorSeparator }}
            placeholder={placeholder} 
            noOptionsMessage={()=>(noOptionsMessage)}
            isSearchable={isSearchable}
            filterOption={(data, value)=>(
              data.data.textValue
                ? (data.data.textValue.toLowerCase()).includes(value.toLowerCase()) && data
                : data
            )}
            theme={theme => ({
              ...theme,
              borderRadius: 3,
              colors: {
                ...theme.colors,
                ...colors
              },
            })}
            onChange={(selected) => onChange(selected)}
          />
        : <div className='selectLoader'><Loader /></div>
    )
  }
}

CustomSelect.defaultProps = {
  options: [],
  placeholder: 'Выберите',
  noOptionsMessage: 'Нет опиций',
  colors: {
    primary25: '#EB8C01',
    primary50: '#EB8C01',
    primary: '#EB8C01',
    neutral80: '#2C2C2C',
  },
  style: {},
  classNamePrefix: '',
  className: '',
  isSearchable: true,
  selected: 0
};

CustomSelect.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default CustomSelect;