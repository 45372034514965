import React, { Component } from 'react';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import { apiSendMessage } from './services';

class ContactForm extends Component {
  state = {
    comment: '',
    type: 'mail'
  }

  handleComment = e => {
    this.setState({comment: e.target.value});
  }

  handleType = e => {
    this.setState({type: e.target.value});
  }

  handleConfirm = () => {
    const { setNotification, userToken } = this.props;
    const { comment } = this.state;

    const that = this;

    if (_.isEmpty(comment)){
      setNotification('messageRequired');
    }else{
      apiSendMessage(this.state, userToken).then(function (response) {
        if (response.data){
          setNotification('messageSuccess', 'success');
          that.setState({type: 'mail', comment: ''});
        }else{
          setNotification('error');
        }
      }).catch(function (error) {
        setNotification('error');
      });
    }
  }

  render() {
    const { style, sendingMessage } = this.props;
    const { comment, type } = this.state;

    return(
      <div className={style.root}>
        <div className={style.heading}><FormattedMessage id="ContactForm.HEADING" /></div>
        <div className={style.info}>
          <div className={style.comment}>
            <textarea className={style.commentTextarea} onChange={this.handleComment} value={ comment }></textarea>
          </div>
          <div className={style.radio}>
            <div className={style.radioLabel}><FormattedMessage id="ContactForm.TITLE" /></div>
            <label>
              <input type='radio' value='mail' onChange={this.handleType} checked={type==='mail'} /><FormattedMessage id="ContactForm.EMAIL" />
            </label>
            <label>
              <input type='radio' value='phone' onChange={this.handleType} checked={type==='phone'} /><FormattedMessage id="ContactForm.PHONE" />
            </label>
          </div>
          <div className={style.btnWrapper}>
            {sendingMessage
              ? <div className={style.btnLoaderOrder}><Loader /></div>
              : <button className={style.btnOrder} onClick={this.handleConfirm}><FormattedMessage id="ContactForm.SEND" /></button>
            }
          </div>
        </div>
      </div>
    )
  }
}

ContactForm.defaultProps = {
  style: require('./ContactForm.module.scss'),
}

ContactForm.propTypes = {
  userToken: PropTypes.string.isRequired,
  setNotification: PropTypes.func.isRequired
};

export default ContactForm;