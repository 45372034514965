export const createAsyncAction = (action) => ({
  REQUEST: action + '_REQUEST',
  SUCCESS: action + '_SUCCESS',
  FAILURE: action + '_FAILURE',
  CANCEL: action + '_CANCEL'
});

export const createAction = (action) => {
  return action + '_ACTION';
}
