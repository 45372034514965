import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CartSuccess from './components/CartSuccess';
import CartOrder from './components/CartOrder';
import _ from 'lodash';

class Cart extends Component {
  render() {
    const { style, checkout } = this.props;

    return (
      <div className={style.root}>
        {!_.isEmpty(checkout)
          ? <CartSuccess />
          : <CartOrder />
        }
      </div>
    )
  }
}

Cart.defaultProps = {
  style: require('./Cart.module.scss')
};

Cart.propTypes = {
  checkout: PropTypes.object.isRequired
};

export default Cart;