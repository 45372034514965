import axios from 'axios';
import ls from 'local-storage';

export const apiUserSms = phone => {
  return axios({
    method: 'POST',
    url: process.env.REACT_APP_API_URL+'auth/code',
    data: {
      phone: phone
    }
  });
}

export const apiUserToken = (phone, code, loginModer) => {
  let data = {
    phone: phone,
    code: code
  }

  if (loginModer){
    data = {...data, login_moder: '1'};
  }

  return axios({
    method: 'POST',
    url: process.env.REACT_APP_API_URL+'auth/login',
    data: data
  });
}

export const apiGetUser = token => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'auth/user',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiGetUserBalance = token => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'auth/user/balance',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiEditUser = (userId, data, token) => {
  return axios({
    method: 'PUT',
    url: process.env.REACT_APP_API_URL+'auth/user/'+userId,
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    },
    data: data
  });
}

export const apiGetUserStatistic = token => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'auth/statistic',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiGetUserRecipients = token => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'delivery/recipient',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiGetDeliveryArea = (token, data) => {
  return axios({
    method: 'POST',
    url: process.env.REACT_APP_API_URL+'delivery/area',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    },
    data: data
  });
}

export const apiGetDeliveryCity = (token, data) => {
  return axios({
    method: 'POST',
    url: process.env.REACT_APP_API_URL+'delivery/city',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    },
    data: data
  });
}

export const apiGetDeliveryWarehouse = (token, data) => {
  return axios({
    method: 'POST',
    url: process.env.REACT_APP_API_URL+'delivery/warehouse',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    },
    data: data
  });
}

export const apiAddUserRecipinet = (token, data) => {
  return axios({
    method: 'POST',
    url: process.env.REACT_APP_API_URL+'delivery/recipient',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    },
    data: data
  });
}

export const saveToLocalStorage = (key, data) => {
  ls.set(key, data);
}

export const removeFromLocalStorage = key => {
  ls.remove(key);
}

export const apiGetPartnersBlanks = token => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'catalog/partners/blanks',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiGetSettings = token => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'auth/config',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiUserRegister = data => {
  return axios({
    method: 'POST',
    url: process.env.REACT_APP_API_URL+'auth/registration',
    data: data
  });
}

export const apiFetchBalanceGrid = token => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'auth/user/balance/grid',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiUserBalanceSms = token => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'auth/user/access/get',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiUserBalanceLogin = (token, code) => {
  return axios({
    method: 'POST',
    url: process.env.REACT_APP_API_URL+'auth/user/access/set',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    },
    data: {
      code: code
    }
  });
}

export const apiGetMaintenance = token => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'maintenance',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
};

export const apiGetBanners = token => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'banners/index-big',
    headers: {
      'Accept': 'application/json',
      'Accept-Language': 'ru',
      'Authorization': 'Bearer '+token
    }
  });
}