import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { setNotification } from 'components/Notification/actions';
import BalanceSms from './BalanceSms';
import { 
  userBalanceSms, 
  userBalanceLogin 
} from 'components/User/actions';

const mapStateToProps = state => ({
  userToken: state.user.token,
  phone: state.user.balanceGrid.phone
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setNotification,
    userBalanceSms,
    userBalanceLogin
  }, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BalanceSms));
