import axios from 'axios';

export const apiAddCheckout = (recipientId, comment, token) => {
  return axios({
    method: 'POST',
    url: process.env.REACT_APP_API_URL+'order/checkout',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    },
    data: {
      'recipient': recipientId,
      'comment': comment
    }
  });
}