exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Banners_root__idm62 {\n  --swiper-pagination-color: #EB8C01;\n  --swiper-navigation-color: #EB8C01; }\n  .Banners_root__idm62 .swiper-button-prev, .Banners_root__idm62 .swiper-button-next {\n    display: none;\n    transition: .3s linear; }\n    @media (min-width: 768px) {\n      .Banners_root__idm62 .swiper-button-prev, .Banners_root__idm62 .swiper-button-next {\n        display: block; } }\n    .Banners_root__idm62 .swiper-button-prev:hover, .Banners_root__idm62 .swiper-button-next:hover {\n      color: #b86e01; }\n\n.Banners_link__2kKdt {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  align-content: center; }\n\na.Banners_link__2kKdt {\n  cursor: pointer; }\n\n.Banners_image__koMc0 {\n  flex: 1 1 auto;\n  width: 100%;\n  height: auto; }\n\n.Banners_loader__2vnY2 {\n  text-align: center; }\n", ""]);

// Exports
exports.locals = {
	"root": "Banners_root__idm62",
	"link": "Banners_link__2kKdt",
	"image": "Banners_image__koMc0",
	"loader": "Banners_loader__2vnY2"
};