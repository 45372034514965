import { createReducer } from 'redux-act';
import ls from 'local-storage';

import { 
  ADD_CART,
  CHANGE_CART,
  DELETE_CART,
  FETCH_CART,
  RESET_CART,
  ADD_CART_COMMENT
} from './../actions/types';

const DEFAULT_STATE = {
  cart: {},
  fetching: false,
  errors: {},
  cartComment: ls.get('cartComment') || ''
};

export default createReducer({
  [FETCH_CART.REQUEST]: (state) => ({
    ...state,
    fetching: true
  }),
  [FETCH_CART.SUCCESS]: (state, payload) => ({
    ...state,
    fetching: false,
    cart: payload
  }),
  [FETCH_CART.FAILURE]: (state, error) => ({
    ...state,
    fetching: false,
    errors: error,
  }),
  [ADD_CART.SUCCESS]: (state, payload) => ({
    ...state,
    cart: payload
  }),
  [CHANGE_CART.SUCCESS]: (state, payload) => ({
    ...state,
    cart: payload
  }),
  [DELETE_CART.SUCCESS]: (state, payload) => ({
    ...state,
    cart: payload
  }),
  [RESET_CART]: state => ({
    ...state,
    cart: {}
  }),
  [ADD_CART_COMMENT]: (state, payload) => ({
    ...state,
    cartComment: payload
  }),
}, DEFAULT_STATE);