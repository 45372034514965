exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!./../../Login.module.scss"), "");
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss"), "");
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/inputs.scss"), "");

// Module
exports.push([module.id, ".RegisterForm_root__1fzDQ {\n  width: 100%;\n  display: flex;\n  min-height: 100%;\n  align-content: flex-end;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  padding: 20px 0 40px; }\n  @media (min-width: 768px) {\n    .RegisterForm_root__1fzDQ {\n      height: 100%;\n      padding: 0;\n      justify-content: center;\n      height: calc(100vh - 80px); } }\n\n.RegisterForm_logoBtn__Nrdfw {\n  display: block; }\n  @media (min-width: 768px) {\n    .RegisterForm_logoBtn__Nrdfw {\n      text-align: left;\n      width: 100%; } }\n\n.RegisterForm_logoImg__1bTGU {\n  flex: 0 1 auto;\n  width: 220px;\n  margin: 0 auto 25px;\n  display: block; }\n  @media (min-width: 768px) {\n    .RegisterForm_logoImg__1bTGU {\n      width: 300px;\n      margin-left: 0; } }\n\n.RegisterForm_html__30A_C { }\n\n.RegisterForm_btn__9K0Yp {\n  margin-top: 50px; }\n\n.RegisterForm_input__T4aiE {\n  width: 100%;\n  max-width: 300px;\n  box-sizing: border-box;\n  margin: 30px auto 0;\n  text-align: center; }\n  .RegisterForm_input__T4aiE.RegisterForm_notValid__j7Pko {\n    border-color: #a71d2a;\n    background-color: rgba(220, 53, 69, 0.64); }\n", ""]);

// Exports
exports.locals = {
	"root": "RegisterForm_root__1fzDQ",
	"logoBtn": "RegisterForm_logoBtn__Nrdfw",
	"logoImg": "RegisterForm_logoImg__1bTGU",
	"html": "RegisterForm_html__30A_C " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!./../../Login.module.scss").locals["html"] + "",
	"btn": "RegisterForm_btn__9K0Yp " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss").locals["btn-border-svg"] + "",
	"input": "RegisterForm_input__T4aiE " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/inputs.scss").locals["input"] + "",
	"notValid": "RegisterForm_notValid__j7Pko"
};