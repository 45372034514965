import { 
  takeLatest,
  put,
  call
} from 'redux-saga/effects';

import { 
  ADD_CART,
  CHANGE_CART,
  DELETE_CART,
  FETCH_CART,
  RESET_CART,
  ADD_CART_COMMENT
} from './types';

import { 
  apiAddCart,
  apiChangeCart,
  apiDeleteCart,
  apiFetchCart,
} from './../services';

import { SET_NOTIFICATION } from 'components/Notification/actions/types';

export function* watchAddCart() {
  yield takeLatest(ADD_CART.REQUEST, addCartSaga);
}

function* addCartSaga(data) {
  try {
    const response = yield apiAddCart(data.productId, data.qty, data.token);

    yield put({ type: ADD_CART.SUCCESS, payload: response.data });
    yield put({ type: SET_NOTIFICATION, payload: {msgId: 'ADD_CART.SUCCESS', type: 'success'} })
  } catch (error) {
    // dispatch a failure action to the store with the error
    const errMsg = error.response.data.message || 'error';
    
    yield put({ type: SET_NOTIFICATION, payload: {msgId: errMsg} });
  }
}

export const addCart = (productId, qty, token) => ({
  type: ADD_CART.REQUEST,
  productId: productId,
  qty: qty,
  token: token
});

export function* watchFetchCart() {
  yield takeLatest(FETCH_CART.REQUEST, fetchCartSaga);
}

function* fetchCartSaga(data) {
  try {
    const response = yield call(apiFetchCart, data.token);

    // dispatch a success action to the store with the new cart
    yield put({ type: FETCH_CART.SUCCESS, payload: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    const message = error.message;
    
    yield put({ type: FETCH_CART.FAILURE, payload: message });
  }
}

export const fetchCart = token => ({
  type: FETCH_CART.REQUEST,
  token: token
});

export function* watchChangeCart() {
  yield takeLatest(CHANGE_CART.REQUEST, changeCartSaga);
}

function* changeCartSaga(data) {
  try {
    const response = yield apiChangeCart(data.cartId, data.qty, data.token);

    // dispatch a success action to the store with the new cart
    yield put({ type: CHANGE_CART.SUCCESS, payload: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    const message = error.message;
    
    yield put({ type: CHANGE_CART.FAILURE, payload: message });
  }
}

export const changeCart = (cartId, qty, token) => ({
  type: CHANGE_CART.REQUEST,
  cartId: cartId,
  qty: qty,
  token: token
});

export function* watchDeleteCart() {
  yield takeLatest(DELETE_CART.REQUEST, deleteCartSaga);
}

function* deleteCartSaga(data) {
  try {
    const response = yield apiDeleteCart(data.cartId, data.token);

    // dispatch a success action to the store with the new cart
    yield put({ type: DELETE_CART.SUCCESS, payload: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    const message = error.message;
    
    yield put({ type: DELETE_CART.FAILURE, payload: message });
  }
}

export const deleteCart = (cartId, token) => ({
  type: DELETE_CART.REQUEST,
  cartId: cartId,
  token: token
});

export const resetCart = () => ({
  type: RESET_CART
});

export const addCartComment = comment => ({
  type: ADD_CART_COMMENT,
  payload: comment
});