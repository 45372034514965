import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoginForm from './LoginForm';
import { setNotification } from 'components/Notification/actions';
import { userSms } from 'components/User/actions';

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    setNotification,
    userSms
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);