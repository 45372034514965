import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import maintenanceGif from 'shared/img/maintenance.gif';
import Logo from 'shared/img/logo.png';

class Maintenance extends Component {
  componentDidMount() {
    const { getSettings, userToken, settings } = this.props;

    if (_.isEmpty(settings)){
      getSettings(userToken);
    }
  }

  render() {
    const { style, intl } = this.props;

    return(
      <div className={style.root}>
        <img src={maintenanceGif} className={style.gif} alt={intl.formatMessage({id: 'Maintenance.TITLE'})} />
        <h2 className={style.heading}><FormattedMessage id="Maintenance.HEADING" /></h2>
        <p className={style.title}><FormattedMessage id="Maintenance.INFO_1" values={{ br: <br/> }} /></p>
        <p className={style.title}><FormattedMessage id="Maintenance.INFO_2" values={{ br: <br/> }} /></p>
        <div className={style.phones}>
          <a className={style.link} href="tel:+380960190900">(096) 01-90-900<span><FormattedMessage id="App.MANAGER_1" /></span></a>
          <a className={style.link} href="tel:+380733390900">(073) 33-90-900<span><FormattedMessage id="App.MANAGER_2" /></span></a>
          <a className={style.link} href="tel:+380999817238">(099) 981-72-38<span><FormattedMessage id="App.MANAGER_3" /></span></a>
        </div>
        <img src={Logo} alt='Sparta лого' className={style.logoImg} />
      </div>
    )
  }
}

Maintenance.defaultProps = {
  style: require('./Maintenance.module.scss')
}

Maintenance.propTypes = {
  getSettings: PropTypes.func.isRequired,
  userToken: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired
}

export default Maintenance;