export const DELIVERY = {
  ru: [
    {
      'id': 'intime',
      'name': 'Ин-Тайм',
      'links': [
        {
          'text': 'Адреса и контактные данные',
          'href': 'https://intime.ua/'
        },
        {
          'text': 'Посчитать стоимость доставки',
          'href': 'https://intime.ua/ru-calc'
        }
      ],
      'img': require('shared/img/delivery/intime.png')
    },
    {
      'id': 'np',
      'name': 'Новая Почта',
      'links': [
        {
          'text': 'Адреса и контактные данные',
          'href': 'https://novaposhta.ua/timetable'
        },
        {
          'text': 'Посчитать стоимость доставки',
          'href': 'https://novaposhta.ua/delivery'
        }
      ],
      'img': require('shared/img/delivery/np.png')
    },
    {
      'id': 'del',
      'name': 'Деливери',
      'links': [
        {
          'text': 'Адреса и контактные данные',
          'href': 'https://www.delivery-auto.com.ua/'
        },
        {
          'text': 'Посчитать стоимость доставки',
          'href': 'https://www.delivery-auto.com/ru-ru/CalculateCost'
        }
      ],
      'img': require('shared/img/delivery/delivery.png')
    }
  ],
  uk: [
    {
      'id': 'intime',
      'name': 'Ін-Тайм',
      'links': [
        {
          'text': 'Адреси та контактні дані',
          'href': 'https://intime.ua/'
        },
        {
          'text': 'Порахувати вартість доставки',
          'href': 'https://intime.ua/ru-calc'
        }
      ],
      'img': require('shared/img/delivery/intime.png')
    },
    {
      'id': 'np',
      'name': 'Нова Пошта',
      'links': [
        {
          'text': 'Адреси та контактні дані',
          'href': 'https://novaposhta.ua/timetable'
        },
        {
          'text': 'Порахувати вартість доставки',
          'href': 'https://novaposhta.ua/delivery'
        }
      ],
      'img': require('shared/img/delivery/np.png')
    },
    {
      'id': 'del',
      'name': 'Делівері',
      'links': [
        {
          'text': 'Адреси та контактні дані',
          'href': 'https://www.delivery-auto.com.ua/'
        },
        {
          'text': 'Порахувати вартість доставки',
          'href': 'https://www.delivery-auto.com/ru-ru/CalculateCost'
        }
      ],
      'img': require('shared/img/delivery/delivery.png')
    }
  ],
};