import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WithAuth from './WithAuth';

import { getUser } from 'components/User/actions';

const mapStateToProps = state => ({
  userToken: state.user.token,
  sentSms: state.user.sentSms
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    getUser
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(WithAuth);