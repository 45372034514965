
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import Catalog from './Catalog';
import { 
  fetchCategory,
  fetchSubCategory
} from 'components/Categories/actions';

import { setNotification } from 'components/Notification/actions';

const mapStateToProps = (state) => ({
  category: state.categories.category,
  categoriesFetching: state.categories.fetching,
  subcategory: state.categories.subcategory,
  subcategoryFetching: state.categories.subcategoryFetching,
  loadMore: state.categories.loadMore,
  userToken: state.user.token,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    fetchCategory,
    fetchSubCategory,
    setNotification
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Catalog));
