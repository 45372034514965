import { createReducer } from 'redux-act';
import ls from 'local-storage';

import {
  USER,
  USER_SMS,
  USER_TOKEN,
  USER_RESET,
  USER_EDIT,
  USER_RECIPIENTS,
  USER_STATISTIC, 
  ADD_USER_RECIPIENT,
  TOGGLE_ADD_RECIPIENT_PROFILE,
  GET_DELIVERY_AREA,
  GET_DELIVERY_CITY,
  GET_DELIVERY_WAREHOUSE,
  TOGGLE_CHANGE_PROFILE,
  GET_PARTNERS_BLANKS,
  GET_SETTINGS,
  USER_BALANCE,
  USER_NOT_REGISTER,
  USER_REGISTER,
  FETCH_BALANCE_GRID,
  UNSET_USER_NOT_REGISTER,
  USER_BALANCE_SMS,
  USER_BALANCE_LOGIN,
  GET_BANNERS
} from './../actions/types';

const DEFAULT_STATE = {
  user: ls.get('user') || {},
  token: ls.get('userToken') || '',
  sentSms: false,
  notRegister: false,
  statistic: {
    fetching: false
  },
  recipients: {
    items: [],
    fetching: false,
    errors: {},
    validate: {}
  },
  updating: false,
  changeProfileIsOpen: false,
  addRecipinetProfileIsOpen: false,
  area: {
    items: {},
    fetching: false,
    errors: {},
  },
  city: {
    items: {},
    fetching: false,
    errors: {},
  },
  warehouse: {
    items: {},
    fetching: false,
    errors: {},
  },
  partners: {
    blanks: {}
  },
  balance: {
    items: {},
    fetching: false,
    errors: {}
  },
  settings: {
    items: {},
    fetching: false,
    errors: {},
  },
  balanceGrid: {
    items: [],
    fetching: false,
    errors: {},
    loadMore: false,
    access: false,
    phone: ''
  },
  balanceLogin: true,
  banners: {
    items: {},
    fetching: false,
    errors: {},
  }
};

export default createReducer({
  [USER_SMS.SUCCESS]: (state, payload) => ({
    ...state,
    user: payload,
    sentSms: true
  }),
  [USER_SMS.FAILURE]: state => ({
    ...state,
    ...DEFAULT_STATE,
    user: {},
    token: ''
  }),
  [USER_REGISTER.SUCCESS]: (state, payload) => ({
    ...state,
    user: payload,
  }),
  [USER_REGISTER.FAILURE]: state => ({
    ...state,
    ...DEFAULT_STATE,
    user: {},
    token: ''
  }),
  [USER_NOT_REGISTER]: (state, payload) => ({
    ...state,
    user: payload,
    notRegister: true
  }),
  [UNSET_USER_NOT_REGISTER]: state => ({
    ...state,
    user: {},
    notRegister: false
  }),
  [USER_TOKEN.SUCCESS]: (state, payload) => ({
    ...state,
    user: payload,
    token: payload.token,
    sentSms: false
  }),
  [USER.SUCCESS]: (state, payload) => ({
    ...state,
    ...payload,
  }),
  [USER.FAILURE]: state => ({
    ...state,
    user: {},
    token: ''
  }),
  [USER_RESET]: state => ({
    ...state,
    ...DEFAULT_STATE,
    user: {},
    token: ''
  }),
  [USER_EDIT.REQUEST]: (state) => ({
    ...state,
    updating: true
  }),
  [USER_EDIT.SUCCESS]: (state, payload) => ({
    ...state,
    user: payload,
    updating: false
  }),
  [USER_EDIT.FAILURE]: (state, error) => ({
    ...state,
    updating: false,
    errors: error
  }),
  [USER_RECIPIENTS.REQUEST]: (state) => ({
    ...state,
    recipients: {
      ...state.recipients,
      fetching: true,
    }
  }),
  [USER_RECIPIENTS.SUCCESS]: (state, payload) => ({
    ...state,
    recipients: {
      items: payload,
      fetching: false,
      errors: {}
    }
  }),
  [USER_RECIPIENTS.FAILURE]: (state, error) => ({
    ...state,
    recipients: {
      ...state.recipients,
      fetching: false,
      errors: error,
    }
  }),
  [USER_STATISTIC.REQUEST]: (state) => ({
    ...state,
    statistic: {
      ...state.statistic,
      fetching: true,
    }
  }),
  [USER_STATISTIC.SUCCESS]: (state, payload) => ({
    ...state,
    statistic: {
      ...payload,
      fetching: false,
      errors: {}
    }
  }),
  [USER_STATISTIC.FAILURE]: (state, error) => ({
    ...state,
    statistic: {
      ...state.statistic,
      fetching: false,
      errors: error,
    }
  }),
  [USER_BALANCE.REQUEST]: (state) => ({
    ...state,
    balance: {
      ...state.balance,
      fetching: true,
    }
  }),
  [USER_BALANCE.SUCCESS]: (state, payload) => ({
    ...state,
    balance: {
      items: payload,
      fetching: false,
      errors: {}
    }
  }),
  [USER_BALANCE.FAILURE]: (state, error) => ({
    ...state,
    balance: {
      items: {},
      fetching: false,
      errors: error
    }
  }),
  [ADD_USER_RECIPIENT.REQUEST]: (state) => ({
    ...state,
    recipients: {
      ...state.recipients,
      fetching: true,
    }
  }),
  [ADD_USER_RECIPIENT.SUCCESS]: (state, payload) => ({
    ...state,
    recipients: {
      ...state.recipients,
      fetching: false,
      errors: {},
      validate: {},
      items: [
        ...state.recipients.items,
        payload
      ]
    }
  }),
  [ADD_USER_RECIPIENT.FAILURE]: (state, error) => ({
    ...state,
    recipients: {
      ...state.recipients,
      validate: error
    }
  }),
  [TOGGLE_ADD_RECIPIENT_PROFILE]: (state, payload) => ({
    ...state,
    addRecipinetProfileIsOpen: payload ? payload : !state.addRecipinetProfileIsOpen
  }),
  [TOGGLE_CHANGE_PROFILE]: (state, payload) => ({
    ...state,
    changeProfileIsOpen: payload ? payload : !state.changeProfileIsOpen
  }),
  [GET_DELIVERY_AREA.REQUEST]: (state) => ({
    ...state,
    area: {
      ...state.area,
      fetching: true,
    }
  }),
  [GET_DELIVERY_AREA.SUCCESS]: (state, payload) => ({
    ...state,
    area: {
      items: {
        ...state.area.items,
        ...payload
      },
      fetching: false,
      errors: {}
    }
  }),
  [GET_DELIVERY_AREA.FAILURE]: (state, error) => ({
    ...state,
    area: {
      ...state.area,
      fetching: false,
      errors: error,
    }
  }),
  [GET_DELIVERY_CITY.REQUEST]: (state) => ({
    ...state,
    city: {
      ...state.city,
      fetching: true,
    }
  }),
  [GET_DELIVERY_CITY.SUCCESS]: (state, payload) => ({
    ...state,
    city: {
      items: {
        ...state.city.items,
        ...payload
      },
      fetching: false,
      errors: {}
    }
  }),
  [GET_DELIVERY_CITY.FAILURE]: (state, error) => ({
    ...state,
    city: {
      ...state.city,
      fetching: false,
      errors: error,
    }
  }),
  [GET_DELIVERY_WAREHOUSE.REQUEST]: (state) => ({
    ...state,
    warehouse: {
      ...state.warehouse,
      fetching: true,
    }
  }),
  [GET_DELIVERY_WAREHOUSE.SUCCESS]: (state, payload) => ({
    ...state,
    warehouse: {
      items: {
        ...state.warehouse.items,
        ...{
          [payload.delivery]: {
            ...state.warehouse.items[payload.delivery],
            [payload.city]: [
              ...payload.warehouse
            ]
          }
        }
      },
      fetching: false,
      errors: {}
    }
  }),
  [GET_DELIVERY_WAREHOUSE.FAILURE]: (state, error) => ({
    ...state,
    warehouse: {
      ...state.warehouse,
      fetching: false,
      errors: error,
    }
  }),
  [GET_PARTNERS_BLANKS.REQUEST]: (state) => ({
    ...state,
    partners: {
      ...state.partners,
      fetching: true,
    }
  }),
  [GET_PARTNERS_BLANKS.SUCCESS]: (state, payload) => ({
    ...state,
    partners: {
      ...state.partners,
      blanks: payload,
      fetching: false,
      errors: {},
    }
  }),
  [GET_PARTNERS_BLANKS.FAILURE]: (state, error) => ({
    ...state,
    partners: {
      ...state.partners,
      fetching: false,
      errors: error,
    }
  }),
  [GET_SETTINGS.REQUEST]: (state) => ({
    ...state,
    settings: {
      ...state.settings,
      fetching: true,
    }
  }),
  [GET_SETTINGS.SUCCESS]: (state, payload) => ({
    ...state,
    settings: {
      ...state.settings,
      items: payload,
      fetching: false,
      errors: {},
    }
  }),
  [GET_SETTINGS.FAILURE]: (state, error) => ({
    ...state,
    partners: {
      ...state.partners,
      items: {},
      fetching: false,
      errors: error,
    }
  }),
  [FETCH_BALANCE_GRID.REQUEST]: (state) => ({
    ...state,
    balanceGrid: {
      ...state.balanceGrid,
      fetching: true,
      access: false
    }
  }),
  [FETCH_BALANCE_GRID.SUCCESS]: (state, payload) => ({
    ...state,
    balanceGrid: {
      ...state.balanceGrid,
      items: payload,
      errors: {},
      fetching: false,
      access: true,
      phone: ''
    }
  }),
  [FETCH_BALANCE_GRID.FAILURE]: (state, error) => ({
    ...state,
    balanceGrid: {
      items: [],
      fetching: false,
      access: false,
      errors: error,
      phone: ''
    }
  }),
  [USER_BALANCE_SMS.REQUEST]: (state) => ({
    ...state,
    balanceGrid: {
      ...state.balanceGrid,
      fetching: true,
      phone: ''
    }
  }),
  [USER_BALANCE_SMS.SUCCESS]: (state, payload) => ({
    ...state,
    balanceGrid: {
      ...state.balanceGrid,
      fetching: false,
      phone: payload
    }
  }),
  [USER_BALANCE_SMS.FAILURE]: (state, payload) => ({
    ...state,
    balanceGrid: {
      ...state.balanceGrid,
      fetching: false,
      phone: ''
    }
  }),
  [USER_BALANCE_LOGIN.REQUEST]: (state) => ({
    ...state,
    balanceLogin: true,
    balanceGrid: {
      ...state.balanceGrid,
      fetching: true,
      phone: ''
    }
  }),
  [USER_BALANCE_LOGIN.SUCCESS]: (state, payload) => ({
    ...state,
    balanceLogin: false,
    balanceGrid: {
      ...state.balanceGrid,
      fetching: false,
      access: true
    }
  }),
  [USER_BALANCE_LOGIN.FAILURE]: (state, payload) => ({
    ...state,
    balanceLogin: true,
    balanceGrid: {
      ...state.balanceGrid,
      fetching: false,
      access: false
    }
  }),
  [GET_BANNERS.REQUEST]: (state) => ({
    ...state,
    banners: {
      ...state.banners,
      fetching: true,
    }
  }),
  [GET_BANNERS.SUCCESS]: (state, payload) => ({
    ...state,
    banners: {
      ...payload,
      fetching: false,
      errors: {}
    }
  }),
  [GET_BANNERS.FAILURE]: (state, error) => ({
    ...state,
    banners: {
      ...state.banners,
      fetching: false,
      errors: error,
    }
  })
}, DEFAULT_STATE);