import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

class CatalogMenu extends Component {

  render() {
    const { style, toggleCategories, isCategoriesOpen, template } = this.props;

    return (
      <div className={`${style.root} ${style[template]}`}>
        <button 
          className={classNames(
            style.btnCatalog,
            {[style.active]: isCategoriesOpen}
          )} 
          onClick={toggleCategories}
        >
          <MediaQuery minWidth={1280}>
            <div className={style.menuIcon}><FontAwesomeIcon icon="bars" /></div>
          </MediaQuery>
          <span><FormattedMessage id="CatalogMenu.CATALOG" /></span>
        </button>
      </div>
    )
  }
}

CatalogMenu.defaultProps = {
  style: require('./CatalogMenu.module.scss'),
  template: 'default'
};

CatalogMenu.propTypes = {
  toggleCategories: PropTypes.func.isRequired,
  isCategoriesOpen: PropTypes.bool.isRequired,
  template: PropTypes.string,
};

export default CatalogMenu;