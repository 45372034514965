exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!./../../Login.module.scss"), "");
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss"), "");
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/inputs.scss"), "");

// Module
exports.push([module.id, ".LoginSms_root__230DB {\n  width: 100%;\n  display: flex;\n  height: 100%;\n  align-content: flex-end;\n  align-items: center;\n  justify-content: flex-end;\n  flex-direction: column;\n  height: calc(100vh - 80px);\n  min-height: 560px; }\n  @media (min-width: 768px) {\n    .LoginSms_root__230DB {\n      min-height: 700px;\n      justify-content: center; } }\n\n.LoginSms_logoBtn__2ZhBh {\n  display: block; }\n  @media (min-width: 768px) {\n    .LoginSms_logoBtn__2ZhBh {\n      text-align: left;\n      width: 100%; } }\n\n.LoginSms_logoImg__TW2Ci {\n  flex: 0 1 auto;\n  width: 220px;\n  margin: 0 auto 25px;\n  display: block; }\n  @media (min-width: 768px) {\n    .LoginSms_logoImg__TW2Ci {\n      width: 300px;\n      margin-left: 0; } }\n\n.LoginSms_html__1WHQP { }\n\n.LoginSms_btn__1LjBi { }\n\n.LoginSms_telInputMask__3hIGM {\n  width: 100%;\n  max-width: 300px;\n  box-sizing: border-box;\n  margin: 30px auto 50px;\n  text-align: center; }\n  .LoginSms_telInputMask__3hIGM.LoginSms_notValid__BRZQn {\n    border-color: #a71d2a;\n    background-color: rgba(220, 53, 69, 0.64); }\n\n.LoginSms_text__2QL2H {\n  font-size: 12px;\n  color: #D7D7D7;\n  text-align: left;\n  margin: 25px auto;\n  line-height: 18px; }\n  @media (min-width: 768px) {\n    .LoginSms_text__2QL2H {\n      font-size: 16px;\n      line-height: 32px;\n      text-align: center; } }\n\n.LoginSms_link__3iB-V {\n  color: #FFFFFF;\n  font-weight: 700; }\n  .LoginSms_link__3iB-V:hover {\n    color: #EB8C01; }\n\n.LoginSms_loader__1O_BK {\n  margin-top: 20px; }\n", ""]);

// Exports
exports.locals = {
	"root": "LoginSms_root__230DB",
	"logoBtn": "LoginSms_logoBtn__2ZhBh",
	"logoImg": "LoginSms_logoImg__TW2Ci",
	"html": "LoginSms_html__1WHQP " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!./../../Login.module.scss").locals["html"] + "",
	"btn": "LoginSms_btn__1LjBi " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss").locals["btn-border-svg"] + "",
	"telInputMask": "LoginSms_telInputMask__3hIGM " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/inputs.scss").locals["input"] + "",
	"notValid": "LoginSms_notValid__BRZQn",
	"text": "LoginSms_text__2QL2H",
	"link": "LoginSms_link__3iB-V " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss").locals["btnLink"] + "",
	"loader": "LoginSms_loader__1O_BK"
};