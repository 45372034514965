import React, { Component } from 'react';
import PropTypes from 'prop-types'
import ListProducts from 'components/ListProducts';
import Loader from 'components/Loader';
import _ from 'lodash';
import classNames from 'classnames';
import { ReactComponent as Bullet } from 'shared/img/bullet.svg';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { STATUSES } from 'components/Orders/constants';

class Order extends Component {
  state = {
    orderId: null
  }

  componentDidMount() {
    const { userToken, fetchOrder, match: { params } } = this.props;

    if(params.orderId){
      fetchOrder(params.orderId, userToken);
      this.setState({
        orderId: params.orderId
      });
    }
  }

  handleReorder = orderId => () => {
    const { userToken, reorderOrder } = this.props;

    reorderOrder(orderId, userToken);
  }

  render() {
    const { style, order, groupid, currentLocale } = this.props;

    return (
      <div className={style.root}>
        <div className={style.container}>
          {!_.isEmpty(order) 
            ? <div className={style.wrapper}>
                <div className={style.left}>
                  <ListProducts disabled={true} products={order.goods_for_site} currency={order.currency} total={order.total} showBottom={true} />
                </div>
                <div className={style.right}>
                  <div className={style.heading}>
                    <FormattedMessage id="Order.TITLE" values={{ orderId: order.id }} />
                    <a href={`${process.env.REACT_APP_COLORIT_IP}invoice${order.id}_${groupid}.xls`} download className={style.btnInvoice}>
                      <span><FormattedMessage id="Order.INVOICE" /></span>
                    </a>
                  </div>
                  <div className={style.status}>
                    {_.map(STATUSES[currentLocale], (status, statusId) => {

                      let statusDate;
                      if (statusId==='accepted'){
                        statusDate = moment(order.added).isValid() ? moment(order.added).format('lll') : '';
                      }else if(statusId==='delivered'){
                        statusDate = moment(order.ready).isValid() ? moment(order.ready).format('lll') : '';
                      }else {
                        statusDate = moment(order.assembly).isValid() ? moment(order.assembly).format('lll') : <FormattedMessage id="App.NOT_SET" />;
                      }

                      return(
                        <div key={status.name} className={classNames(style.statusItem, {[style.active]: (statusId.split('|').includes(order.status))})}>
                          <div className={style.statusBullet}><Bullet /></div>
                          <div className={style.statusName}>{status.name}</div>
                          <div className={style.statusTime}>{statusDate}</div>
                        </div>)
                      })
                    }
                  </div>
                  <button onClick={this.handleReorder(order.id)} className={style.btnReorder}>
                    <FormattedMessage id="Order.CART_ADD" />
                  </button>
                </div>
              </div>
            : <div className={style.empty}>
                <Loader />
              </div>
          }
        </div>
      </div>
    )
  }
}

Order.defaultProps = {
  style: require('./Order.module.scss')
};

Order.propTypes = {
  order: PropTypes.object.isRequired,
  fetchOrder: PropTypes.func.isRequired,
  reorderOrder: PropTypes.func.isRequired,
  userToken: PropTypes.string.isRequired,
  currentLocale: PropTypes.string.isRequired,
  groupid: PropTypes.number.isRequired
};

export default Order;