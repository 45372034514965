import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import Login from './Login';

const mapStateToProps = (state) => ({
  userToken: state.user.token,
  userSentSms: state.user.sentSms,
  userNotRegister: state.user.notRegister,
  currentLocale: state.lang.currentLocale,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Login));