import axios from 'axios';

export const apiFetchCategories = token => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'catalog/tree',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiFetchArrivals = (token, query) => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'catalog/arrivals'+query,
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiFetchCategory = (query, token) => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'catalog/grid/'+query,
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiFetchSubcategory = (catId, token) => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'catalog/subcat/'+catId,
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiDeleteViewed = (productId, token) => {
  return axios({
    method: 'DELETE',
    url: process.env.REACT_APP_API_URL+'catalog/grid/viewed/'+productId,
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiFetchFavorites = token => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'catalog/favorite',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apifetchLovedCategories = token => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'catalog/tree?only_ordered=1',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiControlFavorite = (data, token) => {
  return axios({
    method: 'POST',
    url: process.env.REACT_APP_API_URL+'catalog/favorite',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    },
    data: {
      ids: data
    }
  });
}

export const apiFetchSupercatPrice = (supercatId, markup, token) => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'catalog/excel/goods/'+supercatId+'?markup='+markup,
    responseType: 'blob',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiFetchArrivalInvoice = (date, fee, token) => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'catalog/excel/arrival/'+date+'?markup='+fee,
    responseType: 'blob',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiFetchIncomes = token => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'catalog/income',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}