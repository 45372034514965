exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/layout.scss"), "");

// Module
exports.push([module.id, ".Contact_mapWrapper__x7Azj {\n  position: relative;\n  width: 100%;\n  overflow: hidden; }\n\n.Contact_map__3UcK- {\n  min-width: 1000px;\n  height: 100vh;\n  width: 100%;\n  overflow: hidden; }\n\n.Contact_marker__3PUJi {\n  position: relative; }\n\n.Contact_markerText__L8xxH {\n  white-space: nowrap;\n  background: #EB8C01;\n  padding: 10px;\n  font-size: 11px;\n  color: #FFFFFF;\n  line-height: 13px;\n  width: 186px;\n  position: relative;\n  position: absolute;\n  left: -90px;\n  bottom: 40px;\n  border-radius: 3px; }\n  .Contact_markerText__L8xxH strong {\n    font-weight: 700; }\n  .Contact_markerText__L8xxH:after {\n    content: '';\n    width: 0;\n    height: 0;\n    border-left: 11px solid transparent;\n    border-right: 11px solid transparent;\n    border-top: 16px solid #EB8C01;\n    position: absolute;\n    top: calc(100% - 8px);\n    z-index: 1;\n    left: 50%;\n    transform: translateX(-50%); }\n\n.Contact_form__vP_QS {\n  margin: 15px -15px; }\n  @media (min-width: 1024px) {\n    .Contact_form__vP_QS {\n      position: absolute;\n      right: 0;\n      top: 75px;\n      width: 420px;\n      z-index: 1; } }\n\n.Contact_formContainer__I1ARp {\n  position: relative; }\n", ""]);

// Exports
exports.locals = {
	"mapWrapper": "Contact_mapWrapper__x7Azj",
	"map": "Contact_map__3UcK-",
	"marker": "Contact_marker__3PUJi",
	"markerText": "Contact_markerText__L8xxH",
	"form": "Contact_form__vP_QS",
	"formContainer": "Contact_formContainer__I1ARp " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/layout.scss").locals["container"] + ""
};