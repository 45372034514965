import React, { Component } from 'react';
import PropTypes from 'prop-types';

class StatusIcon extends Component {
  render() {
    const { style } = this.props;

    return (
      <svg width="57px" height="77px" viewBox="0 0 57 77">
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.5">
              <g id="Group-2" transform="translate(-89.000000, -395.000000)">
                  <g id="Group" transform="translate(49.000000, 291.000000)">
                      <g id="Badge" transform="translate(40.500000, 104.000000)">
                          <g id="Group-3">
                              <path d="M54.148,57.625 L54.148,3.976 C54.148,2.65246756 52.9681685,1.50252035 51.601,1.5 L3.976,1.5 C2.62902482,1.5 1.5,2.62882938 1.5,3.976 L1.5,57.625 C1.5,59.2573056 2.78084595,61.4566479 4.19171901,62.2467853 L25.1084362,74.1675386 C26.5278259,74.9631538 29.1201741,74.9631538 30.530281,74.1727853 L51.4660949,62.241241 C52.8671541,61.4566479 54.148,59.2573056 54.148,57.625 Z" id="Page-1" stroke={style.stroke} strokeWidth="3" fill={style.fill}></path>
                              <g id="Star" transform="translate(10.000000, 8.000000)" fill={style.stroke}>
                                  <path d="M21.9264,14.37936 C21.80064,14.37936 21.67296,14.3496 21.55872,14.2896 L17.77104,12.29808 L13.98384,14.2896 C13.71744,14.42976 13.39488,14.40768 13.1496,14.2296 C12.90576,14.05104 12.78384,13.75104 12.83424,13.45488 L13.55808,9.23808 L10.49424,6.25104 C10.27872,6.04128 10.2,5.7264 10.2936,5.43936 C10.38576,5.15232 10.63488,4.94448 10.93296,4.90128 L15.16896,4.2864 L17.06256,0.44832 C17.19552,0.17808 17.47104,0.00768 17.77296,0.00768 C18.07488,0.00768 18.3504,0.17808 18.48384,0.44832 L20.37744,4.2864 L24.61296,4.90128 C24.91104,4.94448 25.15872,5.15232 25.25232,5.43936 C25.3464,5.7264 25.26768,6.04128 25.05168,6.25104 L21.98832,9.23808 L22.71168,13.45488 C22.76256,13.75296 22.64064,14.05296 22.3968,14.2296 C22.25232,14.32896 22.0896,14.37936 21.9264,14.37936 L21.9264,14.37936 Z M17.7696,10.6104 C17.8968,10.6104 18.02256,10.64064 18.1368,10.70064 L20.87232,12.13872 L20.3496,9.0936 C20.3064,8.8368 20.3904,8.57424 20.57616,8.39232 L22.78896,6.23424 L19.7304,5.78976 C19.4736,5.75232 19.2504,5.59104 19.13424,5.3568 L17.76576,2.58576 L16.3968,5.3568 C16.28256,5.59104 16.05936,5.75232 15.80064,5.78976 L12.74256,6.23424 L14.95488,8.39232 C15.14256,8.57424 15.22704,8.8368 15.18384,9.0936 L14.66064,12.13872 L17.39616,10.70064 C17.51616,10.64064 17.64192,10.6104 17.7696,10.6104 L17.7696,10.6104 Z" id="Fill-1"></path>
                                  <path d="M10.36128,4.21488 L0.86256,4.21488 C0.42576,4.21488 0.07104,3.86064 0.07104,3.42384 C0.07104,2.98704 0.42576,2.63232 0.86256,2.63232 L10.36128,2.63232 C10.79808,2.63232 11.15232,2.98704 11.15232,3.42384 C11.15232,3.86256 10.8,4.21488 10.36128,4.21488 L10.36128,4.21488 Z" id="Fill-2"></path>
                                  <path d="M8.51808,7.21104 L4.11936,7.21104 C3.68256,7.21104 3.32832,6.8568 3.32832,6.42 C3.32832,5.9832 3.68256,5.62896 4.11936,5.62896 L8.51808,5.62896 C8.95488,5.62896 9.3096,5.9832 9.3096,6.42 C9.3096,6.8568 8.95488,7.21104 8.51808,7.21104 L8.51808,7.21104 Z" id="Fill-3"></path>
                                  <path d="M10.23744,9.9168 L7.74768,9.9168 C7.3104,9.9168 6.95616,9.56256 6.95616,9.12576 C6.95616,8.68896 7.3104,8.33424 7.74768,8.33424 L10.23744,8.33424 C10.67424,8.33424 11.02896,8.68896 11.02896,9.12576 C11.0304,9.56256 10.67424,9.9168 10.23744,9.9168 L10.23744,9.9168 Z" id="Fill-4"></path>
                                  <path d="M34.94832,4.21488 L25.4496,4.21488 C25.01232,4.21488 24.65808,3.86064 24.65808,3.42384 C24.65808,2.98704 25.01232,2.63232 25.4496,2.63232 L34.94832,2.63232 C35.38512,2.63232 35.73936,2.98704 35.73936,3.42384 C35.73936,3.86256 35.38512,4.21488 34.94832,4.21488 L34.94832,4.21488 Z" id="Fill-5"></path>
                                  <path d="M31.69104,7.21104 L27.29232,7.21104 C26.85552,7.21104 26.50128,6.8568 26.50128,6.42 C26.50128,5.9832 26.85552,5.62896 27.29232,5.62896 L31.69104,5.62896 C32.12832,5.62896 32.48256,5.9832 32.48256,6.42 C32.48448,6.8568 32.12832,7.21104 31.69104,7.21104 L31.69104,7.21104 Z" id="Fill-6"></path>
                                  <path d="M28.0632,9.9168 L25.57296,9.9168 C25.13616,9.9168 24.78192,9.56256 24.78192,9.12576 C24.78192,8.68896 25.13616,8.33424 25.57296,8.33424 L28.0632,8.33424 C28.50192,8.33424 28.85424,8.68896 28.85424,9.12576 C28.85616,9.56256 28.50192,9.9168 28.0632,9.9168 L28.0632,9.9168 Z" id="Fill-7"></path>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    )
  }
}

StatusIcon.propTypes = {
  style: PropTypes.object.isRequired
};

export default StatusIcon;