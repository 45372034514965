exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/inputs.scss"), "");
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss"), "");

// Module
exports.push([module.id, ".AddRecipinetProfile_modal__qcnp_ {\n  background: #FFFFFF;\n  border: 1px solid #D0D0D0;\n  box-shadow: 0 2px 10px 0 rgba(151, 151, 151, 0.3);\n  border-radius: 5px;\n  padding: 30px 40px;\n  min-width: 300px;\n  max-width: 444px;\n  margin: auto; }\n\n.AddRecipinetProfile_modalHeading__10IQ3 {\n  font-weight: 700;\n  font-size: 21px;\n  color: #000000;\n  margin-bottom: 20px; }\n\n.AddRecipinetProfile_warning__3Ht3g {\n  font-size: 12px;\n  color: #EB8C01;\n  line-height: 22px;\n  margin: 10px 0 20px; }\n\n.AddRecipinetProfile_input__gSvdc {\n  background: #FFFFFF;\n  color: #2C2C2C;\n  width: 100%;\n  box-sizing: border-box;\n  background: #FFFFFF;\n  border: 1px solid #D8D8D8;\n  font-size: 12px;\n  padding: 10px 12px; }\n  .AddRecipinetProfile_input__gSvdc:hover, .AddRecipinetProfile_input__gSvdc:focus, .AddRecipinetProfile_input__gSvdc:active {\n    font-size: 12px;\n    color: #2C2C2C; }\n\n.AddRecipinetProfile_formControl__BRC22 {\n  position: relative;\n  display: block;\n  margin-bottom: 20px; }\n  .AddRecipinetProfile_formControl__BRC22 span {\n    display: inline-block;\n    font-weight: 700;\n    font-size: 14px;\n    color: #000000;\n    margin-bottom: 5px; }\n\n.AddRecipinetProfile_labelBtn__2WGyN {\n  font-size: 14px;\n  margin-left: 14px;\n  color: #8F9EA6;\n  position: absolute;\n  right: 0; }\n\n.AddRecipinetProfile_disabledInput__lOG4z {\n  background: #F4F4F4;\n  border: 1px solid #D8D8D8; }\n\n.AddRecipinetProfile_modalSbt__1MSrc {\n  display: block;\n  width: 100%;\n  margin: 35px 0; }\n  .AddRecipinetProfile_modalSbt__1MSrc:disabled {\n    cursor: not-allowed;\n    background: #7F7F7F; }\n\n.AddRecipinetProfile_modalClose__H4EIw {\n  font-size: 14px;\n  margin-bottom: 35px;\n  color: #8F9EA6; }\n\n.AddRecipinetProfile_btnsBox__l_AlU {\n  text-align: center; }\n\n.AddRecipinetProfile_selectLoader__28s82 {\n  text-align: center; }\n", ""]);

// Exports
exports.locals = {
	"modal": "AddRecipinetProfile_modal__qcnp_",
	"modalHeading": "AddRecipinetProfile_modalHeading__10IQ3",
	"warning": "AddRecipinetProfile_warning__3Ht3g",
	"input": "AddRecipinetProfile_input__gSvdc " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/inputs.scss").locals["input"] + "",
	"formControl": "AddRecipinetProfile_formControl__BRC22",
	"labelBtn": "AddRecipinetProfile_labelBtn__2WGyN " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss").locals["link"] + "",
	"disabledInput": "AddRecipinetProfile_disabledInput__lOG4z AddRecipinetProfile_input__gSvdc " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/inputs.scss").locals["input"] + "",
	"modalSbt": "AddRecipinetProfile_modalSbt__1MSrc " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss").locals["cartBtn"] + "",
	"modalClose": "AddRecipinetProfile_modalClose__H4EIw " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss").locals["link"] + "",
	"btnsBox": "AddRecipinetProfile_btnsBox__l_AlU",
	"selectLoader": "AddRecipinetProfile_selectLoader__28s82"
};