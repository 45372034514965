exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/layout.scss"), "");
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss"), "");

// Module
exports.push([module.id, ".Footer_root__2pPqB {\n  margin-top: 16px; }\n  @media (min-width: 768px) {\n    .Footer_root__2pPqB {\n      background: #ffffff;\n      margin-top: 36px; } }\n  .Footer_root__2pPqB.Footer_contact__hK7NA {\n    margin: 0; }\n\n.Footer_container__188QZ {\n  position: relative; }\n\n.Footer_footer__7dIj9 {\n  display: flex;\n  align-content: center;\n  align-items: center;\n  justify-content: space-between;\n  padding: 46px 0; }\n\n.Footer_nav__2J_wh {\n  flex: 1 1 auto;\n  width: 100%;\n  display: flex;\n  align-content: center;\n  align-items: center;\n  justify-content: space-between; }\n  @media (min-width: 768px) {\n    .Footer_nav__2J_wh {\n      justify-content: flex-start; } }\n\n.Footer_navItem__3g3HX {\n  text-transform: uppercase;\n  color: #353B3E;\n  font-size: 12px;\n  display: inline-block; }\n  @media (min-width: 768px) {\n    .Footer_navItem__3g3HX {\n      font-size: 16px; }\n      .Footer_navItem__3g3HX + .Footer_navItem__3g3HX {\n        margin-left: 50px; } }\n\n.Footer_navLogo__wCrtv {\n  display: none; }\n  @media (min-width: 768px) {\n    .Footer_navLogo__wCrtv {\n      flex: 0 0 auto;\n      display: inline-block;\n      cursor: pointer;\n      max-width: 162px; } }\n\n.Footer_logoImg__2TAek {\n  width: 100%; }\n\n.Footer_lang__TrYpw {\n  margin-right: 40px; }\n", ""]);

// Exports
exports.locals = {
	"root": "Footer_root__2pPqB",
	"contact": "Footer_contact__hK7NA",
	"container": "Footer_container__188QZ " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/layout.scss").locals["container"] + "",
	"footer": "Footer_footer__7dIj9",
	"nav": "Footer_nav__2J_wh",
	"navItem": "Footer_navItem__3g3HX " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss").locals["link"] + "",
	"navLogo": "Footer_navLogo__wCrtv",
	"logoImg": "Footer_logoImg__2TAek",
	"lang": "Footer_lang__TrYpw"
};