import React, { Component } from 'react';

class NotFound extends Component {
  render() {
    const { style } = this.props;

    return (
      <div className={style.root}>
        NotFound
      </div>
    )
  }
}

NotFound.defaultProps = {
  style: require('./NotFound.module.scss')
}

export default NotFound;