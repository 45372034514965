import axios from 'axios';

export const apiFetchProduct = (productId, token) => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'catalog/product/'+productId,
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}