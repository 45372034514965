import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'components/Loader';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

class Manager extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  state = {
    isOpen: false,
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isOpen: false
      });
    }
  }

  render() {
    const { style, manager, intl } = this.props;
    const { isOpen } = this.state;

    return (
      !_.isEmpty(manager)
        ? <button ref={this.setWrapperRef} className={classNames(style.manager, {[style.open]: isOpen})} onClick={this.handleToggle}>
            <div className={style.managerThumb}>
              <img 
                src={`${process.env.REACT_APP_IMAGE_PATH}managers/${manager.path}`}
                alt={
                  intl.formatMessage(
                    {id: 'Manager.ALT'}, 
                    { name: manager.name }
                  )
                } 
              />
            </div>
            <div className={style.managerInfo}>
              <div className={style.managerName}>
                {manager.name}
                <div className={style.managerToggle}><FontAwesomeIcon icon={isOpen ? 'caret-up' : 'caret-down'} /></div>
              </div>
              <div className={style.managerText}>
                <FormattedMessage id="Manager.TITLE" />
              </div>
            </div>
            {isOpen && 
              <div className={style.toggleBox}>
                {manager.phones.map((phone, index) => <div key={`mphone_${index}`} className={style.managerBoxItem}><a className={style.managerLink} href={`tel:${phone.replace(/ /g,'')}`}>{phone}</a></div>)}
              </div>
            }
          </button>
        : <div className={style.loader}><Loader /></div>
    )
  }
}

Manager.defaultProps = {
  style: require('./Manager.module.scss')
};

Manager.propTypes = {
  intl: PropTypes.object.isRequired,
  manager: PropTypes.object.isRequired,
};

export default Manager;