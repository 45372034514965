import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/Loader';

import Logo from 'shared/img/logo_big.png';


class LoginSms extends Component {

  state = {
    sms: '',
    showResend: false,
    timeout: 3000
  }

  componentDidMount(){
    this.showResend();
  }

  showResend(){
    setTimeout(() => this.setState({showResend: true}), this.state.timeout);
  }

  prepareSms(sms){
    return sms.replace(/[^\d]/g, '');
  }

  handleLogin = () => {
    const { user, setNotification, userToken, loginModer } = this.props;
    const { sms } = this.state;
    if (sms){
      this.setState({
        sms: ''
      });
      userToken(user.phone, this.prepareSms(sms), loginModer);
    }else{
      setNotification('loginSmsRequired');
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleLogin();
    }
  }

  handleSms = e => {
    this.setState({
      sms: e.target.value
    });
  }

  handleResendSms = () => {
    const { user, userSms } = this.props;
    userSms(user.phone);
  }

  handleResetSms = () => {
    this.props.userSmsReset();
  }

  render() {
    const { sms, showResend } = this.state;
    const { style, user } = this.props;

    return (
      <div className={style.root}>
        <Link className={style.logoBtn} to='/' onClick={this.handleLogo}>
          <img src={Logo} alt='Sparta лого' className={style.logoImg} />
        </Link>
        <div className={style.html}>
          <h2><FormattedMessage id="Login.PASSWORD" /></h2>
          <p><FormattedMessage id="Login.PASSWORD_INFO" values={{ phone: user.phone }}/></p>
        </div>
        <MaskedInput
          className={style.telInputMask}
          mask={[/\d/,/\d/,' ',/\d/,/\d/,' ',/\d/,/\d/]}
          placeholderChar='_'
          onChange={this.handleSms} 
          onKeyDown={this.handleKeyDown}
          value={sms}
          autoFocus={true}
          type='tel'
          keepCharPositions={true}
        />
        <button className={style.btn} onClick={this.handleLogin} disabled={!sms.length}>
          <FormattedMessage id="App.NEXT" />
        </button>
        <div className={style.loader}>
          <Loader />
        </div>
        {showResend 
          ? <div className={style.text}>
              <FormattedMessage id="Login.SMS" 
                values={{ 
                  link1: <button className={style.link} onClick={this.handleResendSms}><FormattedMessage id="Login.RESEND" /></button>,
                  link2: <button className={style.link} onClick={this.handleResetSms}><FormattedMessage id="Login.NEW_NUMBER" /></button> 
                }} 
              />
            </div>
          : <div className={style.text}><FormattedMessage id="Login.SMS_WAIT" /></div>
        }
      </div>
    )
  }
}

LoginSms.defaultProps = {
  style: require('./LoginSms.module.scss')
};

LoginSms.propTypes = {
  setNotification: PropTypes.func.isRequired, 
  userSms: PropTypes.func.isRequired,
  userSmsReset: PropTypes.func.isRequired,
  userToken: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  loginModer: PropTypes.bool
};

export default LoginSms;