import { combineReducers } from 'redux';

import notification from 'components/Notification/reducers/notificationReducer';
import categories from 'components/Categories/reducers/categoriesReducer';
import user from 'components/User/reducers/userReducer';
import product from 'components/Product/reducers/productReducer';
import cart from 'components/Cart/reducers/cartReducer';
import checkout from 'components/Checkout/reducers/checkoutReducer';
import orders from 'components/Orders/reducers/ordersReducer';
import lang from 'components/Lang/reducers/langReducer';

export default combineReducers({
  lang,
  notification,
  categories,
  user,
  product,
  cart,
  checkout,
  orders
});