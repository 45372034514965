export const STATUSES = [
  {
    'id': 'Start',
    'name': 'Start',
    'style': {
      'fill': '#7ED321',
      'stroke': '#5FA11A'
    }
  },
  {
    'id': 'Gold',
    'name': 'Gold',
    'style': {
      'fill': '#F8E71C',
      'stroke': '#C4B616'
    }
  },
  {
    'id': 'Platinum',
    'name': 'Platinum',
    'style': {
      'fill': '#EEEEEE',
      'stroke': '#AEBDC1'
    }
  },
  {
    'id': 'VIP',
    'name': 'VIP',
    'style': {
      'fill': '#4CAFA5',
      'stroke': '#367D76'
    }
  }
];

export const PAYMENTS = {
  ru: {
    'nal': 'Наличными',
    'beznal': 'Банковская карта',
    'forward': 'Наложенный платеж'
  },
  uk: {
    'nal': 'Готівкою',
    'beznal': 'Банківська картка',
    'forward': 'Накладений платіж'
  },
};

export const DELIVERIES = {
  ru: {
    'self': 'Самовывоз',
    'newmail': 'Новая почта',
    'intime': 'In-time',
    'delivery': 'Деливери',
    'courier': 'Доставка курьером по Одессе',
  },
  uk: {
    'self': 'Самовивіз',
    'newmail': 'Нова Пошта',
    'intime': 'In-time',
    'delivery': 'Делівері',
    'courier': 'Кур\'єром по Одесі.',
  }
};

export const BALANCE_STATUSES = {
  ru: {
    'nal': 'Наличными',
    'order': 'Заказ #{id}',
    'return': 'Возврат #{id}',
    'skidka': 'Скидка'
  },
  uk: {
    'nal': 'Готівкою',
    'order': 'Замовлення #{id}',
    'return': 'Повернення #{id}',
    'skidka': 'Знижка'
  }
};