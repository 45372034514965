import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MaskedInput from 'react-text-mask';
import { FormattedMessage } from 'react-intl';
import { preparePhone } from 'utils/services';
import Select from 'components/Select';
import Modal from 'components/Modal';

import { PAYMENTS, DELIVERIES } from 'components/Cabinet/constants';

class AddRecipinetProfile extends Component {
  state = {
    submitDisabled: true,
    formData: {},
    required: []
  }

  componentDidMount() {
    this.setState({
      formData: this.props.formData
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.citiesDelivery.includes(prevState.formData.delivery)){
      return {
        required: ['name', 'phone', 'payment', 'delivery', 'city', 'warehouse']
      };
    }else if (nextProps.areaDelivery.includes(prevState.formData.delivery)){
      return {
        required: ['name', 'phone', 'payment', 'delivery', 'area', 'city', 'warehouse']
      };
    }else if (nextProps.addressDelivery.includes(prevState.formData.delivery)){
      return {
        required: ['name', 'phone', 'payment', 'delivery', 'address']
      };
    }else{
      return {
        required: ['name', 'phone', 'payment', 'delivery']
      };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { userToken, areaDelivery, citiesDelivery, area, city, warehouse, getDeliveryArea, getDeliveryCity, getDeliveryWarehouse } = this.props;
    const { formData, submitDisabled, required } = this.state;

    if (submitDisabled && required){
      const checkRequired = _.reduce(formData, function(result, value, key) {
        return value.length ? [...result, key] : result;
      }, []);

      const checkEqual = _.isEqual(_.sortBy(required), _.sortBy(checkRequired));

      checkEqual && this.setState({
        submitDisabled: false
      });
    }else if (!submitDisabled && !prevState.submitDisabled){
      this.setState({
        submitDisabled: true
      });
    }

    if (areaDelivery.includes(formData.delivery) && !area[formData.delivery]){
      getDeliveryArea(formData.delivery, userToken);
    }else if (areaDelivery.includes(formData.delivery) && !city[formData.delivery] && formData.delivery && formData.area){
      getDeliveryCity({
        delivery: formData.delivery,
        area: formData.area
      }, userToken);
    }

    if (citiesDelivery.includes(formData.delivery) && !city[formData.delivery]){
      getDeliveryCity({
        delivery: formData.delivery
      }, userToken);
    }

    if ((citiesDelivery.includes(formData.delivery) || areaDelivery.includes(formData.delivery)) && formData.city && _.isEmpty((warehouse[formData.delivery]||[])[formData.city])){
      getDeliveryWarehouse(formData, userToken);
    }

    if (prevState.formData.delivery !== formData.delivery){
      this.setState({
        formData: _.omit(formData, ['city', 'warehouse', 'address'])
      });
    }else if (prevState.formData.city !== formData.city){
      this.setState({
        formData: _.omit(formData, ['warehouse', 'address'])
      });
    }
  }

  handleSubmit = () => {
    this.props.addUserRecipinet(this.state.formData, this.props.userToken);
    this.setState({
      formData: this.props.formData
    });
  }

  handleUserInput = e => {
    const targetName = e.target.name;

    if (targetName === 'phone'){
      e.target.value = preparePhone(e.target.value);
    }

    this.setState({
      formData: {
        ...this.state.formData,
        ...{[targetName]: e.target.value}
      }
    });
  }

  handlePayment = selected => {
    this.setState({
      formData: {
        ...this.state.formData,
        ...{payment: selected.value}
      }
    });
  }

  handleDelivery = selected => {
    this.setState({
      formData: {
        ...this.state.formData,
        ...{delivery: selected.value}
      }
    });
  }

  handleArea = selected => {
    this.setState({
      formData: {
        ...this.state.formData,
        ...{area: selected.value}
      }
    });
  }

  handleCity = selected => {
    this.setState({
      formData: {
        ...this.state.formData,
        ...{city: selected.value}
      }
    });
  }

  handleWarehouse = selected => {
    this.setState({
      formData: {
        ...this.state.formData,
        ...{warehouse: selected.value}
      }
    });
  }

  render() {
    const { style, addRecipinetProfileIsOpen, toggleAddRecipinetProfile, area, city, warehouse, intl, currentLocale } = this.props;
    const { formData, submitDisabled } = this.state

    return (
      <Modal
        isOpen={addRecipinetProfileIsOpen}
        onRequestClose={toggleAddRecipinetProfile}
        content={
          <div className={style.modal}>
            <div className={style.modalHeading}><FormattedMessage id="Profile.RECIPIENT_PAYMENT_ADD" /></div>
            <div className={style.form}>
              <label className={style.formControl}>
                <span><FormattedMessage id="Profile.RECIPIENT_FULLNAME" /></span>
                <input className={style.input} value={formData.name} name='name' onChange={this.handleUserInput} />
              </label>
              <label className={style.formControl}>
                <span><FormattedMessage id="Profile.RECIPIENT_PHONE_ADD" /></span>
                <MaskedInput
                  className={style.input}
                  mask={[/\d/,/\d/,/\d/,' ',/\d/,/\d/,/\d/,' ',/\d/,/\d/,' ',/\d/,/\d/]}
                  placeholderChar='_'
                  onChange={this.handleUserInput} 
                  name='phone'
                  keepCharPositions={true}
                />
              </label>
              <label className={style.formControl}>
                <span><FormattedMessage id="Profile.RECIPIENT_PAYMENT" /></span>
                <Select 
                  options={_.map(PAYMENTS[currentLocale], (item, value) => {
                      return {
                        label: item,
                        value: value
                      }
                    })
                  }
                  selected={formData.payment}
                  placeholder={intl.formatMessage({id: 'Profile.RECIPIENT_PAYMENT_CHOSE'})}
                  noOptionsMessage=''
                  onChange={this.handlePayment}
                  isSearchable={false}
                />
              </label>
              <label className={style.formControl}>
                <span><FormattedMessage id="Profile.RECIPIENT_DELEVERY" /></span>
                <Select 
                  options={_.map(DELIVERIES[currentLocale], (item, value) => {
                      return {
                        label: item,
                        value: value,
                        textValue: item
                      }
                    })
                  }
                  selected={formData.delivery}
                  placeholder={intl.formatMessage({id: 'Profile.RECIPIENT_DELEVERY_CHOSE'})}
                  noOptionsMessage=''
                  onChange={this.handleDelivery}
                  isSearchable={false}
                />
              </label>
              {formData.delivery === 'courier' && 
                <label className={style.formControl}>
                  <span><FormattedMessage id="Profile.RECIPIENT_ADDRESS" /></span>
                  <textarea className={style.input} name='address' onChange={this.handleUserInput} value={formData.address}></textarea>
                </label>
              }
              {area && !_.isEmpty(area[formData.delivery]) &&
                <label className={style.formControl}>
                  <span><FormattedMessage id="Profile.RECIPIENT_AREA" /></span>
                  <Select 
                    options={_.map(area[formData.delivery], item => {
                        return {
                          label: item.name,
                          value: item.ref,
                          textValue: item.name
                        }
                      })
                    }
                    selected={null}
                    placeholder={intl.formatMessage({id: 'Profile.RECIPIENT_AREA_CHOSE'})}
                    noOptionsMessage=''
                    onChange={this.handleArea}
                  />
                </label>
              }
              {city && !_.isEmpty(city[formData.delivery]) &&
                <label className={style.formControl}>
                  <span><FormattedMessage id="Profile.USER_CITY" /></span>
                  <Select 
                    options={_.map(city[formData.delivery], item => {
                        return {
                          label: item.name,
                          value: item.name,
                          textValue: item.name
                        }
                      })
                    }
                    selected={null}
                    placeholder={intl.formatMessage({id: 'Profile.USER_CITY_CHOSE'})}
                    noOptionsMessage=''
                    onChange={this.handleCity}
                  />
                </label>
              }
              {warehouse && !_.isEmpty(warehouse[formData.delivery]) && !_.isEmpty(warehouse[formData.delivery][formData.city]) &&
                <label className={style.formControl}>
                  <span><FormattedMessage id="Profile.RECIPIENT_WAREHOUSE_CARGO" /></span>
                  <Select 
                    options={_.map(warehouse[formData.delivery][formData.city], item => {
                        return {
                          label: item.warehouse,
                          value: item.warehouse,
                          textValue: item.warehouse
                        }
                      })
                    }
                    selected={null}
                    placeholder={intl.formatMessage({id: 'Profile.RECIPIENT_WAREHOUSE_CHOSE'})}
                    noOptionsMessage=''
                    onChange={this.handleWarehouse}
                  />
                </label>
              }
              <div className={style.btnsBox}>
                <button className={style.modalSbt} onClick={this.handleSubmit} disabled={submitDisabled} ><FormattedMessage id="App.ADD" /></button>
                <button className={style.modalClose} onClick={toggleAddRecipinetProfile}><FormattedMessage id="App.CHANGE_CANCEL" /></button>
              </div>
            </div>
          </div>
        }
      />
    );
  }
}

AddRecipinetProfile.defaultProps = {
  style: require('./AddRecipinetProfile.module.scss'),
  formData: {
    name: '',
    phone: '',
    payment: '',
    delivery: ''  
  },
  areaDelivery: ['newmail'],
  citiesDelivery: ['intime', 'delivery'],
  addressDelivery: ['courier']
};

AddRecipinetProfile.propTypes = {
  intl: PropTypes.object.isRequired,
  addRecipinetProfileIsOpen: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  toggleAddRecipinetProfile: PropTypes.func.isRequired,
  getDeliveryArea: PropTypes.func.isRequired,
  getDeliveryCity: PropTypes.func.isRequired,
  getDeliveryWarehouse: PropTypes.func.isRequired,
  addUserRecipinet: PropTypes.func.isRequired,
  userToken: PropTypes.string.isRequired,
  currentLocale: PropTypes.string.isRequired,
  area: PropTypes.object.isRequired,
  city: PropTypes.object.isRequired,
  warehouse: PropTypes.object.isRequired
};

export default AddRecipinetProfile;