import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import qs from 'qs';

import LoginSolutions from './components/LoginSolutions';
import LoginForm from './components/LoginForm';
import LoginSms from './components/LoginSms';
import RegisterForm from './components/RegisterForm';

// import LangSwitcher from 'components/Lang/components/LangSwitcher';
import Notification from 'components/Notification';

class Login extends Component {
  render() {
    const { style, userToken, userSentSms, userNotRegister, currentLocale, intl } = this.props;
    const { pathname } = this.props.location;

    const loginModer = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).login_moder 
                        ? qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).login_moder === '1' 
                        : false;

    if (userToken) {
      return <Redirect to="/" />;
    }

    const timeout = { enter: 300, exit: 200 };

    return (
      <div className={style.root}>
        <Notification />
        <div className={style.bg}></div>
        <div className={style.container}>
          {/* <div className={style.lang}>
            <LangSwitcher />
          </div> */}
          {pathname === '/login-form'
            ? <TransitionGroup>
                {!userSentSms
                  ? userNotRegister
                      ? <CSSTransition key={'RegisterForm'} timeout={timeout} classNames='fade' appear>
                          <RegisterForm intl={intl} />
                        </CSSTransition>
                      : <CSSTransition key={'loginForm'} timeout={timeout} classNames='fade' appear>
                          <LoginForm loginModer={loginModer} />
                        </CSSTransition>
                  : <CSSTransition key={'loginSms'} timeout={timeout} classNames='fade' appear>
                      <LoginSms loginModer={loginModer} />
                    </CSSTransition>
                }
              </TransitionGroup>
            : <LoginSolutions currentLocale={currentLocale} />
          }
        </div>
      </div>
    )
  }
}

Login.defaultProps = {
  style: require('./Login.module.scss')
};

Login.propTypes = {
  intl: PropTypes.object.isRequired,
  userSentSms: PropTypes.bool.isRequired,
  userNotRegister: PropTypes.bool.isRequired,
  currentLocale: PropTypes.string.isRequired,
  userToken: PropTypes.string
}

export default Login;