import React, { Component } from 'react';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Spiner from 'react-loader-spinner';

class Loader extends Component {
  render() {
    return (
      <Spiner
        type='ThreeDots'
        color='#EB8C01'
        height={50}
      />
    )
  }
}

export default Loader;