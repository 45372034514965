exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss"), "");
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/inputs.scss"), "");

// Module
exports.push([module.id, ".BalanceSms_root__2w0tx {\n  text-align: center; }\n\n.BalanceSms_text__23Z3U {\n  display: block;\n  color: #2C2C2C;\n  font-size: 18px;\n  margin-bottom: 5px; }\n\n.BalanceSms_textSm__wdKmh {\n  font-size: 14px;\n  margin: 0 auto; }\n\n.BalanceSms_btn__1nyXx {\n  color: #2C2C2C;\n  margin: 20px auto; }\n  .BalanceSms_btn__1nyXx:hover {\n    color: #ffffff; }\n\n.BalanceSms_telInputMask__1UJ3z {\n  width: 100%;\n  max-width: 300px;\n  box-sizing: border-box;\n  margin: 20px auto 8px;\n  text-align: center;\n  background: #ffffff;\n  border-color: #EB8C01;\n  color: #2C2C2C; }\n  .BalanceSms_telInputMask__1UJ3z.BalanceSms_notValid__1Kthr {\n    border-color: #a71d2a;\n    background-color: rgba(220, 53, 69, 0.64); }\n", ""]);

// Exports
exports.locals = {
	"root": "BalanceSms_root__2w0tx",
	"text": "BalanceSms_text__23Z3U",
	"textSm": "BalanceSms_textSm__wdKmh BalanceSms_text__23Z3U",
	"btn": "BalanceSms_btn__1nyXx " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss").locals["btn-border-svg"] + "",
	"telInputMask": "BalanceSms_telInputMask__1UJ3z " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/inputs.scss").locals["input"] + "",
	"notValid": "BalanceSms_notValid__1Kthr"
};