import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CartOrder from './CartOrder';

import { fetchCart } from './../../actions';

import { getSettings } from 'components/User/actions';

const mapStateToProps = state => ({
  currentLocale: state.lang.currentLocale,
  userToken: state.user.token,
  cart: state.cart.cart,
  fetching: state.cart.fetching,
  currency: state.user.user.currency,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    fetchCart,
    getSettings
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CartOrder);
