import { createReducer } from 'redux-act';

import { LOCALES } from './../../../i18n/locales';
import { SET_CURRENT_LOCALE } from './../actions/types';

const LOCALE = localStorage.getItem('locale');

const DEFAULT_STATE = {
  currentLocale: LOCALES[LOCALE] || LOCALES.UKRAINIAN
};

export default createReducer({
  [SET_CURRENT_LOCALE]: (state, payload) => ({
    ...state,
    currentLocale: LOCALES[payload]
  })
}, DEFAULT_STATE);