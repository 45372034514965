import { createAsyncAction, createAction } from 'utils/actions';

export const USER = createAsyncAction('USER');
export const USER_RESET = createAction('USER_RESET');
export const USER_NOT_REGISTER = createAction('USER_NOT_REGISTER');
export const UNSET_USER_NOT_REGISTER = createAction('UNSET_USER_NOT_REGISTER');
export const TOGGLE_ADD_RECIPIENT_PROFILE = createAction('TOGGLE_ADD_RECIPIENT_PROFILE');
export const TOGGLE_CHANGE_PROFILE = createAction('TOGGLE_CHANGE_PROFILE');
export const USER_SMS = createAsyncAction('USER_SMS');
export const USER_TOKEN = createAsyncAction('USER_TOKEN');
export const USER_EDIT = createAsyncAction('USER_EDIT');
export const USER_RECIPIENTS = createAsyncAction('USER_RECIPIENTS');
export const USER_STATISTIC = createAsyncAction('USER_STATISTIC');
export const USER_BALANCE = createAsyncAction('USER_BALANCE');
export const ADD_USER_RECIPIENT = createAsyncAction('ADD_USER_RECIPIENT');
export const GET_DELIVERY_WAREHOUSE = createAsyncAction('GET_DELIVERY_WAREHOUSE');
export const GET_DELIVERY_CITY = createAsyncAction('GET_DELIVERY_CITY');
export const GET_DELIVERY_AREA = createAsyncAction('GET_DELIVERY_AREA');
export const GET_PARTNERS_BLANKS = createAsyncAction('GET_PARTNERS_BLANKS');
export const GET_SETTINGS = createAsyncAction('GET_SETTINGS');
export const USER_REGISTER = createAsyncAction('USER_REGISTER');
export const FETCH_BALANCE_GRID = createAsyncAction('FETCH_BALANCE_GRID');
export const USER_BALANCE_SMS = createAsyncAction('USER_BALANCE_SMS');
export const USER_BALANCE_LOGIN = createAsyncAction('USER_BALANCE_LOGIN');
export const GET_BANNERS = createAsyncAction('GET_BANNERS');