import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getPrice, getCurrency } from 'utils/services';
import Select from 'components/Select';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/Loader';
import AddRecipinetProfile from 'components/Cabinet/components/AddRecipinetProfile';

import { 
  saveToLocalStorage,
  removeFromLocalStorage
} from 'components/User/services';

class CartConfirm extends Component {
  state = {
    recipientId: null,
    disabledConfirm: false
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if ((!prevState.recipient && !_.isEmpty(nextProps.userRecipients))){
      return {
        recipientId: nextProps.userRecipients[0].id
      };
    }

    return null;
  }

  componentDidMount() {
    const { userRecipients, userToken, getUserRecipients } = this.props;

    if (_.isEmpty(userRecipients)){
      getUserRecipients(userToken);
    }
  }

  handleComment = e => {
    saveToLocalStorage('cartComment', e.target.value);
  }

  handleChangeComment = e => {
    this.props.addCartComment(e.target.value);
  }

  handleConfirm = () => {
    const { userToken, addCheckout, cartComment } = this.props;
    const { recipientId } = this.state;
    removeFromLocalStorage('cartComment');
    addCheckout(recipientId, cartComment, userToken);
  }

  handleChange = selected => {
    if (selected && selected.value){
      this.setState({recipientId: selected.value});
    }
  }

  render() {
    const { style, cart, currency, userRecipients, fetchingUserRecipients, fetchingCheckout, toggleAddRecipinetProfile, cartComment, intl, currentLocale } = this.props;

    return (
      <div className={style.root}>
        <AddRecipinetProfile />
        <div className={style.total}>
          <FormattedMessage id="CartConfirm.TOTAL" />
          <div className={style.price}>
            { getPrice(cart.amount, 2) } {getCurrency(currency, currentLocale)}
          </div>
        </div>
        <div className={style.info}>
          <div className={style.text}>
            <FormattedMessage id="CartConfirm.INFO" />
          </div>
          <div className={style.comment}>
            <textarea className={style.commentTextarea} onBlur={this.handleComment} onChange={this.handleChangeComment} value={ cartComment }></textarea>
          </div>
          <div className={style.select}>
            <label className={style.label}>
              {_.isEmpty(userRecipients) && !fetchingUserRecipients
                ? <div className={style.empty}>
                    <button className={style.emptyBtn} onClick={toggleAddRecipinetProfile}>
                      <FormattedMessage id="CartConfirm.RECIPIENT_ADD" />
                    </button>
                  </div>
                : <Select
                    options={
                      userRecipients.map(item => {
                        const label = <div className={style.selectOption}><strong>{item.name}</strong>, <span>{item.phone}, {item.city}, {item.warehouse}</span></div>
                        return {
                          label: label,
                          value: item.id,
                          textValue: `${item.name}, ${item.phone}, ${item.city}, ${item.warehouse}`
                        }
                      }) 
                    }
                    placeholder={intl.formatMessage({id: 'CartConfirm.RECIPIENT_CHOSE'})}
                    noOptionsMessage={intl.formatMessage({id: 'CartConfirm.RECIPIENT_EMPTY'})}
                    onChange={this.handleChange}
                  />
              }
            </label>
          </div>
          <div className={style.btnWrapper}>
            {fetchingCheckout
              ? <div className={style.btnLoaderOrder}><Loader /></div>
              : <button className={style.btnOrder} onClick={this.handleConfirm}><FormattedMessage id="CartConfirm.CONFIRM" /></button>
            }
          </div>
        </div>
      </div>
    )
  }
}

CartConfirm.defaultProps = {
  style: require('./CartConfirm.module.scss')
}

CartConfirm.propTypes = {
  intl: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  decimals: PropTypes.number.isRequired,
  userToken: PropTypes.string.isRequired,
  currentLocale: PropTypes.string.isRequired,
  userRecipients: PropTypes.array.isRequired,
  getUserRecipients: PropTypes.func.isRequired,
  addCheckout: PropTypes.func.isRequired,
  fetchingCheckout: PropTypes.bool.isRequired,
  fetchingUserRecipients: PropTypes.bool.isRequired,
  toggleAddRecipinetProfile: PropTypes.func.isRequired,
  addCartComment: PropTypes.func.isRequired,
  cartComment: PropTypes.string
};

export default CartConfirm;