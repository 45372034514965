import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Statistic from './Statistic';
import { getUserStatistic } from 'components/User/actions';

const mapStateToProps = (state) => ({
  user: state.user.user,
  userStatistic: state.user.statistic,
  userToken: state.user.token,
  currentLocale: state.lang.currentLocale,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    getUserStatistic
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Statistic);
