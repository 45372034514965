import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ListProducts from './ListProducts';

import { 
  changeCart,
  deleteCart
} from 'components/Cart/actions';

const mapStateToProps = state => ({
  decimals: state.user.user.decimals,
  userToken: state.user.token,
  currentLocale: state.lang.currentLocale,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeCart,
    deleteCart
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ListProducts);
