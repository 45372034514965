import React, { Component } from 'react';
import classNames from 'classnames';
import { NavLink, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// import LangSwitcher from 'components/Lang/components/LangSwitcher';

import Logo from 'shared/img/logo.png';

class Footer extends Component {
  render() {
    const { style, pathname } = this.props;

    return(
      <div className={classNames(style.root, {[style[pathname.replace('/', '')]]: true})}>
        <div className={style.container}>
          <footer className={style.footer}>
            <nav className={style.nav}>
              <NavLink className={style.navItem} activeClassName={style.active} to='/about'>
                <FormattedMessage id="App.ABOUT" />
              </NavLink>
              <NavLink className={style.navItem} activeClassName={style.active} to='/delivery'>
                <FormattedMessage id="App.DELEVERY_PAYMENT" />
              </NavLink>
              <NavLink className={style.navItem} activeClassName={style.active} to='/contact'>
                <FormattedMessage id="App.CONTACT" />
              </NavLink>
            </nav>
            {/* <div className={style.lang}>
              <LangSwitcher />
            </div> */}
            <Link className={style.navLogo} to='/'>
              <img src={Logo} alt='Sparta лого' className={style.logoImg} />
            </Link>
          </footer>
        </div>
      </div>
    )
  }
}

Footer.defaultProps = {
  style: require('./Footer.module.scss')
}

export default Footer;