import React, { Component } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScrollUpButton from "react-scroll-up-button";

import Notification from 'components/Notification';
import Header from 'components/Header';
import Footer from 'components/Footer';

class Layout extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { style, location } = this.props;

    return (
      <div className={style.root}>
        <Notification />
        <Header template={this.props.match?.path === '/partners' ? 'secondary' : 'default'} />
        <this.props.component {..._.omit(this.props, ['style'])} />
        <Footer pathname={location.pathname ? location.pathname : null} />
        <ScrollUpButton
          ContainerClassName={style.toTopContainer}
          TransitionClassName={style.toTopTransition}
          ShowAtPosition={150}
        >
          <FontAwesomeIcon icon='long-arrow-alt-up' />
        </ScrollUpButton>
      </div>
    );
  };
}

Layout.defaultProps = {
  style: require('./Layout.module.scss'),
  maintenance: false
};

export default Layout;