import { connect } from 'react-redux';
import Notification from './Notification';

const mapStateToProps = (state) => ({
  notifications: state.notification.notifications,
  messages: state.notification.messages,
  type: state.notification.type,
  currency: state.user.user.currency,
  currentLocale: state.lang.currentLocale,
});

export default connect(mapStateToProps)(Notification);
