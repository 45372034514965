import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Categories from './Categories';
import { setNotification } from 'components/Notification/actions';
import { 
  toggleCategories,
  fetchCategories,
  fetchArrivals,
  fetchFavorites,
  fetchLovedCategories,
  controlFavorite,
  fetchCategory,
  clearSearchAutocomplete,
  fetchIncomes
} from './actions';

const mapStateToProps = state => ({
  categories: state.categories.categories,
  categoriesFetching: state.categories.fetching,
  arrivals: state.categories.arrivals,
  arrivalsFetching: state.categories.arrivalsFetching,
  arrivalsLimit: state.categories.arrivalsLimit,
  arrivalsEnd: state.categories.arrivalsEnd,
  favorites: state.categories.favorites,
  favoritesFetching: state.categories.favoritesFetching,
  lovedCategories: state.categories.lovedCategories,
  lovedCategoriesFetching: state.categories.lovedCategoriesFetching,
  open: state.categories.open,
  userToken: state.user.token,
  searchAutocomplete: state.categories.category.search,
  incomes: state.categories.incomes,
  incomesFetching: state.categories.incomesFetching,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    toggleCategories,
    fetchCategories,
    fetchArrivals,
    fetchFavorites,
    controlFavorite,
    setNotification,
    fetchLovedCategories,
    fetchCategory,
    clearSearchAutocomplete,
    fetchIncomes
  }, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Categories));
