import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { FormattedMessage } from 'react-intl';
import { preparePhone } from 'utils/services';

import Logo from 'shared/img/logo_big.png';

class LoginForm extends Component {

  state = {
    phone: ''
  }

  handleLogin = () => {
    const { setNotification, userSms, loginModer } = this.props;
    const { phone } = this.state;
    if (phone){
      this.setState({
        phone: ''
      });
      userSms(preparePhone(phone), loginModer);
    }else{
      setNotification('loginPhoneRequired');
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleLogin();
    }
  }

  handleTel = e => {
    this.setState({
      phone: e.target.value
    });
  }

  render() {
    const { phone } = this.state;
    const { style } = this.props;

    return (
      <div className={style.root}>
        <Link className={style.logoBtn} to='/' onClick={this.handleLogo}>
          <img src={Logo} alt='Sparta лого' className={style.logoImg} />
        </Link>
        <div className={style.html}>
          <h2><FormattedMessage id="Login.ENTER" /></h2>
          <p><FormattedMessage id="Login.ENTER_INFO" /> <strong>(067) 123-45-67</strong></p>
        </div>
        <MaskedInput
          className={style.telInputMask}
          mask={[/\d/,/\d/,/\d/,' ',/\d/,/\d/,/\d/,' ',/\d/,/\d/,' ',/\d/,/\d/]}
          placeholderChar='_'
          onChange={this.handleTel} 
          onKeyDown={this.handleKeyDown}
          name='phone'
          type='tel'
          keepCharPositions={true}
        />
        <button className={style.btn} onClick={this.handleLogin} disabled={!preparePhone(phone).length}>
          <FormattedMessage id="App.NEXT" />
        </button>
      </div>
    )
  }
}

LoginForm.defaultProps = {
  style: require('./LoginForm.module.scss')
};

LoginForm.propTypes = {
  setNotification: PropTypes.func.isRequired,
  userSms: PropTypes.func.isRequired,
  loginModer: PropTypes.bool
};

export default LoginForm;