export const STATUSES = {
  ru: {
    'accepted': {
      'name': 'Оформлен',
      'color': '#EB8C01'
    },
    'to_stock0|to_stock6|to_stock7': {
      'name': 'В сборке',
      'color': '#EB8C01'
    },
    'delivered': {
      'name': 'Завершен',
      'color': '#53C34A'
    }
  },
  uk: {
    'accepted': {
      'name': 'Оформлено',
      'color': '#EB8C01'
    },
    'to_stock0|to_stock6|to_stock7': {
      'name': 'У збиранні', 
      'color': '#EB8C01'
    },
    'delivered': {
      'name': 'Завершено',
      'color': '#53C34A'
    }
  }
};