import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from 'components/Breadcrumbs';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { FormattedMessage } from 'react-intl';

import Statistic from './components/Statistic';
import Profile from './components/Profile';
import Balance from './components/Balance';
import Order from 'components/Orders/components/Order';
import Manager from './components/Manager';
import Catalog from 'components/Catalog';

class Cabinet extends Component {
  state = {
    activeNav: '/',
    params: {},
    hash: null,
    menuIsOpen: false
  };

  components = {
    statistic: Statistic,
    profile: Profile,
    balance: Balance,
    order: Order,
    viewed: Catalog,
    specials: Catalog
  };

  static getDerivedStateFromProps(nextProps, prevState){
    const regex = /(^\#\S+)\/(\S+)/gm;
    let hash = nextProps.location.hash;
    let params, m;

    while ((m = regex.exec(hash)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      
      if (m.length === 3){
        hash = m[1];
        params = m[2];
      }
    }

    return {
      activeNav: nextProps.location.pathname+hash,
      params: {
        orderId: params,
        catId: params
      },
      hash: hash
    };
  }

  navComponent = () => {
    const { activeNav } = this.state;

    const currentKey = activeNav.replace(/\/?#?/m, '') || 'statistic';

    const ActiveComponent = this.components[currentKey];

    let props = {
      match: {
        params: this.state.params
      },
      location: {
        hash: this.state.hash
      },
      hideBreadcrumbs: true
    };

    if (activeNav === '/#viewed') {
      props = {
        ...props,
        handleRemove: this.handleRemoveViewed
      }
    }

    return (
      <TransitionGroup>
        <CSSTransition key={currentKey} timeout={{ enter: 300, exit: 200 }} classNames='fade' appear>
          <ActiveComponent {...props} />
        </CSSTransition>
      </TransitionGroup>
    );
  }

  handleMobileMenu = () => {
    this.setState({
      menuIsOpen: !this.state.menuIsOpen
    });
  }

  handleRemoveViewed = prodcutId => () => {
    const { deleteViewed, userToken } = this.props;
    
    deleteViewed(prodcutId, userToken);
  }

  render() {
    const { style, user, resetUser } = this.props;
    const { activeNav, menuIsOpen } = this.state;

    const breadcrumbs = [<FormattedMessage id="Cabinet.GREETING" values={{ name: user.name ? user.name : '...' }} />];

    const NavComponent = this.navComponent;

    return (
      <div className={style.root}>
        <Breadcrumbs 
          breadcrumbs={breadcrumbs} 
          element={<Manager />}
          classnames={{
            root: style.breadcrumbsRoot,
            breadcrumbs: style.breadcrumbs
          }}
        />
        <nav className={style.nav}>
          <button className={classNames(style.navMobileToggle, {[style.active]: menuIsOpen})} onClick={this.handleMobileMenu}>Меню<FontAwesomeIcon icon={menuIsOpen ? 'caret-up' : 'caret-down'} /></button>
          <div className={classNames(style.navContainer, {[style.active]: menuIsOpen})}>
            <div className={style.navLeft}>
              <Link className={classNames(style.navItem, {[style.active]: (activeNav === '/') })} to='/'><FormattedMessage id="App.CABINET" /></Link>
              <Link className={classNames(style.navItem, {[style.active]: (activeNav === '/#balance' || activeNav === '/#balance') })} to='/#balance'><FormattedMessage id="App.BALANCE" /></Link>
              <Link className={classNames(style.navItem, {[style.active]: (activeNav === '/#viewed') })} to='/#viewed'><FormattedMessage id="App.VIEW_HISTORY" /></Link>
              {/* <Link className={style.navItem} to='/#testimonials'>РЕКОМЕНДАЦИИ</Link> */}
              <Link className={classNames(style.navItem, {[style.active]: (activeNav === '/#specials') })} to='/#specials'><FormattedMessage id="App.SPECIAL" /></Link>
            </div>
            <div className={style.navRight}>
              <Link className={classNames(style.navItem, {[style.active]: (activeNav === '/#profile') })} to='/#profile'><FormattedMessage id="App.PROFILE" /></Link>
              <button className={style.navItem} onClick={resetUser}><FormattedMessage id="App.EXIT" /></button>
            </div>
          </div>
        </nav>
        <div className={style.container}>
          <div className={style.wrapperComponent}>
            <NavComponent />
          </div>
        </div>
      </div>
    )
  }
}

Cabinet.defaultProps = {
  style: require('./Cabinet.module.scss')
};

Cabinet.propTypes = {
  user: PropTypes.object.isRequired,
  resetUser: PropTypes.func.isRequired,
  deleteViewed: PropTypes.func
};

export default Cabinet;