import { createReducer } from 'redux-act';
import _ from 'lodash';

import { 
  SET_NOTIFICATION, 
  SET_VALIDATION 
} from './../actions/types';

const DEFAULT_STATE = {
  messages: {
    ru: {
      'error': 'Упс. Что-то пошло не так, пожалуйста свяжитесь с нами!',
      'loginPhoneRequired': 'Заполните номер телефона!',
      'loginSmsRequired': 'Заполните пароль!',
      'The code must be {replace} characters.': 'Заполните пароль!',
      'The phone must be {replace} characters.': 'Заполните номер телефона!',
      'User sms limit': 'Превышено число авторизации, юзер заблокирован, пожалуйста свяжитесь с нами!',
      'User with phone number {replace} not found.': 'Мы не видим вас в нашей базе клиентов',
      'USER_SMS.SUCCESS': 'Смс успешно отправлено!',
      'USER_TOKEN.SUCCESS': 'Добро пожаловать!',
      'ADD_CART.SUCCESS': 'Товар успешно добавлен в корзину!',
      'REORDER_ORDER.SUCCESS': 'Заказ успешно добавлен в корзину!',
      'DELETE_ORDER.SUCCESS': 'Заказ успешно удален!',
      'DELETE_VIEWED.SUCCESS': 'Товар успешно удален!',
      'DELETE_ALL_VIEWED.SUCCESS': 'Товары успешно удалены!',
      'Bad code': 'Проверьте пароль, или запросите новый.',
      'messageRequired': 'Сообщение не может быть пустым.',
      'messageSuccess': 'Спасибо! Ваше сообщение успешно отправлено.',
      'ADD_USER_RECIPIENT.SUCCESS': 'Получатель успешно добавлен!',
      'USER_EDIT.SUCCESS': 'Пользователь успешно отредактирован!',
      'The city field is required.': 'Укажите свой город!',
      'The mail field is required.': 'Укажите свою электронную почту!',
      'The phones.{replace} field is required.': 'Укажите свой номер телефона!',
      'The sum must be at least {replace}.': 'Минимальный заказ от {replace} {currency}',
      'The mail must be a valid email address.': 'Укажите свою действительную электронную почту!',
      'The name field is required.': 'Укажите свои имя и фамилию!',
      'The mail has already been taken.': 'Эта почта уже используется!',
      'The phone has already been taken.': 'Этот номер телефона уже используется!',
      'The recipient field is required.': 'Сначала добавьте получателя!',
      'failApiFetchSupercatPrice': 'Что-то не так, пожалуйста, свяжитесь с нами.',
      'failApiFetchArrivalInvoice': 'Что-то не так, пожалуйста, свяжитесь с нами.',
      'User blocked.': 'Что-то пошло не так, пожалуйста, обратитесь к вашему менеджеру'
    },
    uk: {
      'error': 'Упс. Щось пішло не так, будь ласка, зв\'яжіться з нами!',
      'loginPhoneRequired': 'Заповніть номер телефону!',
      'loginSmsRequired': 'Заповніть пароль!',
      'The code must be {replace} characters.': 'Заповніть пароль!',
      'The phone must be {replace} characters.': 'Заповніть номер телефону!',
      'User sms limit': 'Перевищена кількість авторизації, користувач заблокований, будь ласка, зв\'яжіться з нами!',
      'User with phone number {replace} not found.': 'Ми не бачимо вас у нашій базі клієнтів',
      'USER_SMS.SUCCESS': 'Смс успішно надіслано!',
      'USER_TOKEN.SUCCESS': 'Ласкаво просимо!',
      'ADD_CART.SUCCESS': 'Товар успішно доданий до кошика!',
      'REORDER_ORDER.SUCCESS': 'Замовлення успішно додано до кошика!',
      'DELETE_ORDER.SUCCESS': 'Замовлення успішно видалено!',
      'DELETE_VIEWED.SUCCESS': 'Товар успішно видалено!',
      'DELETE_ALL_VIEWED.SUCCESS': 'Товари успішно видалені!',
      'Bad code': 'Перевірте пароль або запитайте новий.',
      'messageRequired': 'Повідомлення не може бути порожнім.',
      'messageSuccess': 'Дякую! Ваше повідомлення успішно надіслано.',
      'ADD_USER_RECIPIENT.SUCCESS': 'Отримувач успішно доданий!',
      'USER_EDIT.SUCCESS': 'Користувач успішно відредаговано!',
      'The city field is required.': 'Вкажіть своє місто!',
      'The mail field is required.': 'Вкажіть свою електронну пошту!',
      'The phones.{replace} field is required.': 'Вкажіть свій номер телефону!',
      'The sum must be at least {replace}.': 'Мінімальне замовлення від {replace} {currency}',
      'The mail must be a valid email address.': 'Вкажіть свою дійсну електронну пошту!',
      'The name field is required.': 'Вкажіть своє ім\'я та прізвище!',
      'The mail has already been taken.': 'Ця пошта вже використовується!',
      'The phone has already been taken.': 'Цей номер телефону вже використовується!',
      'The recipient field is required.': 'Спочатку додайте одержувача!',
      'failApiFetchSupercatPrice': 'Щось не так, будь ласка, зв\'яжіться з нами.',
      'failApiFetchArrivalInvoice': 'Щось не так, будь ласка, зв\'яжіться з нами.',
      'User blocked.': 'Щось пішло не так, будь ласка, зверніться до вашого менеджера'
    },
  },
  notifications: [],
  validations: [],
  type: 'danger'
};

export default createReducer({
  [SET_NOTIFICATION]: (state, payload) => ({
    ...state,
    notifications: _.uniq([payload.msgId]),
    type: payload.type || DEFAULT_STATE.type
  }),
  [SET_VALIDATION]: (state, payload) => ({
    ...state,
    notifications: _.values(payload),
    validations: _.keys(payload),
    type: payload.type || DEFAULT_STATE.type
  }),
}, DEFAULT_STATE);