exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss"), "");

// Module
exports.push([module.id, ".CatalogMenu_secondary__2_zGU .CatalogMenu_btnCatalog__1rPer {\n  background: transparent;\n  border: 1px solid #F77A25; }\n  .CatalogMenu_secondary__2_zGU .CatalogMenu_btnCatalog__1rPer:active, .CatalogMenu_secondary__2_zGU .CatalogMenu_btnCatalog__1rPer:hover {\n    background: linear-gradient(180deg, #FCB24C 0%, #F77A25 100%); }\n  .CatalogMenu_secondary__2_zGU .CatalogMenu_btnCatalog__1rPer::before {\n    background-color: transparent; }\n\n.CatalogMenu_btnCatalog__1rPer { }\n  .CatalogMenu_btnCatalog__1rPer.CatalogMenu_active__1x65M .CatalogMenu_menuIcon__3Rld1 svg, .CatalogMenu_btnCatalog__1rPer:hover .CatalogMenu_menuIcon__3Rld1 svg {\n    color: #FFFFFF; }\n\n.CatalogMenu_menuIcon__3Rld1 {\n  z-index: 1; }\n  .CatalogMenu_menuIcon__3Rld1:hover svg {\n    color: #FFFFFF; }\n", ""]);

// Exports
exports.locals = {
	"secondary": "CatalogMenu_secondary__2_zGU",
	"btnCatalog": "CatalogMenu_btnCatalog__1rPer " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss").locals["btnBorderGradient"] + "",
	"active": "CatalogMenu_active__1x65M",
	"menuIcon": "CatalogMenu_menuIcon__3Rld1 " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-6-3!shared/styles/button.scss").locals["link"] + ""
};