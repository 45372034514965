import axios from 'axios';

export const apiFetchOrders = (token, query = '') => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'order/history'+query,
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiFetchOrder = (orderId, token) => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'order/history/'+orderId,
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiReorderOrder = (orderId, token) => {
  return axios({
    method: 'POST',
    url: process.env.REACT_APP_API_URL+'order/history/'+orderId+'/cart',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}

export const apiDeleteOrder = (orderId, token) => {
  return axios({
    method: 'DELETE',
    url: process.env.REACT_APP_API_URL+'order/history/'+orderId,
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    }
  });
}


export const apiInvoiceOrder = (orderId, token, barcode = false) => {
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL+'catalog/excel/order/sparta/'+orderId+(barcode ? '?barcode=1' : ''),
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+token
    },
    responseType: 'blob',
  });
}