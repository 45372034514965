import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Select';
import _ from 'lodash';

class CatalogFilter extends Component {
  handleChange = selected => {
    this.props.history.push(`/${this.props.pathname}/${selected.value}`);
  }

  render() {
    const { style, items, selected, intl } = this.props;

    const selectedIndex = items.findIndex(item => item.pathname ? _.isEqual(item.pathname, selected) : _.isEqual(+item.id, +selected));

    return (
      <div className={style.root}>
        {!_.isEmpty(items) &&
          <Select
            options={items.map(item => {
                return {
                  label: <div className={style.selectOption}>{item.name}</div>,
                  value: item.id,
                  textValue: item.name
                }
              })
            }
            selected={selectedIndex}
            placeholder={intl.formatMessage({id: 'Catalog.ALL_GOODS'})}
            noOptionsMessage=''
            onChange={this.handleChange}
            className='selectGradient'
            classNamePrefix='selectGradient'
            isSearchable={false}
          />
        }
      </div>
    )
  }
}

CatalogFilter.defaultProps = {
  style: require('./CatalogFilter.module.scss')
};

CatalogFilter.propTypes = {
  intl: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  pathname: PropTypes.string.isRequired,
  selected: PropTypes.string,
};

export default CatalogFilter;